import { useCallback, useMemo } from 'react';

import { useCollectionModals } from '../atoms/CollectionModalsProvider';
import { useLocalization } from '../atoms/useLocalization';

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: () => boolean;
  readonly act: (collection: unknown) => void;
}

export function useCollectionActionReorder(): Action {
  const [t] = useLocalization();
  const { pushModal } = useCollectionModals();

  const canReorderCollections = useCallback(() => true, []);

  const reorderCollections = useCallback(() => {
    void pushModal('manage');
  }, [pushModal]);

  return useMemo(
    () => ({
      key: 'reorderCollection',
      name: t('reorderCollection'),
      icon: '',
      enabled: canReorderCollections,
      act: reorderCollections
    }),
    [canReorderCollections, reorderCollections, t]
  );
}
