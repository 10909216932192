import { useMemo } from 'react';

import { Collection } from '../atoms/useCollectionCacheClient';
import { useCollectionLibraryQuery } from '../atoms/useCollectionLibraryQuery';

export interface CollectionsResult {
  readonly isLoading: boolean;
  readonly collections: readonly Collection[];
  readonly error: string | null;
}

/** Looks up all of the current user's collections. */
export function useUserCollectionLibrary(): CollectionsResult {
  const result = useCollectionLibraryQuery('user');

  return useMemo(() => ({
    collections: result.data ?? [],
    error: result.error,
    isLoading: result.isLoading
  }), [result.data, result.error, result.isLoading]);
}
