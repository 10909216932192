
import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useAuth } from '@iamexperiences/feature-auth';
import React, { ReactElement, useCallback } from 'react';

import { settingsManager } from '../../shared/settings';
import { type HeaderTelemetryEvent, HeaderDataProvider } from '../client-header-tenant';
import { getOfficeSuiteHeaderEndpoint, getOfficeSuiteHeaderScope } from '../utils/OfficeSuiteHelper';
import { Header } from './Header';

export function HeaderWrapper(): ReactElement {
  const { auth } = useAuth();
  const telemetry = useTelemetry();
  const msGraphAudience = settingsManager.getRequired('msGraphResourceName');

  const getMSGraphToken = useCallback(async () => {
    return await auth.acquireToken({ scopes: [msGraphAudience] });
  }, [auth, msGraphAudience]);

  const getOfficeEndpoint = useCallback(async () => {
    return getOfficeSuiteHeaderEndpoint(auth, telemetry);
  }, [auth, telemetry]);

  const getOfficeToken = useCallback(async () => {
    const scope = getOfficeSuiteHeaderScope(auth, telemetry);
    if (!scope) {
      telemetry.error('getofficeSuiteHeaderScope returned null', {});
      return await new Promise<string>(() => {});
    } else {
      return await auth.acquireToken({ scopes: [scope] });
    }
  }, [auth, telemetry]);

  const reportApiSuccess = useCallback((event: HeaderTelemetryEvent) => {
    telemetry.reportCustomEvent(event.action, { message: event.message });
  }, [telemetry]);

  const reportApiFailure = useCallback((event: HeaderTelemetryEvent) => {
    telemetry.reportCustomEvent(event.action, { message: event.message });
  }, [telemetry]);

  return (
  <HeaderDataProvider
    msGraphBaseUrl={msGraphAudience}
    acquireMSGraphToken={getMSGraphToken}
    reportApiFailure={reportApiFailure}
    reportApiSuccess={reportApiSuccess}
    officeShellBaseUrl={getOfficeEndpoint}
    acquireOfficeShellToken={getOfficeToken}
    >
    <Header/>
  </HeaderDataProvider>
  );
};
