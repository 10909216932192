import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useMemo } from 'react';

import { useAppCollectionsQueryConfig } from '../atoms/AppCollectionsProvider';
import {
  Collection,
  CollectionWithLoadError,
  convertCollectionResponse,
  forbidAllActions
} from '../atoms/useCollectionCacheClient';
import { useCollectionOrderQuery } from '../atoms/useCollectionOrderQuery';
import { useTileLibraryQuery } from '../atoms/useTileLibraryQuery';
import { applySmartCollectionLimits } from '../utilities/applySmartCollectionLimits';
import { populateTiles } from '../utilities/populateTiles';

export interface CollectionsResult {
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly collections: readonly Collection[];
}

const loadingState = {
  id: 'placeholder',
  loadingState: 'metadata',
  viewPermissions: forbidAllActions
};

const errorState = {
  id: 'placeholder',
  loadingState: 'error',
  viewPermissions: forbidAllActions
};

/** Looks up all of the current user's collections. */
export function useUserCollectionOrder(): CollectionsResult {
  const { strings } = useAppCollectionsQueryConfig();
  const telemetry = useTelemetry();

  const { isLoading: orderIsLoading, isError: orderIsError, data: order, error: orderError } = useCollectionOrderQuery('user');
  const { isLoading: tilesIsLoading, isError: tilesIsError, data: tiles, error: tilesError } = useTileLibraryQuery('user');

  const result = useMemo(() => {
    const collections = order?.map(convertCollectionResponse);
    // Show top-level loading state
    if (orderIsLoading) {
      return [{ ...loadingState, displayName: strings.loadingTitle } as Collection];
    // Show top-level error state
    } else if (orderIsError) {
      return [{ ...errorState, displayName: strings.errorTitle, error: orderError ?? strings.unknownError } as Collection];
    // Show each collection in a loading state
    } else if (tilesIsLoading) {
      return collections;
    // Show each collection in an error state
    } else if (tilesIsError) {
      return collections?.map<CollectionWithLoadError>(x => ({ ...x, loadingState: 'error', error: tilesError ?? strings.unknownError, viewPermissions: forbidAllActions }));
    // Show the collections
    } else if (tiles && collections) {
      const collectionsWithTiles = populateTiles(tiles, collections, telemetry);
      return collectionsWithTiles.map(applySmartCollectionLimits(strings));
    } else {
      return undefined;
    }
  }, [
    orderIsLoading, orderIsError, order, orderError,
    tilesIsLoading, tilesIsError, tiles, tilesError,
    strings.unknownError, strings.loadingTitle, strings.errorTitle, strings.tooFewPopularTilesError, strings.tooFewRecentTilesError
  ]);

  return useMemo(() => ({
    collections: result ?? [],
    isError: orderIsError,
    isLoading: orderIsLoading
  }), [result, orderIsError, orderIsLoading]);
}
