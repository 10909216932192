import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { ScrollableContentWithFixedHeader } from '@iamexperiences/feature-app-launching';
import React, { lazy, ReactElement, Suspense, useEffect, useMemo } from 'react';

import { CollectionModalsProvider } from '../atoms/CollectionModalsProvider';
import { PageHeaderProvider, useCurrentPageHeaders, usePageHeader } from '../atoms/PageHeaderProvider';
import { PageProvider, usePage } from '../atoms/PageProvider';
import { HeaderWrapper } from '../Header/HeaderWrapper';
import { LaunchPage } from './LaunchPage';
import { type ManageAppPageProps } from './ManageAppPage';
import { withTranslation } from 'react-i18next';
import { ReturnTimeSinceNavigationStarted } from '../../shared/Telemetry/PerformanceTelemetryHelper';

export const ManageAppPage = lazy(async () =>
  await import(/* webpackChunkName: "manage-app" */ './ManageAppPage'));

export const SelfServiceApps = lazy(async () =>
  await import('../feature-app-selfservice/SelfServiceApps'));

declare global {
  interface Window {
    /**
     * Start time recorded during parsing of index.html.
     *
     * This can be used in the short term to determine loading times of the site.
     */

    MyAppsLoadStartMs: number;
  }
}

const header = {
  id: 'suite-header',
  height: 48,
  behavior: 'pinned'
} as const;

interface LaunchPageConfig {
  readonly name: 'launch';
}

interface ManageAppPageConfig {
  readonly name: 'manageApp';
  readonly props: ManageAppPageProps;
}

interface SelfServiceAppsConfig {
  readonly name: 'requestNewApps';
}

type Page = LaunchPageConfig | ManageAppPageConfig | SelfServiceAppsConfig;

function PageContent(): ReactElement | null {
  const [page] = usePage<Page>();

  switch (page.name) {
    case 'launch': return <LaunchPage />;
    case 'manageApp': return <ManageAppPage {...page.props} />;
    case 'requestNewApps': return <SelfServiceApps />;
  }
}

function ScrollablePageWithHeaders(): ReactElement {
  const [page] = usePage<Page>();
  const telemetry = useTelemetry();

  const suiteHeader = useMemo(() => ({ ...header, content: <HeaderWrapper /> }), []);
  usePageHeader(suiteHeader);
  const headers = useCurrentPageHeaders();

  useEffect(() => {
    const time = ReturnTimeSinceNavigationStarted()
    telemetry.reportPerformance('Page Router', {
      data: { MyAppsLoadEnd: time }
    });
  }, [telemetry]);

  return (
    <div role="main">
      <ScrollableContentWithFixedHeader
        overscroll={page.name === 'launch'}
        isContentFixedHeight={false}
        headers={headers}
      >
        <Suspense fallback={<div></div>}>
          <PageContent />
        </Suspense>
      </ScrollableContentWithFixedHeader>
    </div>
  );
}

function PageRouterInner(): ReactElement {
  return (
    <PageProvider<Page> initialPage={{ name: 'launch' }}>
      <PageHeaderProvider>
        <CollectionModalsProvider>
          <ScrollablePageWithHeaders />
        </CollectionModalsProvider>
      </PageHeaderProvider>
    </PageProvider>
  );
}

export const PageRouter = withTranslation('Apps')(PageRouterInner);
