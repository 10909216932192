import { useQuery } from 'react-query';

import { useHeaderDataQueryConfig } from '../atoms/HeaderDataProvider';

export interface IAssignedPlan {
  readonly assignedDateTime: string;
  readonly capabilityStatus: string;
  readonly service: string;
  readonly servicePlanId: string;
}

export interface IOrganizationInfo {
  readonly id: string;
  readonly displayName: string;
  readonly hasP2License: boolean;
  readonly assignedPlans: IAssignedPlan[];
}

const P2_SERVICE_PLAN_IDS = ['eec0eb4f-6444-4f95-aba0-50c24d67f998', 'e866a266-3cff-43a3-acca-0c90a7e00c8b'];

export function useGetCurrentOrganization(tenantId: string): {data: IOrganizationInfo | undefined; isLoading: boolean; isError: boolean} {
  const { acquireMSGraphToken, msGraphBaseUrl, reportApiSuccess, reportApiFailure } = useHeaderDataQueryConfig();
  const queryKey = ['currentOrganization', tenantId];

  const { data, isLoading, isError } = useQuery({
    queryKey,
    cacheTime: 1000 * 60 * 60 * 24, // 24 hrs
    staleTime: 1000 * 60 * 60, // 1 hr
    queryFn: async () => {
      const token = await acquireMSGraphToken();
      return await fetch(`${msGraphBaseUrl}/v1.0/organization`, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(async (response) => await response.json())
        .then((res) => res.value[0])
        .then((org: IOrganizationInfo) => org)
        .then((org) => {
          return {
            id: org.id,
            displayName: org.displayName,
            hasP2License: org.assignedPlans.some((ap: IAssignedPlan) => P2_SERVICE_PLAN_IDS.includes(ap.servicePlanId)),
            assignedPlans: org.assignedPlans.map((plan) => {
              return {
                assignedDateTime: plan.assignedDateTime,
                capabilityStatus: plan.capabilityStatus,
                service: plan.service,
                servicePlanId: plan.servicePlanId
              };
            })
          };
        })
        .catch((error) => {
          reportApiFailure?.({ action: 'header/network-call/failure/get-current-organization', message: error });
          throw error;
        });
    },
    onSuccess: () => {
      reportApiSuccess?.({ action: 'header/network-call/success/get-current-organization' });
    }
  });

  return { data, isLoading, isError };
}
