// Neither the current version of TypeScript nor the polyfill has a native definition for getCanonicalLocales,
// and so we have to explicitly define it here.
// [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/getCanonicalLocales)
declare namespace Intl {
  function getCanonicalLocales(locale: string | string[]): string[];
}

export function normalizeLanguage(lng: string) {
  // 1. The polyfill version doesn't lowercase the language, so we'll forcefully lowercase
  // the whole result. The polyfill will handle the rest.
  // 2. The returned locale could be a comma-separated list, so we need to split it up. Because
  // My Apps returns the first available language in its language detector logic, return the
  // first entry.
  lng = lng?.split(',')[0].toLowerCase();
  try {
    return Intl.getCanonicalLocales(lng)[0];
  } catch {
    return lng;
  }
}
