import { useCallback } from 'react';

export function useSetUrlParams(): {
  removeUrlParam: (param: string) => void;
  setUrlParam: (param: string, value: string) => void;
} {
  const setUrlParam = useCallback((param: string, value: string) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(param, value);
    window.history.pushState({}, '', currentUrl.toString());
  }, []);

  const removeUrlParam = useCallback((param: string) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete(param);
    window.history.pushState({}, '', currentUrl.toString());
  }, []);

  return { setUrlParam, removeUrlParam };
}
