import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useMemo } from 'react';

interface Action<T = void> {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: boolean | ((arg: T) => boolean);
  readonly act: (arg: T) => void;
}

export function useAppActions<TApp, TCollection>(
  actions: Array<Action<readonly [TApp, TCollection]>>
): Array<Action<readonly [TApp, TCollection]>> {
  const telemetry = useTelemetry();

  return useMemo(
    () =>
      actions.map((action) => ({
        ...action,
        act: (args: readonly [TApp, TCollection]) => {
          telemetry.reportCustomEvent(`contextMenu/app/${action.key}`);
          action.act(args);
        }
      })),
    [actions, telemetry]
  );
}
