import { useLocalization as useLoc } from './localization';

type Localization = readonly [
  translateFn: any,
  isLoaded: boolean
];

export function useLocalization(): Localization {
  return useLoc('Apps');
}
