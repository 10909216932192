import { useAuth } from '@iamexperiences/feature-auth';

import { useLocalization } from '../../atoms/useLocalization';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useAppCollectionsQueryConfig } from '../atoms/AppCollectionsProvider';
import { useLocalDeleteCollection } from '../molecules/useLocalDeleteCollection';
import { OptimisticUpdate } from '../utilities/cache';
import { addDataBoundaryHeaders } from '../../utils/DataBoundaryHeaders';

export function useDeleteCollection(): (id: string) => void {
  const [t] = useLocalization();
  const { acquireToken, serviceRootUrl } = useAppCollectionsQueryConfig();
  const localDeleteCollection = useLocalDeleteCollection();
  const { auth } = useAuth();

  const { mutate } = useMutation<void, unknown, string, OptimisticUpdate>(async id => {
    const token = await acquireToken();
    const headers = addDataBoundaryHeaders(auth);
    headers.append('Authorization', 'Bearer ' + token);
    headers.append('Content-Type', 'application/json');

    const response = await fetch(`${serviceRootUrl.slice(0, serviceRootUrl.length - 7)}/api/users/me/workspaces/${id}`, {
      method: 'DELETE',
      headers: headers
    });
    if (!response.ok) {
      return await Promise.reject(response.statusText);
    }
  }, {
    onMutate: async id => {
      return await localDeleteCollection('user', id);
    },
    onError: async (_error, _params, update) => {
      toast.error(t('deleteCollectionFailure'), { id: 'workspaces-api/delete/error' });
      await update?.rollback();
    },
    onSuccess: async (_data, _params, update) => {
      await update?.complete();
    }
  });

  return mutate;
}
