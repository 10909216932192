import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useCallback, useEffect } from 'react';

import { CollectionsTelemetryEvent } from '../../shared/Telemetry/CollectionsTelemetryEvent';
import { useSetUrlParams } from './useSetUrlParams';
import { useUserStorage } from './useUserStorage';
export type AppLayout = 'List' | 'Grid';
const defaultViewState = 'Grid';
const urlParam = 'layout';

export function useToggleAppLayout(logLayout: boolean): {
  appLayout: AppLayout;
  setAppLayout: (value: AppLayout | undefined) => void;
} {
  const telemetry = useTelemetry();
  const { getItem, setItem, removeItem } = useUserStorage<string, AppLayout>();
  const { setUrlParam, removeUrlParam } = useSetUrlParams();
  const key = 'AppsLayout';
  const value = getItem(key);

  useEffect(() => {
    if (logLayout) {
      telemetry.reportCustomEvent(
        CollectionsTelemetryEvent.AppsLayout,
        { appLayout: value?.toString() ?? defaultViewState });
    }
  }, [telemetry, logLayout, value]);

  const setLayout = useCallback((value: AppLayout | undefined) => {
    value ? setItem(key, value) : removeItem(key);
    telemetry.reportCustomEvent(CollectionsTelemetryEvent.UserTogglesAppsLayout, { value });
    if (value && value !== defaultViewState) {
      setUrlParam(urlParam, value);
    } else {
      removeUrlParam(urlParam);
    }
  }, [setItem, removeItem, telemetry, setUrlParam, removeUrlParam]);

  return { appLayout: value ?? defaultViewState, setAppLayout: setLayout };
}
