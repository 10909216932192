import { useLocalization } from '../atoms/useLocalization';
import { useMemo } from 'react';

import { Tile } from '../client-app-collections';
import { useRoute } from './useRoute';

interface App extends Tile {
  readonly provider: string;
}

interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: ([app, collection]: readonly [App, unknown]) => boolean;
  readonly act: ([app, collection]: readonly [App, unknown]) => void;
}

export function useAppActionManage(): Action {
  const [t] = useLocalization();
  const { goToManageAppPage } = useRoute();

  return useMemo<Action>(
    () => ({
      key: 'manageApplication',
      name: t('manageApplication'),
      icon: 'Settings',
      enabled: ([app]) => app.provider === 'AzureADThirdParty',
      act: ([app]) => {
        goToManageAppPage(app);
      }
    }),
    [t, goToManageAppPage]
  );
}
