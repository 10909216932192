import { IDetector, IDetectorOptions } from './interfaces';

export const querystringDetector: IDetector = {
  name: 'querystring',
  lookup: (options?: IDetectorOptions) => {
    const url = new URL(window.location.href);
    const language = url.searchParams.get((options && options.lookupQueryString) || '') || undefined;
    return language;
  },
};
