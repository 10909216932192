export class Csrf {
  private _token: string | undefined;

  public getToken(): string | undefined {
    return this._token;
  }

  public init(location: Location = window.location): void {
    try {
      const url = new URL(location.href);
      if (url.searchParams.has('csrf_token')) {
        const csrfToken = url.searchParams.get('csrf_token');
        // url.searchParams.delete('csrf_token');
        history.replaceState(undefined, '', url.toString());
        if (csrfToken) {
          this._token = csrfToken;
        }
      }
    } catch (error) {
      //
    }
  }
}

export const csrf = new Csrf();
