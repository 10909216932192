import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useAuth } from '@iamexperiences/feature-auth';

import { useLocalization } from '../../atoms/useLocalization';
import { toast } from 'react-hot-toast';
import { useQuery, UseQueryResult } from 'react-query';

import { useAppCollectionsQueryConfig } from './AppCollectionsProvider';
import { useTileCacheConfig } from './TileCacheProvider';
import { CacheKeyScopedTileLibrary, cacheKeyScopedTileLibrary, Scope, Tile } from './useTileCacheClient';
import { addDataBoundaryHeaders } from '../../utils/DataBoundaryHeaders';

const millisecondsPerHour = 3600000;
const millisecondsPerFiveMinutes = 300000;

/** Looks up all of the current user's assigned app tiles. */
export function useTileLibraryQuery(scope: Scope): UseQueryResult<readonly Tile[], string> {
  const [t] = useLocalization();
  const telemetry = useTelemetry();
  const queryKey = cacheKeyScopedTileLibrary(scope);
  const { serviceRootUrl, acquireToken } = useAppCollectionsQueryConfig();
  const { getPersistentCache, onLoadSuccess } = useTileCacheConfig();
  const [cache, setCache] = getPersistentCache?.(queryKey) ?? [[]];
  const { auth } = useAuth();

  return useQuery<readonly Tile[], string, readonly Tile[], CacheKeyScopedTileLibrary>({
    queryKey,
    initialData: cache ?? undefined,
    initialDataUpdatedAt: 1, // force initial refresh
    cacheTime: millisecondsPerHour,
    staleTime: millisecondsPerFiveMinutes,
    queryFn: async () => {
      const token = await acquireToken();
      const headers = addDataBoundaryHeaders(auth);
      headers.append('Authorization', 'Bearer ' + token);

      return await fetch(`${serviceRootUrl}/me/tiles`, {
        headers: headers
      })
        .then(async (response): Promise<{ value: Tile[] }> => await response.json())
        .then(data => data.value
        // Remove tiles with no name (fix for an Office regression)
          .filter(x => x.displayName != null)
        // Populate scopedId, not returned by API
        // Fix missing urls
          .map<Tile>(x => ({
          ...x,
          scopedId: x.id.split('|')[1] ?? x.id,
          fastLaunchUrl: x.fastLaunchUrl ?? x.persistentLaunchUrl
        }))
        // Sort alphabetically by display name
          .sort((a, b) => a.displayName.toLocaleLowerCase().localeCompare(b.displayName.toLocaleLowerCase()))
        ).then(data => {
          try {
            setCache?.(data);
          } catch {}

          try {
            onLoadSuccess?.(queryKey, data);
          } catch {}

          return data;
        })
        .catch((error) => {
          telemetry.error('tiles-api/error', error);
          toast.error(t('genericApiFailure'), { id: 'tiles-api/error' });
          throw error;
        });
    }
  });
}
