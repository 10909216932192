import { useMemo } from 'react';

import { useLocalization } from '../atoms/useLocalization';
import { useRequestApps } from '../molecules/useRequestApps';

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon?: string;
  readonly enabled: () => boolean;
  readonly act: () => void;
  readonly subActionItems?: readonly Action[];
}

export function useGlobalActionAddApp(createBookmark: Action): Action {
  const [t] = useLocalization();
  const requestApps = useRequestApps();

  return useMemo(() => ({
    key: 'addApps',
    name: t('addApps'),
    icon: 'AppIconDefaultAdd',
    enabled: () => true,
    act: () => {},
    subActionItems: requestApps.enabled() ? [createBookmark, requestApps] : [createBookmark]
  }), [t, requestApps, createBookmark]);
}
