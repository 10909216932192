import { Collection, forbidAllActions, FullyLoadedCollection, KnownCollectionIds } from "../atoms/useCollectionCacheClient";

const MIN_POPULAR_APPS = 3;
const MAX_POPULAR_APPS = 21;

const MIN_RECENT_APPS = 1;
const MAX_RECENT_APPS = 14;

function empty(collection: FullyLoadedCollection, message: string): Collection {
  return {
    ...collection,
    loadingState: 'error',
    viewPermissions: forbidAllActions,
    error: message
  }
}

function truncate(collection: FullyLoadedCollection, length: number): Collection {
  return {
    ...collection,
    itemCount: length,
    items: collection.items.slice(0, length)
  };
}

function constrain(collection: FullyLoadedCollection, minTiles: number, maxTiles: number, emptyMessage: string): Collection {
  if (collection.itemCount < minTiles) {
    return empty(collection, emptyMessage);
  } else if (collection.itemCount > maxTiles) {
    return truncate(collection, maxTiles);
  } else return collection;
}

export interface SmartCollectionErrorStrings {
  readonly unknownError: string;
  readonly tooFewPopularTilesError: string;
  readonly tooFewRecentTilesError: string;
}

export function isSmartCollection(id: string){
  return id === KnownCollectionIds.popular || id === KnownCollectionIds.recent;
}

export function applySmartCollectionLimits(strings: SmartCollectionErrorStrings): (collection: Collection) => Collection {
  return collection => {
    if (collection.loadingState === 'loaded') {
      if (collection.id === KnownCollectionIds.popular) {
        return constrain(collection, MIN_POPULAR_APPS, MAX_POPULAR_APPS, strings.tooFewPopularTilesError);
      }
      if (collection.id === KnownCollectionIds.recent) {
        return constrain(collection, MIN_RECENT_APPS, MAX_RECENT_APPS, strings.tooFewRecentTilesError);
      }
    }
    return collection;
  };
}
