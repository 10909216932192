import { IAuth } from '@iamexperiences/feature-auth';

export enum CustomHttpHeaders {
  DataBoundary = 'x-ms-tdbr',
  TenantId = 'x-ms-tenant-id',
  TenantRegion = 'x-ms-tenant-region',
  UserId = 'x-ms-user-id'
}

export enum UserClaims {
  DataBoundary = 'xms_tdbr',
  TenantRegionScope = 'tenant_region_scope',
  TenantId = 'tid',
  ObjectId = 'oid',
  TenantRegionSubScope = 'tenant_region_sub_scope'
}

export enum DataBoundaryRegion {
  EUDB = 'EU'
}

export enum USGovRegion {
  DOD = 'DOD'
}

export function addDataBoundaryHeaders(auth?: IAuth): Headers {
  if (!auth) {
    return new Headers();
  }
  const user = auth.user;
  const headers = new Headers();

  if (user?.rawClaims) {
    const tenantId_claim = getUserClaim(auth, UserClaims.TenantId);
    const tdbr_claim = getUserClaim(auth, UserClaims.DataBoundary);
    const tenantRegion_claim = getUserClaim(auth, UserClaims.TenantRegionScope);
    const userId_claim = getUserClaim(auth, UserClaims.ObjectId);

    if (tenantId_claim) {
      headers.append(CustomHttpHeaders.TenantId, tenantId_claim);
    }

    if (tdbr_claim) {
      headers.append(CustomHttpHeaders.DataBoundary, tdbr_claim);
    }

    if (tenantRegion_claim) {
      headers.append(CustomHttpHeaders.TenantRegion, tenantRegion_claim);
    }

    if (userId_claim) {
      headers.append(CustomHttpHeaders.UserId, userId_claim);
    }
  }

  return headers;
}

export function addDataBoundaryHeadersJSON(): Object {
  const headers = addDataBoundaryHeaders();
  const headersJSON = {};

  headers.forEach((value, key) => {
    headersJSON[key] = value;
  });

  return headersJSON;
}

export function getUserClaim(auth: IAuth, claim: string): string {
  return auth.user?.rawClaims[claim] as string;
}
