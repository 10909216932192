import { Collection } from "../atoms/useCollectionCacheClient";

const popularId = 'popularApps';
const recentId = 'recentApps';

const MIN_TILES_TO_SHOW_SMART_COLLECTIONS = 14;

export function isNotRestrictedSmartCollection(tileCount: number): (collection: Collection) => boolean {
  return collection => collection.id !== popularId && collection.id !== recentId || tileCount >= MIN_TILES_TO_SHOW_SMART_COLLECTIONS;
}
