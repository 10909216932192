import {
  ExperimentClient,
  IExperimentClientInitSettings,
  IFeatureFlag
} from '@iamexperiences/client-experiment';

import { auth } from '../auth';
import { settingsManager } from '../shared/settings';
import { telemetry } from '../shared/Telemetry/Telemetry';

const initSettings: IExperimentClientInitSettings = {
  remoteUrl: settingsManager.get('experimentRemoteUrl') ?? '',
  namespace: settingsManager.get('experimentNamespace') ?? '',
  onError: (name, error) => {
    telemetry.error(`Experiment Error ${name}: `, error);
  },
  getParams: () => {
    const map = new Map<string, string[]>();
    const tenantId = auth.user?.tenantId ?? '';
    map.set('tenantId', [tenantId]);
    return map;
  }
};

const EXPERIMENT_DEFAULT_FEATURE_FLAG: IFeatureFlag = {
  npsToastDisplay: true
};

export const experimentClient = new ExperimentClient({
  experimentFeatureFlags: EXPERIMENT_DEFAULT_FEATURE_FLAG, settings: initSettings
});

let isInitialized = false;

const checkInitialized = (): void => {
  if (!isInitialized && experimentClient.isClientInitialized()) {
    isInitialized = true;
    telemetry.reportCustomEvent('experiment-client/initialized');
  }
};

const checkInitializedInterval = setInterval(() => {
  if (isInitialized) {
    clearInterval(checkInitializedInterval);
    return;
  }
  checkInitialized();
}, 1000);

setTimeout(() => {
  clearInterval(checkInitializedInterval);
}, 60000);
