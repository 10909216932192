import { NavBarData } from '@suiteux/suiteux-shell-applauncher';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { useHeaderDataQueryConfig } from '../atoms/HeaderDataProvider';

export interface NavBarWrapper extends NavBarData {};

export function useGetOfficeShellData(tenantId: string):
{data: NavBarWrapper | undefined; isLoading: boolean; isError: boolean} {
  const {
    acquireOfficeShellToken,
    officeShellBaseUrl,
    reportApiSuccess,
    reportApiFailure
  } = useHeaderDataQueryConfig();

  const queryKey = ['shellData', tenantId];

  const { data, isLoading, isError } = useQuery({
    queryKey,
    cacheTime: 1000 * 60 * 60 * 24, // 24 hrs
    staleTime: 1000 * 60 * 60, // 1 hr
    queryFn: async () => {
      const token = await acquireOfficeShellToken();
      const officeShellUrl = await officeShellBaseUrl();
      if (!officeShellUrl) {
        throw new Error('officeShellUrl is undefined');
      }
      return await
      fetch(`${officeShellUrl}/api/shell/navbardata?CurrentMainLinkId=AADMyApps&TrackingGuid=${uuidv4()}`, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(async response => {
          return await response.json();
        })
        .then((data: NavBarWrapper) => data)
        .catch((error) => {
          reportApiFailure?.({ action: 'header/network-call/failure/get-officeShell-data', message: error });
          throw error;
        });
    },
    onSuccess: () => {
      reportApiSuccess?.({ action: 'header/network-call/success/get-officeShell-data' });
    }
  });

  return { data, isLoading, isError };
}
