import { Icon, IconButton, mergeStyles } from '@fluentui/react';
import React from 'react';
import toast, { Toast, useToaster } from 'react-hot-toast';

const toastStyles = {
  baseStyle: {
    alignItems: 'center',
    borderRadius: '8px',
    bottom: '20px',
    filter: 'drop-shadow(0px 1.2px 3.6px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6.4px 14.4px rgba(0, 0, 0, 0.13))',
    display: 'flex',
    fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', 'Helvetica Neue', 'sans-serif'",
    fontSize: '14px',
    justifyContent: 'center',
    left: '50%',
    marginBottom: '8px',
    maxWidth: '800px',
    minHeight: '48px',
    minWidth: '400px',
    position: 'fixed',
    zIndex: 1
  },
  default: {
    background: '#FFFFFF',
    color: '#404040'
  },
  error: {
    background: '#FED9CC',
    color: '#A80000'
  },
  success: {
    color: '#107C10'
  }
} as const;

const styles = {
  root: mergeStyles(toastStyles.baseStyle, {
    background: toastStyles.default.background,
    color: toastStyles.default.color
  }),
  rootError: mergeStyles(toastStyles.baseStyle, {
    background: toastStyles.error.background,
    color: toastStyles.error.color
  }),
  closeIcon: mergeStyles({
    color: toastStyles.default.color,
    cursor: 'pointer',
    selectors: {
      '&:hover': {
        backgroundColor: toastStyles.default.background,
        color: toastStyles.default.color
      },
      '&:active': {
        backgroundColor: toastStyles.default.background,
        color: toastStyles.default.color
      }
    }
  }),
  successIcon: mergeStyles({
    color: toastStyles.success.color,
    fontSize: '16px',
    paddingLeft: '16px'
  }),
  errorIcon: mergeStyles({
    color: toastStyles.error.color,
    fontSize: '16px',
    paddingLeft: '16px'
  }),
  closeIconError: mergeStyles({
    color: toastStyles.error.color,
    cursor: 'pointer',
    selectors: {
      '&:hover': {
        backgroundColor: toastStyles.error.background,
        color: toastStyles.error.color
      },
      '&:active': {
        backgroundColor: toastStyles.error.background,
        color: toastStyles.error.color
      }
    }
  }),
  contentContainer: mergeStyles({
    fontSize: '14px',
    marginLeft: '8px',
    marginRight: '8px',
    maxWidth: 'calc(100% - 52px)',
    textAlign: 'left',
    width: '100%',
    wordWrap: 'break-word'
  }),
  closeButtonContainer: mergeStyles({
    marginLeft: 'auto',
    marginRight: '10px'
  })
} as const;

export function CustomToaster(): React.ReactElement {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  const handleClose = (customToast: Toast): void => {
    toast.dismiss(customToast.id);
  };

  return (
    <div onMouseEnter={startPause} onMouseLeave={endPause}>
      {toasts
        .filter((toast) => toast.visible)
        .map((toast) => {
          toast.duration = 5000;
          const offset = calculateOffset(toast, {
            reverseOrder: false
          });
          const ref = (el: HTMLDivElement): void => {
            if (el && typeof toast.height !== 'number') {
              const height = el.getBoundingClientRect().height;
              updateHeight(toast.id, height);
            }
          };
          return (
            <div
              key={toast.id}
              ref={ref}
              className={toast.type === 'error' ? styles.rootError : styles.root}
              style={{ transform: `translateX(-50%) translateY(-${offset}px)` }}>
              <Icon
                className={toast.type === 'error' ? styles.errorIcon : styles.successIcon}
                iconName={toast.type === 'error' ? 'error' : 'completed'}
              />
              <div
                className={styles.contentContainer}
                role={'alert'}
                aria-live={'polite'}>
                  {toast.message}
              </div>
              <div className={styles.closeButtonContainer}>
                <IconButton
                  className={toast.type === 'error' ? styles.closeIconError : styles.closeIcon}
                  iconProps={{ iconName: 'Cancel' }}
                  onClick={() => handleClose(toast)}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
}
