import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useAuth } from '@iamexperiences/feature-auth';

import { useLocalization } from '../../atoms/useLocalization';
import { toast } from 'react-hot-toast';
import { useQuery, UseQueryResult } from 'react-query';

import { usePropagateCollections } from '../molecules/usePropagateCollections';
import { throwFetchError } from '../utilities/throwFetchError';
import { useAppCollectionsQueryConfig } from './AppCollectionsProvider';
import { useCollectionCacheConfig } from './CollectionCacheProvider';
import { applyViewToDisplayName, cacheKeyItem, CacheKeyScopedOrder, cacheKeyScopedOrder, CollectionResponse, isDeferredCollectionResponse, Scope, useCollectionCacheClient } from './useCollectionCacheClient';
import { addDataBoundaryHeaders } from '../../utils/DataBoundaryHeaders';

const millisecondsPerHour = 3600000;
const millisecondsPerFiveMinutes = 300000;

interface OrderResponse {
  readonly items: CollectionResponse[];
}

/** Looks up all of the current user's collections. */
export function useCollectionOrderQuery(scope: Scope): UseQueryResult<readonly CollectionResponse[], string> {
  const [t] = useLocalization();
  const telemetry = useTelemetry();
  const { serviceRootUrl, acquireToken } = useAppCollectionsQueryConfig();
  const { getPersistentCache, onLoadSuccess } = useCollectionCacheConfig();
  const { propagateCollections } = usePropagateCollections();
  const queryKey = cacheKeyScopedOrder(scope);
  const [cache, setCache] = getPersistentCache?.(queryKey) ?? [[]];
  const client = useCollectionCacheClient();
  const { auth } = useAuth();

  return useQuery<readonly CollectionResponse[], string, readonly CollectionResponse[], CacheKeyScopedOrder>({
    queryKey,
    cacheTime: millisecondsPerHour,
    staleTime: millisecondsPerFiveMinutes,
    initialData: cache ?? undefined,
    initialDataUpdatedAt: 1, // force initial refresh
    queryFn: async () => {
      const token = await acquireToken();
      const headers = addDataBoundaryHeaders(auth);
      headers.append('Authorization', 'Bearer ' + token);

      return await fetch(`${serviceRootUrl}/me/collectionOrder?$select=items,view&$expand=items($select=id,template,view,viewPermissions)`, {
        headers: headers
      })
        .then(response => throwFetchError(response))
        .then(async (response): Promise<OrderResponse> => await response.json())
      // extract collections, determine states, and apply view
        .then(async data => await Promise.all(data.items.map(async x => {
          const itemKey = cacheKeyItem(scope, x.id);
          if (isDeferredCollectionResponse(x)) {
          // Trigger deferred (re)load, use cached data if available
            const item = client.getQueryData(itemKey);
            client.invalidateQueries(itemKey);
            if (item) return item;
          }
          return x;
        })))
      // remove dangling empty nodes and remove any collection without display name
        .then(data => data.filter(x => !!applyViewToDisplayName(x.template, x.view, x.viewPermissions)))
      // populate persistent cache and report success
        .then(async data => {
          try {
            setCache?.(data);
          } catch {}

          await propagateCollections(scope, data);

          try {
            onLoadSuccess?.(queryKey, data);
          } catch {}

          return data;
        })
        .catch((error) => {
          telemetry.error('collectionOrder-api/error', error);
          toast.error(t('genericApiFailure'), { id: 'collectionOrder-api/error' });
          throw error;
        });
    }
  });
}
