import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useEffect } from 'react';
import { ReturnTimeSinceNavigationStarted } from '../../shared/Telemetry/PerformanceTelemetryHelper';

/**
 * Logs Perf telemetry after the next frame paint after the `enabled` expression becomes true.
 * More accurate than logging when the data is returned.
 * @see https://www.webperf.tips/tip/react-hook-paint/
 */
export function useMarkFramePaint(markName: string, enabled: Boolean): void {
  const telemetry = useTelemetry();
  useEffect(() => {
    /**
       * Only perform the log when the calling component has signaled it is
       * ready to log a meaningful visual update.
    */
    if (!enabled) {
      return;
    }
    // Queues a requestAnimationFrame and onmessage
    runAfterFramePaint(() => {
      const time = ReturnTimeSinceNavigationStarted()
      telemetry.reportPerformance(markName, {
        data: { MyAppsLoadEnd: time }
    });
  });
  }, [markName, enabled, telemetry]);
}

function runAfterFramePaint(callback: ((this: MessagePort, ev: MessageEvent<any>) => any) | null): void {
  requestAnimationFrame(() => {
    const messageChannel = new MessageChannel();
    messageChannel.port1.onmessage = callback;
    messageChannel.port2.postMessage(undefined);
  });
}
