import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useAuth } from '@iamexperiences/feature-auth';
import { useLocalization } from '../../atoms/useLocalization';
import { toast } from 'react-hot-toast';
import { useQuery, UseQueryResult } from 'react-query';

import { addDataBoundaryHeaders } from '../../utils/DataBoundaryHeaders';
import { usePropagateCollections } from '../molecules/usePropagateCollections';
import { throwFetchError } from '../utilities/throwFetchError';
import { useAppCollectionsQueryConfig } from './AppCollectionsProvider';
import { useCollectionCacheConfig } from './CollectionCacheProvider';
import { BasicCollection, CacheKeyScopedLibrary, cacheKeyScopedLibrary, Collection, Scope } from './useCollectionCacheClient';

const millisecondsPerHour = 3600000;
const millisecondsPerFiveMinutes = 300000;

/** Looks up all of the current user's collections. */
export function useCollectionLibraryQuery(scope: Scope): UseQueryResult<readonly Collection[], string> {
  const [t] = useLocalization();
  const telemetry = useTelemetry();
  const { serviceRootUrl, acquireToken } = useAppCollectionsQueryConfig();
  const { getPersistentCache, onLoadSuccess } = useCollectionCacheConfig();
  const { propagateCachedCollectionLibrary } = usePropagateCollections();
  const queryKey = cacheKeyScopedLibrary(scope);
  const [cache, setCache] = getPersistentCache?.(queryKey) ?? [[]];
  const { auth } = useAuth();

  return useQuery<readonly BasicCollection[], string, readonly Collection[], CacheKeyScopedLibrary>({
    queryKey,
    cacheTime: millisecondsPerHour,
    staleTime: millisecondsPerFiveMinutes,
    placeholderData: cache ?? undefined,
    queryFn: async () => {
      const token = await acquireToken();
      const headers = addDataBoundaryHeaders(auth);
      headers.append('Authorization', 'Bearer ' + token);

      return await fetch(`${serviceRootUrl}/me/collections?$select=id,displayName,viewPermissions`, {
        headers
      })
        .then(async response => await throwFetchError(response))
        .then(async (response): Promise<{ value: readonly Collection[] }> => await response.json())
        .then(async ({ value: collections }) => {
          try {
            setCache?.(collections);
          } catch {}

          await propagateCachedCollectionLibrary(scope);

          try {
            onLoadSuccess?.(queryKey, collections);
          } catch {}

          return collections;
        })
        .catch((error) => {
          telemetry.error('collectionLibrary-api/error', error);
          toast.error(t('genericApiFailure'), { id: 'collectionLibrary-api/error' });
          throw error;
        });
    }
  });
}
