import { useAuth } from '@iamexperiences/feature-auth';

import { useLocalization } from '../../atoms/useLocalization';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useAppCollectionsQueryConfig } from '../atoms/AppCollectionsProvider';
import { BasicCollection, cacheKeyScopedLibrary, cacheKeyScopedOrder, CollectionResponse, forbidAllActions, Tile, useCollectionCacheClient } from '../atoms/useCollectionCacheClient';
import { usePropagateCollections } from '../molecules/usePropagateCollections';
import { optimisticAppendItemToList, OptimisticUpdate } from '../utilities/cache';
import { addDataBoundaryHeaders } from '../../utils/DataBoundaryHeaders';

interface CollectionParams {
  readonly name: string;
  readonly apps: readonly Tile[];
}

interface CollectionBody {
  readonly name: string;
  readonly accessProfile: 'privateCollection';
  readonly knownApps: readonly string[];
}

const TEMP_PREFIX = 'tmp';

function getTemporaryCollectionId(collectionName: string): string {
  return `${TEMP_PREFIX}-${collectionName}-id`;
}

/**
 * @returns true iff the given id is a temporary id assigned to a collection to be created.
 */
export function isTemporaryCollectionId(id: string): boolean {
  return id.startsWith(TEMP_PREFIX);
}

export function useCreateCollection(): (params: CollectionParams) => void {
  const [t] = useLocalization();
  const { acquireToken, serviceRootUrl } = useAppCollectionsQueryConfig();
  const client = useCollectionCacheClient();
  const { propagateCollections } = usePropagateCollections();
  const { auth } = useAuth();

  const { mutate } = useMutation<void, unknown, CollectionParams, OptimisticUpdate[]>(async params => {
    const body: CollectionBody = {
      name: params.name,
      accessProfile: 'privateCollection',
      knownApps: params.apps.map(x => x.id)
    };
    const token = await acquireToken();
    const headers = addDataBoundaryHeaders(auth);
    headers.append('Authorization', 'Bearer ' + token);
    headers.append('Content-Type', 'application/json');

    const response = await fetch(`${serviceRootUrl.slice(0, serviceRootUrl.length - 7)}/api/users/me/workspaces`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    if (!response.ok) {
      return await Promise.reject(response.statusText);
    }
  }, {
    onMutate: async params => {
      const id = getTemporaryCollectionId(params.name);
      const collection: CollectionResponse = {
        id,
        template: {
          displayName: params.name,
          isComplete: false,
          itemIds: params.apps.map(x => x.id)
        },
        view: null,
        viewPermissions: forbidAllActions // forbid changes until we have the final id
      };
      const basicCollection: BasicCollection = {
        id,
        displayName: params.name
      };

      await propagateCollections('user', [collection]);

      // Optimistically update visible list cache
      return [
        await optimisticAppendItemToList(client, cacheKeyScopedOrder('user'), collection),
        await optimisticAppendItemToList(client, cacheKeyScopedLibrary('user'), basicCollection)
      ];
    },
    onError: async (_error, _params, updates) => {
      toast.error(t('createCollectionFailure'), { id: 'workspaces-api/create/error' });
      await Promise.all(updates?.map(async x => await x.rollback()) ?? []);
    },
    onSuccess: async (_data, _params, updates) => {
      toast.success(t('collectionCreatedSuccess'), { id: 'collectionCreatedSuccess' });
      await Promise.all(updates?.map(async x => await x.complete()) ?? []);
    }
  });

  return mutate;
}
