import { settingsManager } from '../../shared/settings';

/**
 * Gets the default api server's base url
 */
export const getApiServerBaseUrl = () => {
  let url = new URL(location.href);
  if (process.env.NODE_ENV === 'development') {
    if (url.hostname.startsWith('localhost')) {
      url.port = '80';
    }
  }

  if (process.env.NODE_ENV === 'test') {
    url = new URL('http://mockapi.microsoft.com');
  }

  const prefix = process.env.APP_API_SERVER_OVERRIDE_PREFIX;
  if (prefix) {
    url.hostname = prefix + url.hostname.toLowerCase().replace('myapps', 'myapplications'); // api.myapps-ppe.microsoft.com is not supported. Replace it with api.myapplications-ppe
  }

  if (process.env.APP_API_SERVER_OVERRIDE) {
    url = new URL(process.env.APP_API_SERVER_OVERRIDE);
  }

  if (process.env.APP_API_SERVER_OVERRIDE_PORT) {
    url.port = process.env.APP_API_SERVER_OVERRIDE_PORT;
  }

  url.pathname = '/';
  url.hash = '';
  url.search = '';
  return url;
};

/**
 * Builds a url to hit a specific api endpoint
 * @param pathName The path name of the api
 * @param queryStringParams The query string parameters
 * @param encode This parameter is only respected when mocking is enabled
 * @param baseUrlOverride This parameter overrides the default logic to determine the api url
 */
export const getApiServerUrl = (
  pathName: string,
  queryStringParams: { [key: string]: string | undefined } = {},
  encode = true,
  hostOverride?: string
) => {
  const url = getApiServerBaseUrl();
  if (hostOverride !== undefined) {
    url.host = hostOverride;
  }
  url.pathname = pathName;

  if (process.env.APP_DEV_ENABLE_MOCKS !== 'true' && encode) {
    Object.keys(queryStringParams).forEach((key: string) => {
      const value = queryStringParams[key];
      if (value !== undefined) {
        url.searchParams.set(key, value);
      }
    });
  } else {
    // This option should get dead code removed on production builds where mocks are disabled
    let search = '';
    Object.keys(queryStringParams).forEach((key: string, index: number) => {
      const value = queryStringParams[key];
      if (value !== undefined) {
        if (index === 0) {
          search += key + '=' + value;
        } else {
          search += '&' + key + '=' + value;
        }
      }
    });
    url.search = search;
  }

  // Workaround for Edge url pollyfill issue
  const uri = url.toString().replace('#', '');
  if (uri.endsWith('?')) {
    uri.replace('?', '');
  }
  return uri;
};

export function getIAMUXBaseAddress(): string {
  const protocolPrefix = 'https://';
  const iamuxBaseAddressOverrideEndpoint: string = settingsManager.getRequired('iamuxBaseAddressEndpoint');
  return `${protocolPrefix}${iamuxBaseAddressOverrideEndpoint}`;
}
