import React, { createContext, ReactElement, ReactNode, useContext } from 'react';
import { ValueFor } from '../utilities/cache';
import { Cache, CacheKeyScopedTileLibrary } from './useTileCacheClient';

export interface TileCacheConfig {
  /** Accessor for persistent cache to warm up the session cache on startup. */
  readonly getPersistentCache?: <K extends Cache[0]>(key: K) => [value: ValueFor<Cache, K> | null, setValue?: (value: ValueFor<Cache, K>) => void] | null;

  /** Callback executed when data is loaded into a session cache. Hook provided for telemetry purposes. */
  readonly onLoadSuccess?: <K extends CacheKeyScopedTileLibrary>(key: K, data: ValueFor<Cache, K>) => void;
}

// Exported for testing
export const Context = createContext<TileCacheConfig | null>(null);

export interface TileCacheProviderProps extends TileCacheConfig {
  readonly children?: ReactNode;
}

export function useTileCacheConfig(): TileCacheConfig {
  // This provider is optional
  return useContext(Context) ?? {};
}

/** Provider for optional extensions to the default tile caching behavior. */
export function TileCacheProvider({
  children, ...config
}: TileCacheProviderProps): ReactElement {
  return <Context.Provider value={config}>{children}</Context.Provider>;
}
