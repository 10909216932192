import { ThemeProvider } from '@fluentui/react';
import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useAuth } from '@iamexperiences/feature-auth';
import React, { ReactElement, Suspense, useCallback, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { getApiServerBaseUrl } from '../Apps/utils/getApiServerUrl';
import { AppsActions } from '../shared/Telemetry/AppsActions';
import { StorageProvider } from './atoms/StorageProvider';
import { useInstrumentedStorage } from './atoms/useInstrumentedStorage';
import { useLocalization } from './atoms/useLocalization';
import {
  AppCollectionsProvider,
  BasicCollection,
  cacheKeyScopedLibrary,
  CacheKeyScopedOrder,
  cacheKeyScopedOrder,
  cacheKeyScopedTileLibrary,
  Collection, CollectionCache,
  CollectionCacheKey,
  CollectionCacheProvider,
  Tile,
  TileCacheKey,
  TileCacheProvider,
  ValueFor
} from './client-app-collections';
import { useBootCache } from './molecules/useBootCache';
import { PageRouter } from './pages/PageRouter';
import { ReturnTimeSinceNavigationStarted } from '../shared/Telemetry/PerformanceTelemetryHelper';
import { settingsManager } from '../shared/settings';
import { TokenProvider } from '../shared/Providers/TokenProvider';

const client = new QueryClient();

function keyMatches(a: readonly string[], b: readonly string[]): boolean {
  return a.length === b.length && a.every((x, i) => x === b[i]);
}

function LaunchPageInner(): ReactElement {
  const telemetry = useTelemetry();
  const [t] = useLocalization();
  const { auth } = useAuth();
  const msGraphAudience = settingsManager.getRequired('msGraphResourceName');

  useEffect(() => {
    const time = ReturnTimeSinceNavigationStarted();
    telemetry.reportPerformance(AppsActions.bootPerformance, {
      data: { MyAppsLoadEnd: time }
    });
  }, [telemetry]);

  const getToken = useCallback(async (audience: string) => {
    return await auth.acquireToken({ scopes: [audience] });
  }, [auth]);
  const getMSGraphToken = useCallback(async () => await getToken(msGraphAudience), [getToken, msGraphAudience]);
  const getMyAppsToken = useCallback(async () => await getToken(auth.clientId), [getToken, auth.clientId]);

  const onCollectionLoadSuccess = useCallback(<K extends CollectionCacheKey>(key: K, data: ValueFor<CollectionCache, K>) => {
    if (key === cacheKeyScopedLibrary('user')) {
      telemetry.reportCustomEvent('collections/network-call/get-hidden-collections/success/stats');
    } else if (key === cacheKeyScopedOrder('user')) {
      const typedData = data as ValueFor<CollectionCache, CacheKeyScopedOrder>;
      const numberOfVisiblePrivateCollections = typedData.filter(x => !x.template).length;
      telemetry.reportCustomEvent('collections/network-call/get-expanded-assigned-collections/success/stats', {
        visiblePublicCollectionsCount: data.length - numberOfVisiblePrivateCollections,
        visiblePrivateCollectionsCount: numberOfVisiblePrivateCollections
      });
    }
  }, [telemetry]);

  const collectionOrderCache = useBootCache<readonly Collection[]>('collectionOrder');
  const collectionLibraryCache = useBootCache<readonly BasicCollection[]>('collectionLibrary');
  const tileLibraryCache = useBootCache<readonly Tile[]>('tileLibrary');
  const getCollectionCache = useCallback(<K extends CollectionCacheKey | TileCacheKey>(key: K) => {
    if (keyMatches(key, cacheKeyScopedOrder('user'))) {
      return collectionOrderCache;
    } else if (keyMatches(key, cacheKeyScopedLibrary('user'))) {
      return collectionLibraryCache;
    } else if (keyMatches(key, cacheKeyScopedTileLibrary('user'))) {
      return tileLibraryCache;
    }
    return null;
  }, [collectionOrderCache, collectionLibraryCache, tileLibraryCache]);

  return (
    <AppCollectionsProvider
      serviceRootUrl={`${getApiServerBaseUrl()}api/v2`}
      acquireToken={getMyAppsToken}
      strings={{
        loadingTitle: t('loading'),
        errorTitle: t('error'),
        unknownError: t('unknownCollectionError'),
        tooFewPopularTilesError: t('tooFewPopularTilesError'),
        tooFewRecentTilesError: t('tooFewRecentTilesError')
      }}
      userId={auth.user?.objectId}
    >
      <CollectionCacheProvider getPersistentCache={getCollectionCache} onLoadSuccess={onCollectionLoadSuccess}>
        <TileCacheProvider getPersistentCache={getCollectionCache}>
         <TokenProvider acquireMsGraphToken={getMSGraphToken} acquireServiceToken={getMyAppsToken}>
          <Suspense fallback={<div />}>
            <PageRouter />
          </Suspense>
         </TokenProvider>
        </TileCacheProvider>
      </CollectionCacheProvider>
    </AppCollectionsProvider>
  );
}

export function App(): ReactElement {
  const storage = useInstrumentedStorage(localStorage);

  return (
    <ThemeProvider>
      <QueryClientProvider client={client}>
        <StorageProvider storage={storage}>
          <LaunchPageInner />
        </StorageProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
