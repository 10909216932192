
import { InitOptions } from 'i18next';
import { ICustomLanguageDetector, IDetector, IDetectorCollection, IDetectorOptions } from './interfaces';
import { normalizeLanguage } from './languageNormalization';

export function create(): ICustomLanguageDetector {
  const detectors: IDetectorCollection = {};
  let initOptions: InitOptions;
  let detectOptions: IDetectorOptions;

  return {
    type: 'languageDetector',
    async: true,
    init: (_services, i18nextDetectOptions, i18nextOptions) => {
      detectOptions = i18nextDetectOptions;
      initOptions = i18nextOptions;
    },
    detect: (callback: Function) => {
      detectLanguage(detectors, detectOptions, initOptions)
        .then(lng => callback(lng))
        .catch(() => {});
    },
    addDetector: (newDetectors: IDetector[]) => {
      newDetectors.forEach(detector => {
        detectors[detector.name] = detector;
      });
    },
    cacheUserLanguage: (_lng: string) => {
      // Leave the function body empty for now
    }
  };
}

export function normalizeLang(lng: string): string {
  return normalizeLanguage(lng?.toString().replace(/_/g, '-'));
}
export const detectLanguage = async (
  detectors: IDetectorCollection,
  detectOptions: IDetectorOptions,
  i18nextOptions: InitOptions
) => {
  const promises = detectOptions.order.map(async option => {
    if (detectors[option]) {
      return await detectors[option].lookup(detectOptions);
    } else {
      return [];
    }
  });

  // Use async await to make sure we detect the language in order
  // This is because in Msal we get the token asynchronously to detect the language
  const results = await Promise.all(promises);
  let detected: string[] = [];
  results.forEach(lng => {
    if (lng && typeof lng === 'string') {
      lng = [lng];
    }
    if (lng) {
      detected = detected.concat(lng);
    }
  });

  // Find the first detected language
  let found = '';
  detected.forEach(lng => {
    if (found !== '') {
      return;
    }
    found = lng;
  });

  return found !== '' ? normalizeLang(found) : i18nextOptions.fallbackLng;
};
