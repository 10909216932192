import { useLocalization } from '../atoms/useLocalization';
import { useCallback, useMemo } from 'react';

import { useRoute } from './useRoute';

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon?: string;
  readonly enabled: () => boolean;
  readonly act: () => void;
  readonly subActionItems?: Action[];
}

export function useGlobalActionCreateCollection(): Action {
  const [t] = useLocalization();
  const { openCreateCollection } = useRoute();

  const canCreateCollection = useCallback(() => true, []);

  const createCollection = useCallback(() => {
    openCreateCollection();
  }, [openCreateCollection]);

  return useMemo(
    () => ({
      key: 'createCollection',
      name: t('create'),
      icon: 'AddTo',
      enabled: canCreateCollection,
      act: createCollection
    }),
    [canCreateCollection, createCollection, t]
  );
}
