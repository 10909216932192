export const legacyFeatures = ['recommendations', 'bookmarks', 'personal'] as const;
type LegacyFeature = typeof legacyFeatures[number];
/**
 * All new features should be added to the backend as well
 * The feature arrays should be kept up to date with the features that need to be behind a flag
 * The features will be then fetched by the server using useFeature hook
 */
// Add all new features here
export const prefixedFeatures = ['vertical', 'popularCollection', 'recentCollection', 'dragAndDrop', 'appDescription', 'implicitApps', 'modernManage'] as const;
type PrefixedFeature = typeof prefixedFeatures[number];

/** Features that can be toggled on/off by the end user. */
export const selectableFeatures = ['vertical'] as const;
export type SelectableFeature = typeof selectableFeatures[number] & Feature;

/** Features that can be toggled by a query parameter. */
export const features = [...legacyFeatures, ...prefixedFeatures] as const;
export type Feature = LegacyFeature | PrefixedFeature;
