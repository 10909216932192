/* eslint-disable max-len */
import { ContextualMenuItemType } from '@fluentui/react';
import { useCallback, useMemo } from 'react';

import { useCollectionModals } from '../atoms/CollectionModalsProvider';
import { useLocalization } from '../atoms/useLocalization';
import { useToggleAppLayout } from '../atoms/useToggleAppLayout';
import { useToggleViewMode } from '../atoms/useToggleViewMode';

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: () => boolean;
  readonly act: () => void;
}

export function useGlobalActionCustomizeView(): Action {
  const [t] = useLocalization();
  const { appLayout, setAppLayout } = useToggleAppLayout(false);
  const { viewMode, setViewMode } = useToggleViewMode(false);
  const { pushModal } = useCollectionModals();

  const canCustomizeView = useCallback(() => true, []);
  const canManageCollections = useCallback(() => true, []);

  const manageCollections = useCallback(() => {
    void pushModal('manage');
  }, [pushModal]);

  const toggleViewMode = useCallback(() => {
    setViewMode(viewMode === 'Focus' ? 'Continuous' : 'Focus');
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [setViewMode, viewMode]);

  const toggleAppLayout = useCallback(() => {
    setAppLayout(appLayout === 'List' ? 'Grid' : 'List');
  }, [setAppLayout, appLayout]);

  return useMemo(
    () => ({
      key: 'customizeView',
      name: t('customizeView'),
      icon: 'AutoEnhanceOn',
      enabled: canCustomizeView,
      act: () => {},
      subActionItems: [
        { key: 'manageCollections', name: t('manageAll'), enabled: canManageCollections, act: manageCollections },
        { key: 'div', name: 'Div', enabled: true, itemType: ContextualMenuItemType.Divider },
        { key: 'header', name: t('layout'), enabled: true, itemType: ContextualMenuItemType.Header },
        { key: 'menu', name: appLayout === 'Grid' ? t('switchToListView') : t('switchToGridView'), icon: appLayout === 'Grid' ? 'list' : 'GridViewMedium', enabled: true, act: toggleAppLayout },
        { key: 'mode', name: viewMode === 'Focus' ? t('switchToContinuousMode') : t('switchToFocusMode'), icon: viewMode === 'Focus' ? 'OpenPaneMirrored' : 'DockLeft', enabled: true, act: toggleViewMode }
      ]
    }),
    [t, canCustomizeView, manageCollections, canManageCollections, appLayout, toggleAppLayout, viewMode, toggleViewMode]
  );
}
