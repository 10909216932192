import { MsalAuthenticationResult } from '@azure/msal-react';
import { AuthErrorEnum, AuthPrompt, AuthenticatedTemplate, useAuth } from '@iamexperiences/feature-auth';
import React, { ReactElement, ReactNode, useEffect } from 'react';

import { useTelemetryUtils } from '../../shared/Telemetry/useTelemetryUtils';
import { useDataBoundary } from './useDataBoundary';

interface AuthUserProps {
  readonly children?: ReactNode;
}

/**
 * This verification has to happen after the AuthenticatedTemplate
 * and before other components are rendered.
 * This is an empty component which just passes the children components
 * through. But it verifies the claim for Eu tenant boundary and updates the
 * telemetry object accordingly.
 * @param children - React nodes that an authenticated user can view
 */
function DetermineTelemetryEuBoundary({ children }: AuthUserProps): ReactElement {
  const { dataBoundary, isRegionalEndpointEnabled } = useDataBoundary();
  const { updateTelemetryReference } = useTelemetryUtils();

  useEffect(() => {
    updateTelemetryReference(isRegionalEndpointEnabled, dataBoundary);
  }, [dataBoundary, isRegionalEndpointEnabled, updateTelemetryReference]);

  return (
    <>{children}</>
  );
}

const ErrorComponent: React.ElementType<MsalAuthenticationResult> = ({error}) => {
  const { auth } = useAuth();
  auth.logError(AuthErrorEnum.OTHER_AUTH_ERROR, `Encountered error in AuthenticatedTemplate: ${JSON.stringify(error)}. Redirecting to login page.`);
  auth.login({ prompt: AuthPrompt.SELECT_ACCOUNT });
  return null;
}

export function AuthenticatedUser({ children }: AuthUserProps): ReactElement {
  return (
    <AuthenticatedTemplate errorComponent={ErrorComponent}>
      <DetermineTelemetryEuBoundary>
        {children}
      </DetermineTelemetryEuBoundary>
    </AuthenticatedTemplate>
  );
}
