import React, { createContext, ReactElement, ReactNode, useContext } from 'react';
import { ValueFor } from '../utilities/cache';
import { Cache, CacheKeyItem, CacheKeyScopedLibrary, CacheKeyScopedOrder } from './useCollectionCacheClient';

export interface CollectionCacheConfig {
  /** Accessor for persistent cache to warm up the session cache on startup. */
  readonly getPersistentCache?: <K extends Cache[0]>(key: K) => [value: ValueFor<Cache, K> | null, setValue?: (value: ValueFor<Cache, K>) => void] | null;

  /** Callback executed when data is loaded into a session cache. Hook provided for telemetry purposes. */
  readonly onLoadSuccess?: <K extends CacheKeyScopedLibrary | CacheKeyScopedOrder | CacheKeyItem>(key: K, data: ValueFor<Cache, K>) => void;
}

// Exported for testing
export const Context = createContext<CollectionCacheConfig | null>(null);

export interface CollectionCacheProviderProps extends CollectionCacheConfig {
  readonly children?: ReactNode;
}

export function useCollectionCacheConfig(): CollectionCacheConfig {
  // This provider is optional
  return useContext(Context) ?? {};
}

/** Provider for optional extensions to the default collection caching behavior. */
export function CollectionCacheProvider({
  children, ...config
}: CollectionCacheProviderProps): ReactElement {
  return <Context.Provider value={config}>{children}</Context.Provider>;
}
