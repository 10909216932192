import { useCallback } from "react";
import { useExpiringUserStorage } from "../atoms/useExpiringUserStorage";

type Key =
  // legacy
  'AppCollections' |
  // current
  'collectionOrder' |
  'collectionLibrary' |
  'tileLibrary';

export function useBootCache<T>(key: Key): [
  value: T | null,
  setValue: (value: T) => void
] {
  const { getItem, setItem } = useExpiringUserStorage<typeof key, T>();
  const state = getItem(key);
  const setState = useCallback((value: T) => setItem(key, value), [setItem]);
  return [state, setState];
}
