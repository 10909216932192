import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useAuth } from '@iamexperiences/feature-auth';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useLocalization } from '../Apps/atoms/useLocalization';
import { addDataBoundaryHeaders } from '../Apps/utils/DataBoundaryHeaders';
import {
  getApiServerUrl
} from '../Apps/utils/getApiServerUrl';
import { AppsActions } from '../shared/Telemetry/AppsActions';
import { AppsRedirectError } from './AppsRedirectError';
import { AppsRedirectTelemetryEvent } from './AppsRedirectTelemetryEvents';

const ppe = '4925308c-f164-4d2d-bc7e-0631132e9375';
const prod = '9188040d-6c67-4c5b-b112-36a304b66dad';
const msaTenantIds = new Set([ppe, prod]);

const getTenantToSwitchTo = (tenants: ITenant[]): string | null => {
  const availableTenants = tenants.filter((tenant) => {
    return !msaTenantIds.has(tenant.tenantId);
  });
  if (availableTenants.length <= 0) {
    return null;
  } else {
    return availableTenants[0].tenantId;
  }
};

interface ITenant {
  readonly tenantId: string;
  readonly displayName: string;
  readonly countryCode: string;
  readonly domains: string[];
  readonly isHomeTenant: boolean;
}

interface IMsaAppsRedirectProps {
  readonly username?: string;
}

const MsaAppsRedirect: React.FC<IMsaAppsRedirectProps> = ({ username }) => {
  const [t] = useLocalization();
  const { auth } = useAuth();
  const scopes = React.useMemo(() => [`${auth.clientId}/workspace.read`, `${auth.clientId}/workspace.write`],
    [auth.clientId]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const telemetry = useTelemetry();

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      const tenantsList = await (async () => {
        try {
          const tokenValue = await auth.acquireToken({ scopes });
          telemetry.reportCustomEvent(AppsRedirectTelemetryEvent.TokenRetrieved);

          const headers = addDataBoundaryHeaders(auth);
          headers.append('Authorization', `Bearer ${tokenValue}`);
          const tenantsResponse = await fetch(getApiServerUrl('api/me/tenants'), {
            headers
          });
          const isNotOkayResponse = !tenantsResponse.ok;
          if (isNotOkayResponse) {
            throw new Error(`Non 200 response received: ${tenantsResponse.status}`);
          }

          const tenantsJson = (await tenantsResponse.json()) as ITenant[];
          telemetry.reportCustomEvent(AppsRedirectTelemetryEvent.TenantsRetrievedSuccessfully, {
            message: JSON.stringify(tenantsJson)
          });
          return tenantsJson;
        } catch (error) {
          telemetry.error(AppsActions.authRedirectError, { error });
          telemetry.reportCustomEvent(AppsRedirectTelemetryEvent.TenantsRetrievalFailed);
          setErrorMessage(error as any);
          return null;
        }
      })();

      if (tenantsList === null) {
        return;
      }

      const tenantId = getTenantToSwitchTo(tenantsList ?? []);
      if (tenantId === null) {
        telemetry.reportCustomEvent(AppsRedirectTelemetryEvent.NoAADTenants);
        setErrorMessage(t('noAvailableAADTenant'));
      } else {
        telemetry.reportCustomEvent(AppsRedirectTelemetryEvent.PerformingTenantRedirect, {
          tenantId
        });
        void auth.login({ tenantId, ...(username !== undefined && { loginHint: username }) });
      }
    };
    if (errorMessage === null) {
      void runEffect();
    }
  }, [auth, errorMessage, scopes, t, username, telemetry]);

  if (errorMessage) {
    return <AppsRedirectError message={errorMessage} />;
  }

  return null;
};

export const AppsRedirect: React.FC = ({ children }) => {
  const { auth } = useAuth();
  const user = auth.user;
  const isLoading = user === null;
  const tenantId = user?.tenantId ?? '';
  const username = user?.email;
  const isMsaTenant = msaTenantIds.has(tenantId.toLowerCase());
  const telemetry = useTelemetry();

  useEffect(() => {
    if (tenantId !== undefined && tenantId.length > 0) {
      telemetry.reportCustomEvent(AppsRedirectTelemetryEvent.TenantIdLoaded, { tenantId });
    }
  }, [telemetry, tenantId]);

  useEffect(() => {
    if (isMsaTenant) {
      telemetry.reportCustomEvent(AppsRedirectTelemetryEvent.MsaTenantCodePathStarting, {
        tenantId
      });
    }
  }, [isMsaTenant, telemetry, tenantId]);

  if (isLoading) {
    return null;
  }

  return <>{isMsaTenant ? <MsaAppsRedirect username={username}>{children}</MsaAppsRedirect> : children}</>;
};
