import { useCallback, useMemo } from 'react';
import { TypedStorage } from '../StorageProvider';
import { useUserStorage } from '../useUserStorage';
import { SelectableFeature } from './features';

export type UserPreviewState =
  'OptIn' |
  'OptOut';

function mapKey(feature: SelectableFeature | null): string | null {
  return `preview:${feature}`;
}

export function useFeatureStorage(): TypedStorage<SelectableFeature, UserPreviewState> {
  const { getItem, setItem, removeItem } = useUserStorage<string, UserPreviewState>();
  const getFeature = useCallback((key: SelectableFeature | null) => getItem(mapKey(key)), [getItem]);
  const setFeature = useCallback((key: SelectableFeature | null, value: UserPreviewState) => setItem(mapKey(key), value), [setItem]);
  const removeFeature = useCallback((key: SelectableFeature | null) => removeItem(mapKey(key)), [removeItem]);
  return useMemo(() => ({
    getItem: getFeature,
    setItem: setFeature,
    removeItem: removeFeature
  }), [getFeature, setFeature, removeFeature]);
}
