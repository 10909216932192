import React, { createContext, ReactElement, ReactNode, useContext } from 'react';

export interface TokenProviderQueryConfig {
  // Callback for getting a bearer token for calling Microsoft Graph.
  readonly acquireMsGraphToken: () => Promise<string>;

  // Callback for getting a bearer token for calling the MyApps service.
  readonly acquireServiceToken?: () => Promise<string>;
}

export const Context = createContext<TokenProviderQueryConfig | null>(null);
export interface TokenProviderProps extends TokenProviderQueryConfig {
  readonly children?: ReactNode;
}
export function useTokenQueryConfig(): TokenProviderQueryConfig {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'No ancestor TokenProvider found; useTokenQueryConfig must be called within a TokenProvider context.'
    );
  }
  return context;
}
export function TokenProvider({
  children, ...config
}: TokenProviderProps): ReactElement {
  return <Context.Provider value={config}>{children}</Context.Provider>;
}