import { useMemo } from 'react';
import { BasicCollection, Collection, Scope } from '../atoms/useCollectionCacheClient';
import { useCollectionOrderQuery } from '../atoms/useCollectionOrderQuery';
import { useUserCollectionLibrary } from './useUserCollectionLibrary';

export interface CollectionResponse {
  readonly isLoading: boolean;
  readonly visible: readonly BasicCollection[];
  readonly hidden: readonly Collection[];
  readonly isError: boolean;
}

export function useCollectionsByVisibility(scope: Scope): CollectionResponse {
  const { isLoading: isOrderLoading, data: order, isError: orderError } = useCollectionOrderQuery(scope);
  const { isLoading: isLibraryLoading, collections: library, error: libraryError } = useUserCollectionLibrary();

  return useMemo(() => {
    if (isOrderLoading || isLibraryLoading) {
      // Loading
      return { isLoading: true, visible: [], hidden: [], isError: false };
    } else if (orderError || libraryError) {
      // Error
      return { isLoading: false, visible: [], hidden: [], isError: orderError || !!libraryError };
    } else {
      // Success: Hidden = Library\Order
      const visibleIds = order?.reduce((v, x) => {
        v[x.id] = true;
        return v;
      }, {}) ?? {};

      //Transform library array to a map
      const libraryMap = library?.reduce((v, x) => {
        v[x.id] = x;
        return v;
      }, {}) ?? {};

      const visible = order?.filter(x => !!libraryMap[x.id]).map(v => libraryMap[v.id]) ?? [];
      const hidden = library.filter(x => !visibleIds[x.id]);
      return { isLoading: false, visible, hidden, isError: false };
    }
  }, [isOrderLoading, isLibraryLoading, orderError, libraryError, order, library]);
}
