import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useCallback, useEffect } from 'react';

import { CollectionsTelemetryEvent } from '../../shared/Telemetry/CollectionsTelemetryEvent';
import { useSetUrlParams } from './useSetUrlParams';
import { useUserStorage } from './useUserStorage';
export type ViewMode = 'Focus' | 'Continuous';
const defaultViewState = 'Continuous';
const urlParam = 'mode';

export function useToggleViewMode(logLayout: boolean): {
  viewMode: ViewMode;
  setViewMode: (value: ViewMode | undefined) => void;
} {
  const telemetry = useTelemetry();
  const { getItem, setItem, removeItem } = useUserStorage<string, ViewMode>();
  const { setUrlParam, removeUrlParam } = useSetUrlParams();
  const key = 'ViewMode';
  const value = getItem(key);

  useEffect(() => {
    if (logLayout) {
      telemetry.reportCustomEvent(
        CollectionsTelemetryEvent.ViewMode,
        { ViewMode: value?.toString() ?? defaultViewState });
    }
  }, [telemetry, logLayout, value]);

  const setLayout = useCallback((value: ViewMode | undefined) => {
    value ? setItem(key, value) : removeItem(key);
    telemetry.reportCustomEvent(CollectionsTelemetryEvent.UserTogglesViewMode, { value });
    if (value && value !== defaultViewState) {
      setUrlParam(urlParam, value);
    } else {
      removeUrlParam(urlParam);
    }
  }, [setItem, removeItem, telemetry, setUrlParam, removeUrlParam]);

  return { viewMode: value ?? defaultViewState, setViewMode: setLayout };
}
