import { useSetTelemetry } from '@iamexperiences/ecos-telemetry';
import { useCallback } from 'react';

import { EU_DATA_BOUNDARY_CLAIM_VALUE } from '../Constants/Constants';
import { getEUTelemetryReference, getWWTelemetryReference } from './Telemetry';

interface TelemetryUtils {
  readonly updateTelemetryReference: (isRegionalEndpointEnabled: boolean, dataBoundary?: string) => void;
}
export function useTelemetryUtils(): TelemetryUtils {
  const setTelemetry = useSetTelemetry();
  const updateTelemetryReference = useCallback(
    (
      isRegionalEndpointEnabled: boolean,
      dataBoundary?: string
    ) => {
      if (dataBoundary?.toLocaleLowerCase() === EU_DATA_BOUNDARY_CLAIM_VALUE) {
        if (isRegionalEndpointEnabled) {
          setTelemetry(getEUTelemetryReference());
        }
      } else {
        setTelemetry(getWWTelemetryReference());
      }
    }, [setTelemetry]);

  return {
    updateTelemetryReference
  };
}
