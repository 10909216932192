export function ReturnTimeSinceNavigationStarted(): number {
  try {
    const navigationEntries = window?.performance?.getEntriesByType('navigation') as PerformanceNavigationTiming[];
    const timeOrigin = window?.performance?.timeOrigin;
    const domInteractive = navigationEntries?.[0]?.domInteractive;

    if (typeof timeOrigin === 'number' && typeof domInteractive === 'number') {
        let timeDifference = Date.now() - (timeOrigin + domInteractive);
        return timeDifference;
    }
  } catch {}

  return 0;
}