import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useAuth } from '@iamexperiences/feature-auth';

import { useLocalization } from '../../atoms/useLocalization';
import { toast } from 'react-hot-toast';
import { useQuery, UseQueryResult } from 'react-query';

import { useAppCollectionsQueryConfig } from './AppCollectionsProvider';
import { CacheKeyScopedSearchTileLibrary, cacheKeyScopedSearchTileLibrary, Scope, Tile } from './useTileCacheClient';
import { addDataBoundaryHeaders } from '../../utils/DataBoundaryHeaders';

/** Searches current user's assigned and implicit app tiles. */
export function useSearchTileLibraryQuery(
  scope: Scope, searchTerm?: string
): UseQueryResult<readonly Tile[], string> {
  const [t] = useLocalization();
  const telemetry = useTelemetry();
  const queryKey = cacheKeyScopedSearchTileLibrary(scope, searchTerm ?? "");
  const { serviceRootUrl, acquireToken } = useAppCollectionsQueryConfig();
  const { auth } = useAuth();

  return useQuery<readonly Tile[], string, readonly Tile[], CacheKeyScopedSearchTileLibrary>({
    queryKey,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const queryParams = searchTerm
        ? `?$search="displayName:${searchTerm.trim().toLocaleLowerCase()}"`
        : "";

      const token = await acquireToken();
      const headers = addDataBoundaryHeaders(auth);
      headers.append("Authorization", 'Bearer ' + token);

      return await fetch(`${serviceRootUrl}/me/tiles${queryParams}`, {
        headers: headers,
        // TO DO: Add `signal` with an `AbortController` here to allow for follow-up queries to
        //        abort the previous and no longer necessary query.
        //        https://identitydivision.visualstudio.com/Engineering/_workitems/edit/2489444
      })
      .then(async (response): Promise<{ value: Tile[] }> => await response.json())
      .then(data => data.value
        // Remove tiles with no name (fix for an Office regression)
        .filter(x => x.displayName != null)
        // Populate scopedId, not returned by API
        // Fix missing urls
        .map<Tile>(x => ({
          ...x,
          scopedId: x.id.split('|')[1] ?? x.id,
          fastLaunchUrl: x.fastLaunchUrl ?? x.persistentLaunchUrl
      })))
      .catch((error) => {
        telemetry.error('tiles-api/error', error);
        toast.error(t('genericApiFailure'), { id: 'genericApiFailure' });
        throw error;
      });
    }
  });
}
