import {
  BasicCollection,
  CollectionViewParams
} from '../../Apps/client-app-collections';
import {
  IAppContextMenuTelemetry,
  IAppLaunchTelemetry,
  ICollectionOrderTelemetry,
  ICollectionTelemetry
} from './ITelemetry';

export interface Tile {
  readonly id: string;
  readonly scopedId: string;
  readonly provider: string;
  readonly displayName: string;
  readonly publisher?: string;
}

function isCollectionViewParams(
  data: BasicCollection | CollectionViewParams
): data is CollectionViewParams {
  if ((data as CollectionViewParams).explicitItemIds) {
    return true;
  }
  return false;
}
export const collectionTelemetry =
(updatedCollection: BasicCollection | CollectionViewParams): ICollectionTelemetry => {
  const collTelemetry: ICollectionTelemetry = {
    id: updatedCollection.id,
    collectionNameLength: updatedCollection.displayName?.length ?? 0,
    appCount: isCollectionViewParams(updatedCollection) ? updatedCollection.explicitItemIds?.length : 0
  };
  return collTelemetry;
};

export const collectionOrderTelemetry = (
  prevCollectionsOrder: string,
  currentCollectionsOrder: string
): ICollectionOrderTelemetry => {
  const orderTelemetryObject: ICollectionOrderTelemetry = {
    previousOrder: prevCollectionsOrder,
    currentOrder: currentCollectionsOrder
  };
  return orderTelemetryObject;
};

export const collectionAppLaunchTelemetry = (appTile: Tile, collectionId: string = ''): IAppLaunchTelemetry => {
  const appLaunchTelemetry = {
    id: appTile.scopedId,
    provider: appTile.provider,
    collectionId,
    displayNameLength: appTile.displayName.length
  };

  return appLaunchTelemetry;
};

export const collectionAppLaunchActionTelemetry = (appTile: Tile, action: string): IAppContextMenuTelemetry => {
  const appActionTelemetry: IAppContextMenuTelemetry = {
    action,
    app: { ...collectionAppLaunchTelemetry(appTile) }
  };
  return appActionTelemetry;
};
