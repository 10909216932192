import { useMemo } from 'react';

export interface UrlParams {
  readonly [key: string]: string;
}

export function useUrlParams(): UrlParams {
  return useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const result = {};
    urlSearchParams.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  }, []);
}
