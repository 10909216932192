import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import { createTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import React, { ReactElement } from 'react';

const classes = mergeStyleSets({
  header: {
    backgroundColor: '#f3f2f1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
});

const customThemeForShimmer = createTheme({
  palette: {
    // palette slot used in Shimmer for main background
    neutralLight: '#e3e1df',
    // palette slot used in Shimmer for tip of the moving wave
    neutralLighter: '#eae8e7',
    // palette slot used in Shimmer for all the space around the shimmering elements
    white: '#f3f2f1'
  }
});

const ShimmerElementsNoBranding = [
  { type: ShimmerElementType.gap, width: 16 },
  { type: ShimmerElementType.line, height: 24, width: 24 },
  { type: ShimmerElementType.gap, width: 16 },
  { type: ShimmerElementType.line, height: 18, width: 86 },
  { type: ShimmerElementType.gap, width: 4 },
  { type: ShimmerElementType.gap, width: '30%' },
  { type: ShimmerElementType.line, height: 32, width: '30%' },
  { type: ShimmerElementType.gap, width: '30%' },
  { type: ShimmerElementType.line, height: 18, width: 18 },
  { type: ShimmerElementType.gap, width: 14 },
  { type: ShimmerElementType.line, height: 18, width: 18 },
  { type: ShimmerElementType.gap, width: 18 },
  { type: ShimmerElementType.circle, height: 32, width: 32 },
  { type: ShimmerElementType.gap, width: 16 }
];

const ShimmerElementsNoBrandingSmall = [
  { type: ShimmerElementType.gap, width: 16 },
  { type: ShimmerElementType.line, height: 24, width: 24 },
  { type: ShimmerElementType.gap, width: 16 },
  { type: ShimmerElementType.line, height: 18, width: 86 },
  { type: ShimmerElementType.gap, width: 4 },
  { type: ShimmerElementType.gap, width: '100%' },
  { type: ShimmerElementType.line, height: 18, width: 18 },
  { type: ShimmerElementType.gap, width: 14 },
  { type: ShimmerElementType.line, height: 18, width: 18 },
  { type: ShimmerElementType.gap, width: 18 },
  { type: ShimmerElementType.circle, height: 32, width: 32 },
  { type: ShimmerElementType.gap, width: 16 }
];

export function LazyLoadingHeader(): ReactElement {
  const screenWidth = window.innerWidth;
  return (
    <div className={classes.header}>
      <Shimmer
          shimmerColors={{
            shimmer: customThemeForShimmer.palette.neutralLight,
            shimmerWave: customThemeForShimmer.palette.neutralLighter,
            background: customThemeForShimmer.palette.white
          }}
          shimmerElements={screenWidth >= 1025 ? ShimmerElementsNoBranding : ShimmerElementsNoBrandingSmall}
        />
      </div>
  );
};
