import React, { createContext, ReactElement, ReactNode, useContext } from 'react';
import { SmartCollectionErrorStrings } from '../utilities/applySmartCollectionLimits';

interface CollectionStrings {
  /** Top-level label to show when no collections have loaded. */
  readonly loadingTitle: string;
  /** Top-level label to show when loading the collection order fails. */
  readonly errorTitle: string;
}

export type AppCollectionsStrings = SmartCollectionErrorStrings & CollectionStrings;

export interface AppCollectionsQueryConfig {
  /** The base url for the collections API, without trailing slash. */
  readonly serviceRootUrl: string;
  /** Callback for getting a bearer token for calling the collections API. */
  readonly acquireToken: () => Promise<string>;
  /** Localized strings that must be provided. */
  readonly strings: AppCollectionsStrings;
  /** Identifier for the signed in user. If not provided, some APIs will fail. */
  readonly userId?: string;
}

// Exported for testing
export const Context = createContext<AppCollectionsQueryConfig | null>(null);

export interface AppCollectionsProviderProps extends AppCollectionsQueryConfig {
  readonly children?: ReactNode;
}

export function useAppCollectionsQueryConfig(): AppCollectionsQueryConfig {
  const context = useContext(Context);
  if (!context) {
    throw new Error("No ancestor AppCollectionsProvider found; useAppCollectionsQueryConfig must be called within an AppCollectionsProvider context.");
  }
  return context;
}

export function AppCollectionsProvider({
  children, ...config
}: AppCollectionsProviderProps): ReactElement {
  return <Context.Provider value={config}>{children}</Context.Provider>;
}
