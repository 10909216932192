import { useMemo } from 'react';
import { Feature, legacyFeatures, prefixedFeatures } from './features';
import { UrlParams, useUrlParams } from '../useUrlParams';

function isEnabled(flag: Feature, urlParams: UrlParams, prefix: string = ''): boolean {
  const value = urlParams[prefix + flag];
  return value === 'true' || value === '1';
}

export function useFeatureFlags(): readonly Feature[] {
  const urlParams = useUrlParams();
  return useMemo(() => {
    return [
      ...legacyFeatures.filter(flag => isEnabled(flag, urlParams)),
      ...prefixedFeatures.filter(flag => isEnabled(flag, urlParams, 'feature.'))
    ];
  }, [urlParams]);
}
