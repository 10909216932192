import { useCallback, useMemo } from 'react';
import { replaceGuid } from '../utils/replaceGuid';
import { TypedStorage } from './StorageProvider';
import { useUserStorage } from './useUserStorage';
import { useTelemetry } from '@iamexperiences/ecos-telemetry';

export interface CacheItem<T> {
  /** Timestamp when the cache was written. */
  readonly storeTime: number;
  /** Timestamp when the cache will expire. */
  readonly expiry: number;
  /** The cached value. */
  readonly value: T;
}

export interface CacheOptions {
  /** How long the data should be persisted in the cache. Default is 2 weeks. */
  readonly timeToLiveMs?: number;
}

const defaultTimeToLive = 10 * 365 * 24 * 3600 * 1000; // 10 years in milliseconds

export function useExpiringUserStorage<Key extends string = string, Value = unknown>(
  options?: CacheOptions
): TypedStorage<Key, Value> {
  const { getItem, setItem, removeItem } = useUserStorage<Key, string>();
  const telemetry = useTelemetry();
  const getExpiringItem = useCallback((key: Key | null) => {
    // Look up the data
    let value: CacheItem<Value> | null = null;
    const jsonString = getItem(key);
    if (jsonString) {
      try {
        value = JSON.parse(jsonString);
      } catch (error) {
        telemetry.reportCustomEvent('storage/get/error', {
          key: replaceGuid(key ?? ''),
          error: error
        });
        // Data is corrupted, so remove it
        removeItem(key);
        value = null;
      }
    }

    // Evict expired data
    if (value) {
      const now = new Date();
      if (now.getTime() > value.expiry) {
        telemetry.reportCustomEvent('storage/get/expired', {
          key: replaceGuid(key ?? ''),
          expiration: value.expiry
        });
        removeItem(key);
        value = null;
      }
    }

    return value?.value ?? null;
  }, [getItem, removeItem]);

  const timeToLive = options?.timeToLiveMs ?? defaultTimeToLive;

  const setExpiringItem = useCallback((key: Key | null, value: Value) => {
    const now = new Date().getTime();
    const cacheItem: CacheItem<Value> = {
      value,
      storeTime: now,
      expiry: now + timeToLive
    };
    const data = JSON.stringify(cacheItem);
    setItem(key, data);
  }, [setItem, timeToLive]);

  return useMemo(() => ({
    getItem: getExpiringItem,
    setItem: setExpiringItem,
    removeItem
  }), [getExpiringItem, setExpiringItem, removeItem]);
}
