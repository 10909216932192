import { useLocalization } from '../atoms/useLocalization';
import { useCallback, useMemo } from 'react';
import { OpenDialogFunction } from '../molecules/useConfirmationDialog';
import { useConfirmDeleteCollectionDialog } from '../molecules/useConfirmDeleteCollectionDialog';

export interface Collection {
  readonly id: string;
  readonly displayName: string;
  readonly viewPermissions: {
    readonly canRenameList: boolean;  // Delete and Rename are both allowed only on private collections
  }
}

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: (collection: Collection) => boolean;
  readonly act: (collection: Collection) => void;
}

export function useCollectionActionDelete(openDialog: OpenDialogFunction): Action {
  const [t] = useLocalization();
  const deleteCollection = useConfirmDeleteCollectionDialog(openDialog);
  const canDelete = useCallback((collection: Collection) => collection.viewPermissions.canRenameList, []);

  return useMemo(() => ({
    key: 'delete',
    name: t('Delete'),
    icon: '',
    enabled: canDelete,
    act: deleteCollection
  }), [canDelete, deleteCollection]);
}
