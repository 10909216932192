import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useCallback, useMemo } from 'react';

import { replaceGuid } from '../utils/replaceGuid';
import { CacheItem } from './useExpiringUserStorage';

/** Removes the index signature from a type, leaving only the explicit properties. */
type Explicit<T> = {
  [K in keyof T as string extends K ? never : number extends K ? never : K]: T[K]
};

const shouldProxy: Partial<{ readonly [key in keyof Explicit<Storage>]: true }> = {
  clear: true,
  setItem: true,
  removeItem: true,
  length: true,
  key: true
};

/** Wraps a Storage object, logging telemetry on every cache lookup (hit or miss). */
export function useInstrumentedStorage(storage: Storage): Storage {
  const telemetry = useTelemetry();

  const getItem = useCallback((key: string) => {
    let value: string | null = null;
    let parsedValue: CacheItem<String> | null = null;
    let age: number = 0;
    let timeToLive: number = 0;
    const now = new Date().getTime();
    try {
      value = storage.getItem(key);
      if (value != null) {
        try {
          parsedValue = JSON.parse(value);
          if (parsedValue?.storeTime) {
            age = now - parsedValue.storeTime;
            if (parsedValue.expiry) {
              timeToLive = parsedValue.expiry - parsedValue.storeTime;
            }
          }
        } catch { }
      }
    } finally {
      // If storage is not available, that is a cache miss
      telemetry.reportCustomEvent('storage/get', {
        key: replaceGuid(key),
        hit: (value != null),
        age,
        timeToLive
      });
    }
    return value;
  }, [storage, telemetry]);

  return useMemo(() => new Proxy(storage, {
    get: (target, p, receiver) => {
      if (p === 'getItem') {
        return getItem;
      } else if (shouldProxy[p]) {
        const result = Reflect.get(target, p, receiver);
        if (typeof result === 'function') {
          return result.bind(target);
        }
        return result;
      } else {
        return getItem(p.toString());
      }
    }
  }), [storage, getItem]);
}
