import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useCallback } from 'react';

import { useUserStorage } from '../useUserStorage';
import { SelectableFeature } from './features';

export type FeatureOptInState =
  'OptIn' |
  'OptOut';

const defaultPreviewState: Readonly<Record<SelectableFeature, FeatureOptInState>> = {
  vertical: 'OptIn'
};

export function useFeatureOptInState(feature: SelectableFeature): [
  state: FeatureOptInState,
  setState: (value: FeatureOptInState | undefined) => void
] {
  const telemetry = useTelemetry();
  const { getItem, setItem, removeItem } = useUserStorage<string, FeatureOptInState>();
  const key = `preview:${feature}`;
  const value = getItem(key);
  const setValue = useCallback((value: FeatureOptInState | undefined) => {
    if (value) {
      setItem(key, value);
    } else {
      removeItem(key);
    }
    telemetry.reportCustomEvent('user-toggled-feature', {
      feature,
      value: value ?? defaultPreviewState[feature],
      implicit: value === undefined
    });
  }, [telemetry, feature, setItem, key, removeItem]);
  return [value ?? defaultPreviewState[feature], setValue];
}
