import { useAuth } from "@iamexperiences/feature-auth";
import { useCallback, useMemo } from "react";
import { TypedStorage, useStorage } from "./StorageProvider";

export function useUserStorage<Key extends string = string, Value extends string = string>(): TypedStorage<Key, Value> {
  const { getItem, setItem, removeItem } = useStorage<string, Value>();
  const {auth} = useAuth();
  const user = auth.user;
  const getUserKey = useCallback((key: Key | null) => (key && user) ? `${user.objectId}:${user.tenantId}:${key}` : null, [user]);
  const getUserItem = useCallback((key: Key | null) => getItem(getUserKey(key)), [getItem, getUserKey]);
  const setUserItem = useCallback((key: Key | null, value: Value) => setItem(getUserKey(key), value), [setItem, getUserKey]);
  const removeUserItem = useCallback((key: Key | null) => removeItem(getUserKey(key)), [removeItem, getUserKey]);
  return useMemo(() => ({
    getItem: getUserItem,
    setItem: setUserItem,
    removeItem: removeUserItem
  }), [getUserItem, setUserItem, removeUserItem]);
}
