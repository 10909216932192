import { useMemo } from 'react';
import clipboardCopy from 'clipboard-copy';
import { useLocalization } from '../atoms/useLocalization';

export interface App {
  readonly persistentLaunchUrl: string;
}

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: true;
  readonly act: ([app]: readonly [App, unknown]) => void;
}

export function useAppActionCopyLink(): Action {
  const [t] = useLocalization();

  return useMemo(() => ({
    key: 'copyLink',
    name: t('copyLink'),
    icon: 'Link',
    enabled: true,
    act: ([app]) => {
      clipboardCopy(app.persistentLaunchUrl);
    }

  }), []);
}
