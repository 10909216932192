import React, { ReactElement } from 'react';
import { isSmartOrDeferredCollection, Scope } from '../atoms/useCollectionCacheClient';
import { useCollectionOrderQuery } from '../atoms/useCollectionOrderQuery';
import { DeferredCollectionStub } from '../molecules/DeferredCollectionStub';

export interface DeferredCollectionLoaderProps {
  readonly scope: Scope;
}



/** Forces load of all deferred collections from the order. */
export function DeferredCollectionLoader({ scope }: DeferredCollectionLoaderProps): ReactElement | null {
  const { data: collections } = useCollectionOrderQuery(scope);
  const deferred = collections?.filter(isSmartOrDeferredCollection);
  return deferred ? <>{ deferred.map(x => <DeferredCollectionStub scope={scope} collectionId={x.id} key={x.id} />)}</> : null;
}
