import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useMemo } from 'react';

import { useAppCollectionsQueryConfig } from '../atoms/AppCollectionsProvider';
import { Collection, convertCollectionResponse, forbidAllActions } from '../atoms/useCollectionCacheClient';
import { useCollectionQuery } from '../atoms/useCollectionQuery';
import { useTileLibraryQuery } from '../atoms/useTileLibraryQuery';
import { applySmartCollectionLimits } from '../utilities/applySmartCollectionLimits';
import { populateTiles } from '../utilities/populateTiles';

type Result<T extends string | null | undefined> = T extends string ? Collection : null;

const loadingState = {
  loadingState: 'items',
  viewPermissions: forbidAllActions
} as const;

const errorState = {
  loadingState: 'error',
  viewPermissions: forbidAllActions
} as const;

/** Looks up all of the current user's collections. */
export function useUserCollection<T extends string | null | undefined>(id: T): Result<T> {
  const { strings } = useAppCollectionsQueryConfig();
  const telemetry = useTelemetry();

  // Look up the collection individually if we can't get it from the order
  const { isLoading: collectionIsLoading, isError: collectionIsError, data: collection, error: collectionError } = useCollectionQuery('user', id);

  // Look up the tiles to fill the collection
  const { isLoading: tilesIsLoading, isError: tilesIsError, data: tiles, error: tilesError } = useTileLibraryQuery('user');

  return useMemo(() => {
    // Show top-level loading state
    if (collectionIsLoading) {
      return { ...loadingState, id, displayName: id } as Result<T>;
    // Show top-level error state
    } else if (collectionIsError) {
      return { ...errorState, id, displayName: strings.errorTitle, error: collectionError ?? strings.unknownError } as Result<T>;
    } else if (collection) {
      const result = convertCollectionResponse(collection);
      switch (result.loadingState) {
        case 'error':
          return result as Result<T>;
        default:
          // Show the collection in a loading state
          if (tilesIsLoading) {
            return { ...result, loadingState: 'tiles', viewPermissions: forbidAllActions } as Result<T>;
          // Show the collection in an error state
          } else if (tilesIsError) {
            return {
              id: result.id,
              displayName: result.displayName,
              loadingState: 'error',
              error: tilesError ?? strings.unknownError,
              viewPermissions: forbidAllActions
            } as Result<T>;
          // Show the collection
          } else if (tiles) {
            return applySmartCollectionLimits(strings)(populateTiles(tiles, [result], telemetry)[0]) as Result<T>;
          }
      }
    }

    return null as Result<T>;
  }, [collectionIsLoading, collectionIsError, collection, id, strings, collectionError, tilesIsLoading, tilesIsError, tiles, tilesError, telemetry]);
}
