import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useCallback, useMemo } from 'react';

import { CollectionsTelemetryEvent } from '../../shared/Telemetry/CollectionsTelemetryEvent';
import { KnownCollectionIds } from '../client-app-collections/atoms/useCollectionCacheClient';
import { useUserStorage } from './useUserStorage';

export interface CollectionViewState {
  readonly collapsedCollectionIds?: string[];
  readonly selectedCollectionId?: string;
}

export interface CollapsedCollectionTelemetry {
  readonly numberOfCollapsedCollection: number;
  readonly isAllAppsCollapsed: boolean;
}

export function useCollectionViewState(): {
  collapsedCollections: string[] | undefined;
  selectedCollection: string | undefined;
  addCollapsedCollectionId: (value: string, numApps: number) => void;
  removeCollapsedCollectionId: (value: string, numApps: number) => void;
  setSelectedCollectionCache: (value: string) => void;
} {
  const telemetry = useTelemetry();
  const { getItem, setItem } = useUserStorage<string, string>();
  const key = 'collectionViewState';

  // Set the initial value of data synchronously during the initial render
  const initialData = useMemo(() => {
    try {
      const serializedValue = getItem(key);
      if (serializedValue !== null) {
        const collectionViewState = JSON.parse(serializedValue) as CollectionViewState;
        const collapsedCollectionTelemetry: CollapsedCollectionTelemetry = {
          isAllAppsCollapsed: collectionViewState.collapsedCollectionIds
            ? collectionViewState.collapsedCollectionIds.includes(KnownCollectionIds.allapps)
            : false,
          numberOfCollapsedCollection: collectionViewState.collapsedCollectionIds
            ? collectionViewState.collapsedCollectionIds.length
            : 0
        };
        telemetry.reportCustomEvent(CollectionsTelemetryEvent.CollapsedCollectionState, collapsedCollectionTelemetry);
        return collectionViewState;
      }
    } catch (error) {}
    return null;
  }, [getItem, telemetry]);

  const getCollectionViewStateFromCache = useCallback(() => {
    try {
      const serializedValue = getItem(key);
      return serializedValue !== null ? JSON.parse(serializedValue) as CollectionViewState : null;
    } catch {
      return null;
    }
  }, [getItem]);

  const updateCollapsedCollections = useCallback((updatedCollections: string[], collectionViewState?: CollectionViewState) => {
    const updatedCollectionViewState: CollectionViewState =
      {
        ...collectionViewState,
        collapsedCollectionIds: updatedCollections
      };
    setItem(key, JSON.stringify(updatedCollectionViewState));
  }, [setItem]);

  const setSelectedCollectionCache = useCallback((value: string) => {
    try {
      const collectionViewState = getCollectionViewStateFromCache();
      if (collectionViewState !== null) {
        const updatedCollectionViewState: CollectionViewState =
          {
            ...collectionViewState,
            selectedCollectionId: value
          };
        setItem(key, JSON.stringify(updatedCollectionViewState));
      } else {
        const updatedCollectionViewState: CollectionViewState =
          {
            selectedCollectionId: value
          };
        setItem(key, JSON.stringify(updatedCollectionViewState));
      }
    } catch {}
  }, [getCollectionViewStateFromCache, setItem]);

  const addCollapsedCollectionId = useCallback((value: string, numApps: number) => {
    try {
      telemetry.reportCustomEvent(CollectionsTelemetryEvent.CollapsedCollection, { id: value, apps: numApps });
      const collectionViewState = getCollectionViewStateFromCache();
      if (collectionViewState !== null) {
        updateCollapsedCollections([...collectionViewState.collapsedCollectionIds ?? [], value], collectionViewState);
      } else {
        updateCollapsedCollections([value]);
      }
    } catch {}
  }, [getCollectionViewStateFromCache, telemetry, updateCollapsedCollections]);

  const removeCollapsedCollectionId = useCallback((value: string, numApps: number) => {
    try {
      telemetry.reportCustomEvent(CollectionsTelemetryEvent.ExpandCollection, { id: value, apps: numApps });
      const collectionViewState = getCollectionViewStateFromCache();
      if (collectionViewState !== null) {
        updateCollapsedCollections(
          collectionViewState.collapsedCollectionIds?.filter((id) => id !== value) ?? [], collectionViewState);
      }
    } catch {}
  }, [getCollectionViewStateFromCache, telemetry, updateCollapsedCollections]);

  return {
    collapsedCollections: initialData ? initialData.collapsedCollectionIds : undefined,
    selectedCollection: initialData ? initialData.selectedCollectionId : undefined,
    addCollapsedCollectionId,
    removeCollapsedCollectionId,
    setSelectedCollectionCache
  };
}
