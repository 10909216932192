import { useMemo } from 'react';
import { useLocalization } from '../atoms/useLocalization';
import { useUpdateCollectionView } from '../client-app-collections';

export interface App {
  readonly id: string;
}

export interface Collection {
  readonly id: string;
  readonly view?: {
    readonly explicitItemIds?: readonly string[];
    readonly hiddenItemIds?: readonly string[];
  } | null;
  readonly viewPermissions?: {
    readonly canRemoveItems: boolean
  };
}

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: ([app, collection]: readonly [unknown, Collection]) => boolean;
  readonly act: ([app, collection]: readonly [App, Collection]) => void;
}

export function useAppActionRemove(): Action {
  const [t] = useLocalization();
  const updateCollection = useUpdateCollectionView();

  return useMemo(() => ({
    key: 'removeAppFromCollection',
    name: t('removeAppFromCollection'),
    icon: 'Clear',
    enabled: ([, collection]) => collection.viewPermissions?.canRemoveItems ?? false,
    act: ([app, collection]) => {
      updateCollection({
        id: collection.id,
        explicitItemIds: collection.view?.explicitItemIds?.filter(id => id !== app.id),
        hiddenItemIds: collection.view?.hiddenItemIds ? [...collection.view.hiddenItemIds, app.id] : [app.id]
      });
    }

  }), [t, updateCollection]);
}
