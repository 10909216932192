import * as React from 'react';

import { useLocalization } from '../Apps/atoms/useLocalization';
import {
  FullSizeError,
  IFullSizeErrorComponentLabels,
  IFullSizeErrorProps
} from '../Apps/molecules/FullsizeError';
import { Omit } from './helpers.types';

export type LocalizedFullSizeErrorProps = Omit<IFullSizeErrorProps, 'labels'>;

export const LocalizedFullSizeError: React.FC<LocalizedFullSizeErrorProps> = (props) => {
  const [t] = useLocalization();
  const constants: IFullSizeErrorComponentLabels = {
    timeLabel: t('time'),
    errorCodeLabel: t('errorCode'),
    correlationIdLabel: t('correlationId'),
    sessionIdLabel: t('sessionId'),
    userIdLabel: t('userId'),
    detailsLabel: t('details'),
    unknownErrorTitle: t('unknownErrorTitle'),
    viewMoreLabel: t('viewMore'),
    viewLessLabel: t('viewLess'),
    defaultErrorButtonActionLabel: t('action'),
    downloadErrorTooltipLabel: t('downloadError')
  };
  return <FullSizeError labels={constants} {...props} />;
};
