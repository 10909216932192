import { useCallback } from 'react';
import { useLocalization } from '../atoms/useLocalization';
import { OpenDialogFunction } from './useConfirmationDialog';
import { useDeleteCollectionView } from '../client-app-collections';

export interface Collection {
  readonly id: string;
  readonly displayName: string;
}

/** Returns a function that will delete the given collection after the user confirms in a dialog. */
export function useConfirmRestoreCollectionDialog(openDialog: OpenDialogFunction): (collection: Collection) => void {
  const [t] = useLocalization();
  const restoreCollection = useDeleteCollectionView();
  return useCallback((collection: Collection) => {
    openDialog({
      action: "restoreCollection",
      title: t("restoreCollectionTitle", { collectionName: collection.displayName ?? collection.id }),
      body: t("restoreCollectionBody"),
      confirm: t("Restore"),
      deny: t("Cancel")
    }, () => restoreCollection(collection.id));
  }, [openDialog]);
}
