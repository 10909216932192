import { useAuth } from '@iamexperiences/feature-auth';
import { useLocalization } from '../../atoms/useLocalization';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useAppCollectionsQueryConfig } from '../atoms/AppCollectionsProvider';
import {
  cacheKeyItem,
  collectionLoadingMetadata,
  CollectionResponse,
  CollectionView,
  useCollectionCacheClient
} from '../atoms/useCollectionCacheClient';
import { useLocalUpdateCollection } from '../molecules/useLocalUpdateCollection';
import { OptimisticUpdate } from '../utilities/cache';
import { addDataBoundaryHeaders } from '../../utils/DataBoundaryHeaders';

export interface CollectionViewParams extends Partial<CollectionView> {
  readonly id: string;
}

interface CollectionParams {
  id: string;
  name?: string;
  knownApps?: readonly string[];
  hiddenApps?: readonly string[];
  useDefaultOrder?: boolean;
}

function mapParamsForApiV1(params: CollectionViewParams): CollectionParams {
  const v1Params: CollectionParams = { id: params.id };
  if (params.displayName !== undefined) v1Params.name = params.displayName;
  if (params.explicitItemIds !== undefined) v1Params.knownApps = params.explicitItemIds;
  if (params.hiddenItemIds !== undefined) v1Params.hiddenApps = params.hiddenItemIds;
  if (params.useViewOrder !== undefined) v1Params.useDefaultOrder = !params.useViewOrder;
  return v1Params;
}

export function useUpdateCollectionView(): (params: Readonly<CollectionViewParams>) => void {
  const [t] = useLocalization();
  const { serviceRootUrl, acquireToken } = useAppCollectionsQueryConfig();
  const client = useCollectionCacheClient();
  const localUpdateCollection = useLocalUpdateCollection();
  const { auth } = useAuth();

  const { mutate } = useMutation<void, unknown, Readonly<CollectionViewParams>, OptimisticUpdate>(async params => {
    const token = await acquireToken();
    const headers = addDataBoundaryHeaders(auth);
    headers.append('Authorization', 'Bearer ' + token);
    headers.append('Content-Type', 'application/json');

    // TODO(tada): Update to use new V2 view update API.
    const response = await fetch(`${serviceRootUrl.slice(0, serviceRootUrl.length - 7)}/api/users/me/workspaces/${params.id}`, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(mapParamsForApiV1(params))
    });
    if (!response.ok) {
      return await Promise.reject(response.statusText);
    }
  }, {
    onMutate: async params => {
      const itemKey = cacheKeyItem('user', params.id);
      const collection =
        client.getQueryData(itemKey) ?? collectionLoadingMetadata(params.id, params.displayName ?? params.id);

      const afterUpdate: CollectionResponse = {
        ...collection,
        view: {
          displayName: params.displayName ?? collection.view?.displayName,
          useViewOrder: params.useViewOrder ?? collection.view?.useViewOrder ?? false,
          explicitItemIds: params.explicitItemIds ?? collection.view?.explicitItemIds ?? [],
          hiddenItemIds: params.hiddenItemIds ?? collection.view?.hiddenItemIds ?? []
        }
      };

      return await localUpdateCollection('user', afterUpdate);
    },
    onError: async (_error, _id, update) => {
      toast.error(t('updateCollectionFailure'), { id: 'workspaces-api/update/error' });
      await update?.rollback();
    },
    onSuccess: async (_data, _id, update) => {
      await update?.complete();
      toast.success(t('collectionUpdateSuccess'), { id: 'collectionUpdateSuccess' });
    }
  });

  return mutate;
}
