import { CacheLocation, getMsalAuth } from '@iamexperiences/feature-auth';

import { getFeatureFlagWithoutHooks } from './shared/getFeatureFlagWithoutHooks';
import { settingsManager } from './shared/settings';
import { telemetry } from './shared/Telemetry/Telemetry';

export const auth = getMsalAuth(
  {
    clientId: settingsManager.getRequired('clientId'),
    authority: settingsManager.getRequired('authAuthorityEndpoint'),
    cacheLocation: getFeatureFlagWithoutHooks('useLocalStorage', true) ? CacheLocation.LocalStorage : CacheLocation.SessionStorage,
    redirectUriForHiddenIframeFlow: `https://${window.location.hostname}/blank.html`
  },
  {
    onError: (message: string) => {
      console.error(message);
      telemetry?.error('feature-auth/error', message);
    },
    onInfo: (message: string) => {
      if (process.env.NODE_ENV === 'test') return;
      console.log(message);
      telemetry?.reportCustomEvent('feature-auth/info', { message });
    },
    onVerbose: (message) => {
      console.log(message);
    },
    onWarning: (message) => {
      console.log(message);
    }
  }
);
