import i18n from './i18n';

import { csrf } from './csrf';

export const bootstrap = async (_location: Location = window.location): Promise<boolean> => {
  const bootRequirements: Array<Promise<boolean>> = [];
  csrf.init();
  // add the promise returned by i18n.init to bootRequirements
  bootRequirements.push(i18n.init().then(() => true));
  return await Promise.all(bootRequirements).then(bootRequirementResults =>
    bootRequirementResults.reduce((canBoot: boolean, bootRequirementResult) => {
      if (!canBoot || !bootRequirementResult) {
        return false;
      }

      return true;
    }, true)
  );
};
