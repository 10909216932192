import { settingsManager } from '../../shared/settings';
import { IAuth } from "@iamexperiences/feature-auth";
import { ITelemetry } from '@iamexperiences/ecos-telemetry';
import { DataBoundaryRegion, USGovRegion, UserClaims, getUserClaim } from './DataBoundaryHeaders';

export function getOfficeSuiteHeaderEndpoint(auth: IAuth, telemetry: ITelemetry): string | undefined {
  let audience = settingsManager.get('officeSuiteHeaderEndpoint');
  const dataBoundary = getUserClaim(auth, UserClaims.DataBoundary);
  if (dataBoundary === DataBoundaryRegion.EUDB){
    const audienceeu = settingsManager.get('officeSuiteHeaderEndpointEU');
    if (!audienceeu) {
      telemetry.error('officeSuiteHeaderEndpointEU setting not present, check settings', {});
    } else{
      audience = audienceeu;
    }
  }

  audience = checkAndGetDODAudience(auth, telemetry, audience);

  telemetry.reportCustomEvent('officesuite/invoke', {
    message: audience
  });

  return audience;
}

export function getOfficeSuiteHeaderScope(auth: IAuth, telemetry: ITelemetry): string | undefined {
  let audience =  settingsManager.get('officeSuiteHeaderScope') ?? settingsManager.get('officeSuiteHeaderEndpoint');

  audience = checkAndGetDODAudience(auth, telemetry, audience);

  if (!audience){
    telemetry.error('officeSuiteHeaderScope and officeSuiteHeaderEndpoint settings not present, check settings', {});
  }

  return audience;
}

function checkAndGetDODAudience(auth: IAuth, telemetry: ITelemetry, audience?: string): string | undefined {
  const subScope = getUserClaim(auth, UserClaims.TenantRegionSubScope);
  if (subScope === USGovRegion.DOD) {
    const audienceDOD = settingsManager.get('officeSuiteHeaderEndpointDOD');
    if (!audienceDOD) {
      telemetry.error(`DoD setting not present, check settings`, {});
    } else {
      audience = audienceDOD;
    }
  }
  return audience;
}
