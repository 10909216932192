import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useCallback } from 'react';
import { AppsActions } from '../../shared/Telemetry/AppsActions';
import { IAppInfo, IAppItem } from './Header.types';

type AppKeys = 'myApps' | 'myAccess' | 'myGroups' | 'myAccount';

interface EnabledApps {
  readonly getMenuItems: (
    appsMap: ReadonlyMap<AppKeys, IAppInfo>,
    hasP2License: boolean,
    upn?: string,
    tenantId?: string)
  => IAppItem[];
}

export function useEnabledApps(): EnabledApps {
  const telemetry = useTelemetry();

  const onSwitchApp = useCallback((targetApp: string) => () => {
    telemetry.reportCustomEvent(AppsActions.switchApp, {
      currentApp: 'My Apps',
      targetApp
    });
  }, [telemetry]);

  const getMenuItems = useCallback((
    appsMap: ReadonlyMap<AppKeys, IAppInfo>,
    hasP2License: boolean,
    upn?: string,
    tenantId?: string
  ): IAppItem[] => {
    const appItems: IAppItem[] = [];

    for (const [key, value] of appsMap) {
      if (key === 'myAccess' && !hasP2License) {
        continue;
      }

      const app = value;

      if (key === 'myApps') {
        appItems.push({
          key,
          text: app.appName,
          selected: true,
          href: app.defaultUrl,
          target: '_self'
        });
      } else {
        const baseUrl = new URL(app.defaultUrl);
        baseUrl.searchParams.set('tenantId', tenantId ?? '');
        baseUrl.searchParams.set('upn', upn ?? '');
        appItems.push({
          key,
          text: app.appName,
          href: baseUrl.toString(),
          target: '_blank',
          onClick: onSwitchApp(app.appKey)
        });
      }
    }

    return appItems;
  }, [onSwitchApp]);

  return {
    getMenuItems
  };
}
