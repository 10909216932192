import { Link } from '@fluentui/react';
import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { AuthPrompt, useAuth } from '@iamexperiences/feature-auth';
import {
  FlexPanelCollections,
  IConfiguration,
  ILocalizationMap,
  IOrganization
} from '@iamexperiences/suite-header';
import { ITenantProps } from '@iamexperiences/suite-header/lib-commonjs/SuiteHeader/TenantList/ITenantProps';
import { useGetResourceTenants } from '@iamexperiences/suite-header/lib/SuiteHeader/hooks/apis/useGetResourceTenants';
import i18next from 'i18next';
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Constants } from '../../shared/Constants/Constants';
import { settingsManager } from '../../shared/settings';
import { AppsActions } from '../../shared/Telemetry/AppsActions';
import { useLocalization } from '../atoms/useLocalization';
import {
  useGetCurrentOrganization,
  useGetOfficeShellData,
  useGetOrganizationBranding,
  useGetUserImage,
} from '../client-header-tenant';
import { useRoute } from '../organisms/useRoute';
import { IAppInfo, NavBarWrapper } from './Header.types';
import { LazyLoadingHeader } from './LazyLoadingHeader';
import { useEnabledApps } from './useEnabledApps';

const SearchBox = lazy(async () =>
  await import(/* webpackPrefetch: true, webpackChunkName: "searchbox" */ './SearchBox').then((module) => ({ default: module.SearchBox })));
const SuiteHeader = lazy(async () =>
  await import(/* webpackPrefetch: true, webpackChunkName: "ecos-header" */ '@iamexperiences/suite-header/lib/SuiteHeader').then((module) => ({ default: module.SuiteHeader })));

type AppKeys = 'myApps' | 'myAccess' | 'myGroups' | 'myAccount';

const DEFAULT_HELPLINK =
  'https://docs.microsoft.com/en-us/azure/active-directory/user-help/my-apps-portal-end-user-access';
const DEFAULT_EVO_URL = 'https://login.microsoftonline.com';

const dataCacheKeys = ['tileLibrary', 'collectionOrder', 'collectionLibrary'];

export const Header: React.FC = () => {
  const { auth } = useAuth();
  const user = auth.user;
  const clientId = auth.clientId;
  const tenantId = user?.tenantId;
  const userId = user?.objectId;
  const username = user?.username;
  const name = user?.name;
  const claims = user && (user.rawClaims as { xms_pl?: unknown } | undefined);
  const [language, setLanguage] = useState<string | undefined>(
    claims && typeof claims.xms_pl === 'string' ? claims.xms_pl : undefined
  );
  const helpLink = settingsManager.get('panelHelpLink') ?? DEFAULT_HELPLINK;
  const evoBase = settingsManager.get('azureInstance') ?? DEFAULT_EVO_URL;
  const msGraphBaseUrl = settingsManager.getRequired('msGraphResourceName');
  const myAccessLink = settingsManager.get('myAccessUrl');
  const myGroupsLink = settingsManager.get('myGroupsUrl');
  const viewAccountLink = settingsManager.get('meControlViewAccountLink');
  const isOfficeAppLauncherDisabled = settingsManager.get('isOfficeAppLauncherDisabled');
  const isUniversalMeControlEnabled = settingsManager.get('isUniversalMeControlEnabled') ?? false;

  const telemetry = useTelemetry();
  const { goToHomePage } = useRoute();

  const { getMenuItems } = useEnabledApps();

  const { data: currentOrganization } = useGetCurrentOrganization(tenantId ?? '');
  const { data: branding } = useGetOrganizationBranding(tenantId ?? '');
  const { data: userImageUrl } = useGetUserImage(userId ?? '');
  const { data: officeShellData } = useGetOfficeShellData(tenantId ?? '');
  const isGuestUser = useMemo(() => user?.homeTenantId !== user?.tenantId, [user]);

  const bannerLogo = useMemo(() => {
    if (branding && branding.cdnList?.length > 0 && branding?.bannerLogoRelativeUrl) {
      return `https://${branding.cdnList[0]}/${branding.bannerLogoRelativeUrl}`;
    } else {
      return undefined;
    }
  }, [branding]);

  const [t] = useLocalization();
  const localizationMap: ILocalizationMap = {
    apps: t('Apps'),
    accessibilityLink: t('frenchAccessibilityLinkText'),
    switchOrgIntroText: t('switchOrgIntroText'),
    currentOrganizationTitle: t('currentOrganizationTitle'),
    otherOrganizationsTitle: t('otherOrganizationsTitle'),
    manageOrganizations: t('manageOrganizationsLinkText'),
    closePanelAriaLabel: t('closeButtonAriaLabel'),
    tenantBrandingImageAlt: t('tenantBranding', { displayName: currentOrganization?.displayName }),
    organizations: t('organizations'),
    loadingOrganizations: t('loadingOrganizations'),
    organizationsEmpty: t('emptyOrganizations'),
    finishLoading: t('loaded'),
    organizationError: t('getOrganizationsError'),
    helpPanelHeaderText: t('help'),
    viewApps: t('viewApps'),
    helpPanelTermsOfUseLink: t('termsOfUse'),
    helpPanelPrivacyLink: t('privacy'),
    accountManager: t('accountManager'),
    switchOrganization: t('switchOrganization'),
    moreOptions: t('more'),
    leaveNewExp: t('leaveNewExp'),
    waffleNotAvailableHeader: t('fallbackWaffleHeaderText'),
    waffleNotAvailableDescription: t('fallbackWaffleMainText')
  };

  const appsMap: Map<AppKeys, IAppInfo> = new Map<AppKeys, IAppInfo>();
  appsMap.set('myApps', {
    appKey: 'My Apps',
    appName: t('My Apps'),
    defaultUrl: '/'
  });

  viewAccountLink &&
    appsMap.set('myAccount', {
      appKey: 'My Account',
      appName: t('myAccount'),
      defaultUrl: viewAccountLink
    });

  myGroupsLink &&
    appsMap.set('myGroups', {
      appKey: 'My Groups',
      appName: t('myGroups'),
      defaultUrl: myGroupsLink
    });

  myAccessLink &&
    appsMap.set('myAccess', {
      appKey: 'My Access',
      appName: t('myAccess'),
      defaultUrl: myAccessLink
    });

  useEffect(() => {
    const lng = i18next.languages[0];
    setLanguage(lng);
  }, [i18next.languages[0]]);

  const changeTenant = useCallback((targetTenant: ITenantProps, currentTenant?: IOrganization) => {
    telemetry.reportCustomEvent(AppsActions.switchOrg, {
      source: currentTenant?.displayName,
      target: targetTenant.displayName
    });
    auth.login({ tenantId: targetTenant.tenantId, loginHint: auth.user?.username });
  }, [auth, telemetry]);

  const switchAccount = useCallback(() => {
    auth.login({ prompt: AuthPrompt.SELECT_ACCOUNT });
  }, [auth]);

  const switchTo = useCallback((upn: string) => {
    auth.login({ loginHint: upn });
  }, [auth]);

  const handleHeaderRender = useCallback(() => {
    telemetry.reportCustomEvent(AppsActions.headerRendered);
  }, [telemetry]);

  const manageOrganizationControl = useCallback(() => {
    const organizationLink = new URL(`${viewAccountLink ?? ''}/organizations`);
    organizationLink.searchParams.set(Constants.TENANT_ID_QUERY_STRING, tenantId ?? '');
    organizationLink.searchParams.set(Constants.UPN_QUERY_STRING, username ?? '');
    return (
      <Link href={organizationLink.toString()} target="_blank">
        {t('manageOrganizationsLinkText')}
      </Link>
    );
  }, [viewAccountLink, tenantId, username, t]);

  const getSearchBoxRenderer = useCallback(() => {
    return (
      <Suspense fallback={<div></div>}>
        <SearchBox/>
      </Suspense>
    );
  }, []);

  const userData = useMemo(() => {
    return {
      tenantId: tenantId ?? '',
      tenantDisplayName: currentOrganization?.displayName ?? '',
      upn: username ?? '',
      name: name ?? '',
      imageUrl: userImageUrl,
      rawClaims: user?.rawClaims ?? {},
      homeTenantId: user?.homeTenantId ?? ''
    };
  }, [currentOrganization?.displayName, name, tenantId, userImageUrl, username]);

  const getToken = useCallback(async (baseUrl: string) => {
    return await auth.acquireToken({ scopes: [baseUrl] });
  }, [auth]);
  const configuration: IConfiguration = useMemo(() => {
    return {
      msGraphBaseUrl: msGraphBaseUrl,
      getToken: getToken
    }
  }, [msGraphBaseUrl, getToken]);
  const {
    data: organizations,
    isLoading: organizationsLoading,
    error: errorGettingOrganizations
  } = useGetResourceTenants(configuration, userData, { staleTime: Infinity });

  const queryClient = useQueryClient();
  const shouldRefreshTenantInfo = useRef(false);

  const handleHeaderButtonClick = useCallback((buttonId: string) => {
    // refetch tenant info when opening tenant switching panel and tenant data finished loading
    if (buttonId === FlexPanelCollections.organizations && !organizationsLoading) {
      // don't refetch tenant info when opening the panel for the first time, but do refetch
      // on all subsequent panel openings
      if (!shouldRefreshTenantInfo.current) {
        shouldRefreshTenantInfo.current = true;
      } else {
        queryClient.invalidateQueries(['getTenantsInfo']);
      }
    }
    telemetry.reportCustomEvent(AppsActions.headerButtonClicked, { buttonId });
  }, [telemetry, queryClient, organizationsLoading]);

  const logOut = useCallback(() => {
    // Iterate through local storage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      // Process only when the key is not null
      if (!key) continue;
      dataCacheKeys.forEach(dataKey => {
        // If tenants are still loading, remove all data with relevant keys
        if (organizationsLoading && key.includes(dataKey)) {
          localStorage.removeItem(key);
          return;
        }
        // If organizations have loaded, remove data related to the current users tenants
        organizations?.forEach(org => {
          if (key.includes(dataKey) && key.includes(org.tenantId)) {
            localStorage.removeItem(key);
          }
        });
      });
    }
    telemetry.reportCustomEvent(AppsActions.meControlLogout);
    telemetry.destroy();
    void auth.logout();
  }, [telemetry, auth, organizationsLoading, organizations]);


  if (language === undefined) {
    return null;
  }

  return (
    <Suspense fallback={<LazyLoadingHeader></LazyLoadingHeader>}>
      <SuiteHeader
        appId={clientId}
        appName={<h1>{t('My Apps')}</h1>}
        appNameLabel={t('My Apps')}
        onClickAppBranding={goToHomePage}
        enabledApps={getMenuItems(appsMap, currentOrganization?.hasP2License ?? false, username, tenantId)}
        searchBox={getSearchBoxRenderer}
        organizations={{ value: organizations ?? [], isLoading: organizationsLoading }}
        changeTenant={changeTenant}
        culture={language}
        evoBase={evoBase}
        hasError={!!errorGettingOrganizations}
        helpLink={helpLink}
        imageUrl={bannerLogo}
        isOfficeAppLauncherDisabled={isOfficeAppLauncherDisabled || isGuestUser}
        isUniversalMeControlEnabled={isUniversalMeControlEnabled}
        localizationMap={localizationMap}
        navbarData={officeShellData as NavBarWrapper}
        onRenderComplete={handleHeaderRender}
        onHeaderButtonClicked={handleHeaderButtonClick}
        manageTenantControl={manageOrganizationControl}
        isMsalJsEnabled={true}
        onSignOut={logOut}
        onSwitch={switchAccount}
        onSwitchTo={switchTo}
        theme={undefined}
        userData={userData}
        viewAccountLink={viewAccountLink ?? ''}
        workloadId="AADMyApps"
      />
    </Suspense>
  );
};
