import { useLocalization } from '../atoms/useLocalization';
import { useCallback, useMemo } from 'react';

import { useCollectionsByVisibility, useUpdateCollectionOrder } from '../client-app-collections';

export interface Collection {
  readonly id: string;
}

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: (collection: Collection) => boolean;
  readonly act: (collection: Collection) => void;
}

export function useCollectionActionHide(): Action {
  const [t] = useLocalization();

  const { visible: visibleCollections, hidden: hiddenCollections } = useCollectionsByVisibility('user');
  const reorderCollections = useUpdateCollectionOrder('user', 'hide');

  const canHide = useCallback(({ id }: Collection) => !!visibleCollections.find(x => x.id === id), [visibleCollections]);

  const hideCollection = useCallback((collection: Collection) => {
    if (!canHide(collection)) return;
    reorderCollections(
      visibleCollections.map(x => x.id).filter(x => x !== collection.id),
      [...hiddenCollections.map(x => x.id), collection.id]
    );
  }, [canHide, visibleCollections, hiddenCollections]);

  return useMemo(() => ({
    key: 'hide',
    name: t('hide'),
    icon: '',
    enabled: canHide,
    act: hideCollection
  }), [canHide, hideCollection]);
}
