/* eslint-disable max-len */
import { useAuth } from '@iamexperiences/feature-auth';
import { useLocalization } from '../../atoms/useLocalization';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useAppCollectionsQueryConfig } from '../atoms/AppCollectionsProvider';
import { cacheKeyItem, cacheKeyScopedOrder, collectionLoadingMetadata, CollectionView, useCollectionCacheClient } from '../atoms/useCollectionCacheClient';
import { useLocalUpdateCollections } from '../molecules/useLocalUpdateCollections';
import { OptimisticUpdate } from '../utilities/cache';
import { addDataBoundaryHeaders } from '../../utils/DataBoundaryHeaders';

export interface CollectionViewParams extends Partial<CollectionView> {
  readonly id: string;
  readonly operation?: DragAndDropOperations;
}

export interface DragAndDropCollectionViewParams {
  readonly dropContainer: CollectionViewParams;
  readonly sourceContainer: CollectionViewParams;
}

export type DragAndDropOperations = 'move' | 'copy' | 'reorder';

interface CollectionParams {
  id: string;
  name?: string;
  knownApps?: readonly string[];
  hiddenApps?: readonly string[];
  useDefaultOrder?: boolean;
}

function mapParamsForApiV1(params: CollectionViewParams): CollectionParams {
  const v1Params: CollectionParams = { id: params.id };
  if (params.displayName !== undefined) v1Params.name = params.displayName;
  if (params.explicitItemIds !== undefined) v1Params.knownApps = params.explicitItemIds;
  if (params.hiddenItemIds !== undefined) v1Params.hiddenApps = params.hiddenItemIds;
  if (params.useViewOrder !== undefined) v1Params.useDefaultOrder = !params.useViewOrder;
  return v1Params;
}

export function useUpdateCollectionViews(): (params: Array<Readonly<CollectionViewParams>>) => void {
  const [t] = useLocalization();
  const { serviceRootUrl, acquireToken } = useAppCollectionsQueryConfig();
  const client = useCollectionCacheClient();
  const localUpdateCollections = useLocalUpdateCollections();
  const { auth } = useAuth();

  const { mutate } = useMutation<void, unknown, Array<Readonly<CollectionViewParams>>, OptimisticUpdate>(
    async (params) => {
      const token = await acquireToken();
      const headers = addDataBoundaryHeaders(auth);
      headers.append('Authorization', 'Bearer ' + token);
      headers.append('Content-Type', 'application/json');
      try {
        const promises = params.map(async (param) => {
          const response = await fetch(`${serviceRootUrl.slice(0, serviceRootUrl.length - 7)}/api/users/me/workspaces/${param.id}`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(mapParamsForApiV1(param))
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
        });

        await Promise.all(promises);
      } catch (error: any) {
        return await Promise.reject(error?.message);
      }
    }, {
      onMutate: async (params) => {
        const afterUpdateCollections = params.map((param) => {
          const itemKey = cacheKeyItem('user', param.id);
          const collection = client.getQueryData(itemKey) ?? collectionLoadingMetadata(param.id, param.displayName ?? param.id);
          return {
            ...collection,
            view: {
              displayName: param.displayName ?? collection.view?.displayName,
              useViewOrder: param.useViewOrder ?? collection.view?.useViewOrder ?? false,
              explicitItemIds: param.explicitItemIds ?? collection.view?.explicitItemIds ?? [],
              hiddenItemIds: param.hiddenItemIds ?? collection.view?.hiddenItemIds ?? []
            }
          };
        });

        return await localUpdateCollections('user', afterUpdateCollections);
      },
      onError: async (_error, _id, update) => {
        toast.error(t('genericApiFailure'), { id: 'workspaces-api/error' });
        await update?.rollback();
      },
      onSuccess: async (_data, _id, update) => {
        await update?.complete();
        const operation = _id.pop()?.operation;
        let message: string = t('appAddSuccess');
        let id: string = 'appAddSuccess';
        switch (operation) {
          case 'reorder': {
            message = t('appOrderChangeSuccess');
            id = 'appOrderChangeSuccess';
            break;
          }
          case 'move': {
            message = t('appMoveSuccess');
            id = 'appMoveSuccess';
          }
        }
        toast.success(message, { id });
        // Triggering refresh here in order to avoid race condition with API call updates.
        const key = cacheKeyScopedOrder('user');
        if (key) {
          await client.invalidateQueries(key);
        }
      }
    });

  return mutate;
}
