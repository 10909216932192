export const getFeatureFlagWithoutHooks = (featureFlag: string, defaultValue: boolean) => {
  const urlSearchParams = new URLSearchParams(window.location.search).toString();
  const hasFeatureFlag = urlSearchParams.includes(featureFlag);

  if (hasFeatureFlag) {
    // If value exists in the query parameter, honor it and put into the storage
    const isFeatureEnabledInQuery = urlSearchParams.includes(`feature.${featureFlag}=true`);
    window.localStorage.setItem(featureFlag, isFeatureEnabledInQuery.toString());
    return isFeatureEnabledInQuery;
  } else {
    // Else check the local storage
    const isFeatureEnabledInStorage = window.localStorage.getItem(featureFlag);
    if (isFeatureEnabledInStorage != null) {
      return isFeatureEnabledInStorage === true.toString();
    } else {
      return defaultValue;
    }
  }
};
