import { useCallback, useMemo } from 'react';

import { useLocalization } from '../atoms/useLocalization';
import { useRoute } from './useRoute';

export interface Collection {
  readonly id: string;
  readonly viewPermissions: {
    readonly canAddItems: boolean;
    readonly canRemoveItems: boolean;
    readonly canReorderItems: boolean;
    readonly canRenameList: boolean;
  };
}

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: (collection: Collection) => boolean;
  readonly act: (collection: Collection) => void;
}

export function useCollectionActionEdit(): Action {
  const [t] = useLocalization();
  const { openEditCollection } = useRoute();

  const canEdit = useCallback((collection: Collection) => (
    !!(collection.viewPermissions.canAddItems ||
      collection.viewPermissions.canRemoveItems ||
      collection.viewPermissions.canReorderItems ||
      collection.viewPermissions.canRenameList
    )
  ), []);

  const editCollection = useCallback(({ id }: Collection) => {
    openEditCollection(id);
  }, [openEditCollection]);

  return useMemo(
    () => ({
      key: 'edit',
      name: t('edit'),
      icon: '',
      enabled: canEdit,
      act: editCollection
    }),
    [canEdit, editCollection, t]
  );
}
