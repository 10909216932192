import { useCallback, useState } from 'react';

type Modal = readonly [
  key: string,
  props: Object | undefined,
  result: any
];

type ModalPropsForKey<T extends Modal, TKey extends T[0]> = Extract<T, readonly [TKey, any, any]>[1];
type ModalValueForKey<T extends Modal, TKey extends T[0]> = Extract<T, readonly [TKey, any, any]>[2];

type StackFrame<T extends Modal> = readonly [
  key: T[0],
  props: T[1],
  resolve: (result: T[2]) => void,
  reject: () => void
];

interface ViewCollectionModals<T extends Modal> {
  readonly activeModal: T[0];
  readonly getPropsForModal: <TKey extends T[0]>(key: TKey) => ModalPropsForKey<T, TKey> | undefined;
  readonly pushModal: <TKey extends T[0]>(modal: TKey, props: ModalPropsForKey<T, TKey>)
  => Promise<ModalValueForKey<T, TKey>>;
  readonly popModal: <TKey extends T[0]>(completed?: boolean, result?: ModalValueForKey<T, TKey>) => void;
}

export function useViewCollectionModals<T extends Modal>(): ViewCollectionModals<T> {
  const [modals, setModals] = useState<ReadonlyArray<StackFrame<T>>>([]);

  const popModal = useCallback((completed?: boolean, result?: Modal[2]) => {
    setModals((modals) => {
      const frame = modals.at(-1);
      if (!frame) return modals;
      // Resolve or reject the promise
      if (completed) {
        frame[2](result);
      } else {
        frame[3]();
      }
      // Pop the modal off the stack
      return modals.slice(0, -1);
    });
  }, [setModals]);

  const pushModal = useCallback(async <TKey extends T[0]>
  (key: TKey, props: ModalPropsForKey<T, TKey>): Promise<ModalValueForKey<T, TKey>> => {
    const promise = new Promise((resolve, reject) => {
      setModals((modals) => [...modals, [key, props, resolve, reject]]);
    });
    promise.catch(() => {}); // Prevent uncaught promise error when the modal is cancelled.
    return await promise;
  }, []);

  const getPropsForModal = useCallback((key: T[0]) => {
    for (let i = 0, n = modals.length; i < n; i++) {
      const modal = modals[n - 1 - i];
      if (modal[0] === key) {
        return modal[1];
      }
    }
    return undefined;
  }, [modals]);

  return {
    activeModal: modals.at(-1)?.[0] ?? 'none',
    getPropsForModal,
    popModal,
    pushModal
  };
}
