import { useLocalization } from '../../atoms/useLocalization';
import { useCallback, useMemo, useState } from 'react';

import { DeleteBookmarkProps } from './DeleteBookmark';

export interface App {
  readonly scopedId: string;
  readonly provider: string;
}

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: ([app, collection]: readonly [App, unknown]) => boolean;
  readonly act: ([app, collection]: readonly [App, unknown]) => void;
}

export function useBookmarkActionDelete(): [
  action: Action,
  updateBookmarkProps: DeleteBookmarkProps
] {
  const [t] = useLocalization();

  const [bookmarkId, setBookmarkId] = useState<string>('');
  const [modalHidden, setModalHidden] = useState(true);
  const hideModal = useCallback(() => setModalHidden(true), []);

  return useMemo(() => ([{
    key: 'deleteAppFromCollection',
    name: t('Delete'),
    icon: 'Delete',
    enabled: ([app]) => app.provider === 'CustomUrl',
    act: ([app]) => {
      setBookmarkId(app.scopedId);
      setModalHidden(false);
    }
  }, {
    bookmarkId,
    hidden: modalHidden,
    onHide: hideModal
  }]), [bookmarkId, modalHidden, hideModal]);
}
