import { useQuery } from 'react-query';

import { useHeaderDataQueryConfig } from '../atoms/HeaderDataProvider';

export interface IOrganizationBranding {
  readonly id: string;
  readonly cdnList: string[];
  readonly bannerLogoRelativeUrl: string;
  readonly backgroundColor: string;
  readonly backgroundImageRelativeUrl: string;
}

export function useGetUserImage(userId: string): {data: string | undefined; isLoading: boolean; isError: boolean} {
  const { acquireMSGraphToken, msGraphBaseUrl, reportApiSuccess, reportApiFailure } = useHeaderDataQueryConfig();
  const queryKey = ['userImage', userId];

  const { data, isLoading, isError } = useQuery({
    queryKey,
    cacheTime: 1000 * 60 * 60 * 24, // 24 hrs
    staleTime: 1000 * 60 * 60, // 1 hr
    queryFn: async () => {
      const token = await acquireMSGraphToken();
      return await fetch(`${msGraphBaseUrl}/me/photo/$value`, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(async response => {
          if (response.status === 404) {
            return undefined;
          } else {
            return await response.json();
          }
        })
        .then((image) => image ? URL.createObjectURL(image.data) : undefined)
        .catch((error) => {
          reportApiFailure?.({ action: 'header/network-call/failure/get-user-image', message: error });
          throw error;
        });
    },
    onSuccess: () => {
      reportApiSuccess?.({ action: 'header/network-call/success/get-user-image' });
    }
  });

  return { data, isLoading, isError };
}
