import { useCallback } from 'react';
import { useLocalization } from '../atoms/useLocalization';
import { OpenDialogFunction } from './useConfirmationDialog';
import { useDeleteCollection } from '../client-app-collections';

export interface Collection {
  readonly id: string;
  readonly displayName: string;
}

/** Returns a function that will delete the given collection after the user confirms in a dialog. */
export function useConfirmDeleteCollectionDialog(openDialog: OpenDialogFunction): (collection: Collection) => void {
  const [t] = useLocalization();
  const deleteCollection = useDeleteCollection();
  return useCallback((collection: Collection) => {
    openDialog({
      action: "deleteCollection",
      title: t("deleteCollectionWarning", { collectionName: collection.displayName ?? collection.id }),
      body: t("areYouSure"),
      confirm: t("Delete"),
      deny: t("Cancel")
    }, () => deleteCollection(collection.id));
  }, [openDialog]);
}
