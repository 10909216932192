import { useCallback } from 'react';
import { applyViewToDisplayName, cacheKeyItem, cacheKeyScopedLibrary, cacheKeyScopedOrder, CollectionResponse, Scope, useCollectionCacheClient } from '../atoms/useCollectionCacheClient';
import { optimisticReplaceItemsInList, optimisticReplaceValue, OptimisticUpdate } from '../utilities/cache';

/**
 * Updates all caches containing a collection, and triggers a background refresh of the collection and all containing lists.
 * Does NOT execute any API operation to update the collection on the backend.
 */
export function useLocalUpdateCollection(): (scope: Scope, collection: CollectionResponse) => Promise<OptimisticUpdate> {
  const client = useCollectionCacheClient();

  return useCallback(async (scope, collection) => {
    const updates = [
      await optimisticReplaceValue(client, cacheKeyItem(scope, collection.id), collection),
      await optimisticReplaceItemsInList(client, cacheKeyScopedOrder(scope), x => x.id === collection.id ? collection : x),
      // Update name if it has changed
      await optimisticReplaceItemsInList(client, cacheKeyScopedLibrary(scope), x => {
        if (x.id === collection.id) {
          const displayName = applyViewToDisplayName(collection.template, collection.view, collection.viewPermissions);
          if (x.displayName !== displayName) {
            return {
              ...x,
              displayName
            };
          }
        }
        return x;
      }),
    ];

    return {
      complete: async () => {
        await Promise.all(updates.map(x => x.complete()));
      },
      rollback: async () => {
        await Promise.all(updates.map(x => x.rollback()));
      }
    }
  }, [client]);
}
