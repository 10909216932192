export enum CollectionsTelemetryEvent {
  UserTogglesAppsLayout = 'collections/business-event/toggle-apps-layout',
  UrlParamTogglesAppsLayout = 'collections/business-event/url-toggle-apps-layout',
  AppsLayout = 'collections/business-event/apps-layout',
  UserTogglesViewMode = 'collections/business-event/toggle-view-mode',
  UrlParamTogglesViewMode = 'collections/business-event/url-toggle-view-mode',
  ViewMode = 'collections/business-event/view-mode',
  AddAppToCollectionWithDragAndDrop = 'collections/business-event/add-app-with-drag-and-drop',
  RemoveAppFromCollectionWithDragAndDrop = 'collections/business-event/remove-app-with-drag-and-drop',
  ReorderCollectionWithDragAndDrop = 'collections/business-event/reorder-with-drag-and-drop',
  CopyFromCollectionWithDragAndDrop = 'collections/business-event/copy-with-drag-and-drop',
  StartDragAndDrop = 'collections/business-event/start-drag-and-drop',
  EndDragAndDrop = 'collections/business-event/end-drag-and-drop',
  DragAndDropWithNoUpdate = 'collections/business-event/drag-and-drop-no-update',
  DragAndDropError = 'collections/business-event/drag-and-drop-error',
  AddAppsFromEmptyCollection = 'collections/business-events/add-apps-from-empty-collection',
  DeleteFromEmptyCollectionUX = 'collections/business-events/delete-from-empty-collection-ux',
  CollapsedCollectionState = 'collections/business-events/collapsed-collection-state',
  CollapsedCollection = 'collections/business-events/collapsed-collection',
  ExpandCollection = 'collections/business-events/expand-collection'
}
