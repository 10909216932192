import { useMemo } from 'react';
import { useSearchTileLibraryQuery } from '../atoms/useSearchTileLibraryQuery';
import { Tile } from '../atoms/useTileCacheClient';
import { useTileLibraryQuery } from '../atoms/useTileLibraryQuery';

export interface TilesResult {
  readonly isLoading: boolean;
  readonly isFetching: boolean;
  readonly tiles: readonly Tile[];
  readonly error: string | null;
}

/** Looks up all of the current user's assigned app tiles. */
export function useUserTileLibrary(searchTerm?: string): TilesResult {
  const result = searchTerm
    ? useSearchTileLibraryQuery('user', searchTerm) : useTileLibraryQuery('user');

  return useMemo(() => ({
    tiles: result.data ?? [],
    error: result.error,
    isLoading: result.isLoading,
    isFetching: result.isFetching
  }), [result]);
}
