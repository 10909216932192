import { AuthPrompt, useAuth } from '@iamexperiences/feature-auth';
import { useLocalization } from '../Apps/atoms/useLocalization';
import React, { useCallback } from 'react';

import { LocalizedFullSizeError, LocalizedFullSizeErrorProps } from '../shared/LocalizedFullSizeError';

interface IProps {
  readonly message: string;
}

export const AppsRedirectError: React.FC<IProps> = ({ message }) => {
  const [t] = useLocalization();
  const { auth } = useAuth();
  const actionCallback = useCallback(() => {
    auth.login({ prompt: AuthPrompt.LOGIN });
  }, [auth]);

  const props: LocalizedFullSizeErrorProps = {
    errorTitle: t('redirectError'),
    subtitle: t('noAvailableAADTenant'),
    actionButtonProps: {
      actionLabel: t('Sign out'),
      onActionPressed: actionCallback
    },
    message
  };
  return (
    <LocalizedFullSizeError {...props} />
  );
};
