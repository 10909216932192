import { ITelemetry } from '@iamexperiences/ecos-telemetry';

import { applyView, Collection, CollectionLoadingItems, CollectionLoadingTiles, CollectionWithTilesLoadError, FullyLoadedCollection, Scope } from '../atoms/useCollectionCacheClient';
import { Tile } from '../atoms/useTileCacheClient';
import { isSmartCollection } from './applySmartCollectionLimits';
import { isNotRestrictedSmartCollection } from './isNotRestrictedSmartCollection';

function populateCompleteCollectionItems(collection: CollectionLoadingItems | CollectionLoadingTiles | CollectionWithTilesLoadError | FullyLoadedCollection, allTiles: readonly Tile[]): Collection {
  if (collection.template?.isComplete) {
    return applyView({
      ...collection,
      template: {
        ...collection.template,
        itemIds: allTiles.map(x => x.id)
      },
      loadingState: 'apply-view'
    });
  }
  return collection;
}

export interface PopulateTilesCallbacks {
  /**
   * Reads cached tiles to populate cached collection order.
   * @returns a promise that resolves when all caches have been updated.
   */
  readonly refreshTiles: (scope: Scope) => Promise<void>;

  /**
   * Reads given tiles to populate cached collections.
   * @returns a promise that resolves when all caches have been updated.
   */
  readonly populateTilesInCachedCollections: (scope: Scope, tiles: readonly Tile[]) => Promise<void>;

  /**
   * Reads cached tiles to populate given collections.
   * @returns the updated collections.
   */
  readonly populateCachedTilesInCollections: (scope: Scope, collections: readonly Collection[]) => readonly Collection[];

  /**
   * Reads given tiles to populate given collections.
   * @returns the updated collections.
   */
  readonly populateTilesInCollections: (tiles: readonly Tile[], collections: readonly Collection[]) => readonly Collection[];
}

// 1. Determine tileIds for "complete" collections (and apply view)
// 2. Fill in tiles

/**
 * @returns functions that update collections with a set of available tiles.
 * Any collection items not in the tile set are hidden.
 */
export function populateTiles(tiles: readonly Tile[], collections: readonly Collection[], telemetry: ITelemetry): readonly Collection[] {
  // Create convenient tile lookup
  const tilesById = tiles.reduce((v, x) => ({ ...v, [x.id]: x }), {});
  const pruneRestrictedSmartCollections = isNotRestrictedSmartCollection(tiles.length);

  // Populate tiles in collection order
  return collections.filter(pruneRestrictedSmartCollections).map<Collection>(collection => {
    // For complete collections, determine the list of items
    if (collection.loadingState != 'error' && collection.template?.isComplete) {
      collection = populateCompleteCollectionItems(collection, tiles);
    }

    // Update tiles
    if (collection.loadingState === 'loaded') {
      collection = {
        ...collection,
        loadingState: 'tiles',
        itemIds: collection.items.map(x => x.id)
      };
    }

    // Fill in tiles
    if (collection.loadingState === 'tiles') {
      const items = collection.itemIds.map(id => tilesById[id]).filter(x => !!x);
      if (isSmartCollection(collection.id)) {
        telemetry.reportCustomEvent('smart-collections/data/app-insights',
          { collectionName: collection.id, appsReturned: collection.itemIds.length, appsValidForUser: items.length });
      }
      return {
        ...collection,
        loadingState: 'loaded',
        items,
        itemCount: items.length
      };
    } else {
      return collection;
    }
  });
}
