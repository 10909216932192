import { useQueryClient } from 'react-query';
import { CacheClient } from '../utilities/cache';

/** Data for a launchable app tile. */
export interface Tile {
  /** Unique identifier for the tile. */
  readonly id: string;
  /** The source of the app tile. */
  readonly provider: string;
  /** The provider's id for the tile. */
  readonly scopedId: string;
  /** Display name of the tile. */
  readonly displayName: string;
  /** Display description of the tile. */
  readonly description?: string;
  /** Location of the logo to display on the tile. */
  readonly logoUrl: string;
  /** Location to bookmark for launching this tile later. */
  readonly persistentLaunchUrl: string;
  /** Location to launch the url within the current session. */
  readonly fastLaunchUrl: string;
}

export type Scope = 'user' | 'tenant';

/** This key applies to all caches of apps from the library. */
export const cacheKeyTile = ['app'] as const;
/** This key applies to all caches of lists of apps from the library. */
export const cacheKeyTileLibrary = ['app', 'library'] as const;
/** This key applies to a library cache scoped to a user or tenant. */
export const cacheKeyScopedTileLibrary = (scope: Scope): CacheKeyScopedTileLibrary => ['app', 'library', scope];
export const cacheKeyScopedSearchTileLibrary = (scope: Scope, searchTerm: string): CacheKeyScopedSearchTileLibrary =>
  ['app', 'library', 'search', scope, searchTerm];

export type CacheKeyTile = typeof cacheKeyTile;
export type CacheKeyTileLibrary = typeof cacheKeyTileLibrary;
export type CacheKeyScopedTileLibrary = readonly ['app', 'library', Scope];
export type CacheKeyScopedSearchTileLibrary = readonly ['app', 'library', 'search', Scope, string];
export type CacheKey = CacheKeyTile | CacheKeyTileLibrary | CacheKeyScopedTileLibrary;

export type Cache =
  [CacheKeyTile, void] |
  [CacheKeyTileLibrary, void] |
  [CacheKeyScopedTileLibrary, readonly Tile[]];

export function useTileCacheClient(): CacheClient<Cache> {
  return useQueryClient();
}
