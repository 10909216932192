import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useCallback } from 'react';

import { CollectionsTelemetryEvent } from '../../shared/Telemetry/CollectionsTelemetryEvent';
import { useCollectionModals } from '../atoms/CollectionModalsProvider';
import { Collection, useUpdateCollectionView } from '../client-app-collections';
import { OpenDialogFunction } from '../molecules/useConfirmationDialog';
import { useConfirmDeleteCollectionDialog } from '../molecules/useConfirmDeleteCollectionDialog';

interface EmptyCollectionActions {
  readonly onDeleteCollectionClicked: (collection: Collection) => void;
  readonly onAddAppsClicked: (collection: Collection) => void;
  readonly onItemDraggedOver: (collection: Collection) => boolean;
}

export function useEmptyCollectionActions(openConfirmationDialog: OpenDialogFunction): EmptyCollectionActions {
  const telemetry = useTelemetry();
  const deleteCollection = useConfirmDeleteCollectionDialog(openConfirmationDialog);
  const { pushModal } = useCollectionModals();
  const updateCollection = useUpdateCollectionView();

  const onDeleteCollectionClicked = useCallback((collection: Collection) => {
    telemetry.reportCustomEvent(CollectionsTelemetryEvent.DeleteFromEmptyCollectionUX);
    deleteCollection(collection);
  }, [deleteCollection, telemetry]);

  const onItemDraggedOver = useCallback((collection: Collection) => {
    return collection.viewPermissions.canAddItems;
  }, []);

  const onAddAppsClicked = useCallback((collection: Collection) => {
    telemetry.reportCustomEvent(CollectionsTelemetryEvent.AddAppsFromEmptyCollection);
    void pushModal('addApps', { initiallySelectedItems: [] }).then(apps => {
      updateCollection({ id: collection.id, explicitItemIds: apps.map(x => x.id) });
    }).catch(e => e);
  }, [pushModal, telemetry, updateCollection]);

  return {
    onAddAppsClicked,
    onDeleteCollectionClicked,
    onItemDraggedOver
  };
}
