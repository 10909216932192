import { QosProvider } from '@iamexperiences/ecos-telemetry';
import React, { createContext, ReactElement, ReactNode, useContext, useState } from 'react';

interface Page {
  readonly name: string;
  readonly props?: any;
}

type PageContext<T extends Page> = [
  activePage: T,
  setActivePage: (page: T) => void
];

interface PageProviderProps<T extends Page> {
  readonly initialPage: T;
  readonly children?: ReactNode;
}

const Context = createContext<PageContext<any> | null>(null);

export function usePage<T extends Page>(): PageContext<T> {
  const context = useContext(Context);
  if (!context) {
    throw new Error('No ancestor PageProvider found; usePage must be called within an PageProvider context.');
  }

  return context;
}

export function PageProvider<T extends Page>({
  initialPage,
  children
}: PageProviderProps<T>): ReactElement {
  const context = useState(initialPage);
  return (
    <Context.Provider value={context}>
      <QosProvider name={context[0].name}>
        {children}
      </QosProvider>
    </Context.Provider>
  );
}
