import { useAuth } from '@iamexperiences/feature-auth';
import { useLocalization } from '../../atoms/useLocalization';
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import { addDataBoundaryHeaders } from '../../utils/DataBoundaryHeaders';
import { useAppCollectionsQueryConfig } from '../atoms/AppCollectionsProvider';
import { useCollectionCacheConfig } from '../atoms/CollectionCacheProvider';
import { cacheKeyItem, cacheKeyScopedOrder, collectionLoadingMetadata, CollectionResponse, Scope, useCollectionCacheClient } from '../atoms/useCollectionCacheClient';

interface WorkspaceOrderBody {
  readonly order: readonly string[];
  readonly hidden?: readonly string[];
}

export type collectionOrderOperations = 'reorder' | 'hide';

interface Context {
  readonly order: readonly CollectionResponse[] | undefined;
}

export function useUpdateCollectionOrder(scope: Scope, operation: collectionOrderOperations): (visibleCollectionIds: readonly string[], hiddenCollectionIds?: readonly string[]) => void {
  const [t] = useLocalization();
  const { acquireToken, serviceRootUrl, userId } = useAppCollectionsQueryConfig();
  const { getPersistentCache } = useCollectionCacheConfig();
  const collectionCache = getPersistentCache?.(cacheKeyScopedOrder(scope))?.[0];
  const client = useCollectionCacheClient();
  const { auth } = useAuth();

  const { mutate } = useMutation<void, unknown, WorkspaceOrderBody, Context>(async (body: WorkspaceOrderBody): Promise<void> => {
    const id = scope === 'user' ? userId : 'tenantOrder';
    if (!id) return await Promise.reject();
    const token = await acquireToken();
    const headers = addDataBoundaryHeaders(auth);
    headers.append('Authorization', 'Bearer ' + token);
    headers.append('Content-Type', 'application/json');

    const response = await fetch(`${serviceRootUrl.slice(0, serviceRootUrl.length - 7)}/api/workspaceOrder/${id}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(body)
    });
    if (!response.ok) {
      return await Promise.reject(response.statusText);
    }
  }, {
    onMutate: async body => {
      // Optimistically update order cache
      // item caches should be unaffected
      // library caches should be unaffected
      const orderKey = cacheKeyScopedOrder(scope);
      await client.cancelQueries(orderKey);
      const order = client.getQueryData(orderKey);
      client.setQueryData(orderKey, () => {
        return body.order.map(x => client.getQueryData(cacheKeyItem(scope, x)) ?? collectionCache?.find(y => y.id === x) ?? collectionLoadingMetadata(x, x));
      });
      return { order };
    },
    onError: (_error, _body, context) => {
      switch (operation) {
        case 'reorder':
          toast.error(t('genericApiFailure'), { id: 'workspaceOrder-api/error' });
          break;
        case 'hide':
          toast.error(t('hideCollectionFailure'), { id: 'workspaceOrder-api/hide/error' });
      }
      if (context?.order) {
        client.setQueryData(cacheKeyScopedOrder(scope), context.order);
      }
    },
    onSettled: async () => {
      await client.invalidateQueries(cacheKeyScopedOrder('user'));
    },
    onSuccess: async () => {
      switch (operation) {
        case 'reorder':
          toast.success(t('collectionOrderChangeSuccess'), { id: 'collectionOrderChangeSuccess' });
          break;
        case 'hide':
          toast.success(t('collectionHiddenSuccess'), { id: 'collectionHiddenSuccess' });
      }
    }
  });

  return useCallback((visibleCollectionIds: readonly string[], hiddenCollectionIds?: readonly string[]) => {
    mutate(hiddenCollectionIds
      ? {
          order: visibleCollectionIds,
          hidden: hiddenCollectionIds
        }
      : {
          order: visibleCollectionIds
        });
  }, [mutate]);
}
