import { setRTL } from '@fluentui/utilities';
import i18n, { Module } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

import {
  CustomLanguageDetector,
  htmlTagDetector,
  IDetectorOptions,
  navigatorDetector,
  profileLanguageDetector,
  querystringDetector
} from './Apps/molecules/LanguageDetector';

const languageDetectorOptions: IDetectorOptions = {
  // We're excluding "cookie" and "localStorage" from the language detection, since
  // we don't offer any way for users to change their language in My*. Instead, we will
  // match user's browser languages
  order: ['querystring', 'profileLanguage', 'navigator', 'htmlTag'],
  lookupQueryString: 'lng'
};

const languageDetector = CustomLanguageDetector.create();
languageDetector.addDetector([querystringDetector, profileLanguageDetector, navigatorDetector, htmlTagDetector]);

i18n
  .use(Backend)
  .use(languageDetector as Module)
  .use(initReactI18next) // This replaces reactI18nextModule
  .init({
    fallbackLng: 'en-US',
    ns: ['Apps'],
    defaultNS: 'Apps',
    debug: process.env.NODE_ENV === 'development',
    load: 'all',
    saveMissing: false,
    // The key is . (period) by default but we are currently using the key as fallback
    // to simplify development
    // https://www.i18next.com/principles/fallback#key-fallback
    // `keySeparator` allows for grouping of keys
    // Example:
    //    t('button.save')
    // results in the following in the json file:
    // "button": { "save": "xxxxxx" }
    keySeparator: false,
    // Similarly, namespace separator (ns) is : by default and allows for a specific ns to be used
    //    t('example-ns:button.save')
    nsSeparator: false,
    interpolation: {
      escapeValue: false // Not needed for react
    },
    react: {
      useSuspense: true
    },
    detection: languageDetectorOptions
  });

i18n.on('languageChanged', (lng: string) => {
  setLangAndDir(lng);
});

i18n.on('failedLoading', (lng: string) => {
  lng = 'en-US';
  setLangAndDir(lng);
});

export default i18n;
function setLangAndDir(lng: string) {
  let dir = i18n.dir();
  if (process.env.NODE_ENV === 'development' && lng === 'qps-PLOC') {
    // If pseudo loc then use rtl
    dir = 'rtl';
  }
  setRTL(dir === 'rtl');
  document.documentElement.lang = lng;
}
