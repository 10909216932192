import { Bookmark } from '@iamexperiences/feature-app-bookmarks';
import { useLocalization } from '../../atoms/useLocalization';
import { useCallback, useMemo, useState } from 'react';

import { UpdateBookmarkProps } from './UpdateBookmark';

export interface App {
  readonly scopedId: string;
  readonly provider: string;
  readonly displayName: string;
  readonly persistentLaunchUrl: string;
}

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: ([app, collection]: readonly [App, unknown]) => boolean;
  readonly act: ([app, collection]: readonly [App, unknown]) => void;
}

function appAsBookmark(app: App): Bookmark {
  return {
    id: app.scopedId,
    displayName: app.displayName,
    url: app.persistentLaunchUrl
  };
}

const emptyBookmark = {
  id: '',
  displayName: '',
  url: ''
} as const;

export function useBookmarkActionUpdate(): [
  /* action: */ Action,
  /* updateBookmarkProps: */ UpdateBookmarkProps
] {
  const [t] = useLocalization();

  const [bookmark, setBookmark] = useState<Bookmark>(emptyBookmark);
  const [modalHidden, setModalHidden] = useState(true);
  const hideModal = useCallback(() => setModalHidden(true), []);

  return useMemo(() => ([{
    key: 'manageApp',
    name: t('bookmarkEditModalTitle'),
    icon: 'Settings',
    enabled: ([app]) => app.provider === 'CustomUrl',
    act: ([app]) => {
      setBookmark(appAsBookmark(app));
      setModalHidden(false);
    }
  }, {
    bookmark,
    hidden: modalHidden,
    onHide: hideModal
  }]), [bookmark, modalHidden, hideModal]);
}
