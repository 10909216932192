import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo, useReducer } from 'react';

export interface TypedStorage<Key extends string = string, Value = unknown> {
  /** Looks up a value from storage. */
  readonly getItem: (key: Key | null) => Value | null;
  /** Updates a value from storage. */
  readonly setItem: (key: Key | null, value: Value) => void;
  /** Removes a value from storage. */
  readonly removeItem: (key: Key | null) => void;
}

export interface TypedSingletonStorage<Value = unknown> {
  /** Looks up the singleton value from storage. */
  readonly getItem: () => Value | null;
  /** Updates the singleton value from storage. */
  readonly setItem: (value: Value) => void;
  /** Removes the singleton value from storage. */
  readonly removeItem: () => void;
}

// Exported for testing
export const Context = createContext<TypedStorage<string, any> | null>(null);

export function useStorage<Key extends string = string, Value extends string = string>(): TypedStorage<Key, Value> {
  const config = useContext(Context);

  if (config == null) {
    throw Error('Storage configuration missing. Please nest this component within a <StorageProvider>');
  }

  // React will trigger a re-render after invoking setItem or removeItem.
  // Re-rendering logic is here so only the component using this hook and
  // its children are re-rendered.
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const setItem = useCallback((key: Key, value: Value) => {
    config.setItem(key, value);
    forceUpdate();
  }, [config]);

  const removeItem = useCallback((key: Key) => {
    config.removeItem(key);
    forceUpdate();
  }, [config]);

  return useMemo(() => ({
    getItem: config.getItem,
    setItem,
    removeItem
  }), [config, setItem, removeItem]) as TypedStorage<Key, Value>;
}

export interface StorageProviderProps {
  readonly storage: Storage;
  readonly children?: ReactNode;
}

export function StorageProvider({
  storage, children
}: StorageProviderProps): ReactElement {
  const unmonitoredCache = useMemo(() => ({}), []);

  const getItem = useCallback((key: string | null) => {
    if (!key) { return null; }
    // First try the unmonitored cache
    let value = unmonitoredCache[key];
    // If the value is still missing, try storage lookup
    if (value === undefined) {
      try {
        value = storage.getItem(key);
      } catch {
        value = null;
      }
      // This just caches the value, it doesn't change the value seen by callers;
      // so we don't need to invalidate the react tree here.
      unmonitoredCache[key] = value;
    }
    return value;
  }, [unmonitoredCache]);

  const setItem = useCallback((key: string | null, value: string) => {
    if (!key) { return; }
    try {
      storage.setItem(key, value);
    } catch {}
    unmonitoredCache[key] = value;
  }, [unmonitoredCache]);

  const removeItem = useCallback((key: string | null) => {
    if (!key) { return; }
    try {
      storage.removeItem(key);
    } catch {}
    unmonitoredCache[key] = null;
  }, [unmonitoredCache]);

  const state: TypedStorage<string, any> = useMemo(() => ({
    getItem, setItem, removeItem
  }), [getItem, setItem, removeItem]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
