import { CollectionModalProvider } from '@iamexperiences/feature-app-collections';
import React, { lazy, ReactElement, Suspense, useCallback, useState } from 'react';

import { useLocalization } from '../atoms/useLocalization';

const CreateCollection = lazy(async () =>
  await import(/* webpackChunkName: "modals" */ '../molecules/CollectionModals/CreateCollection'));

const AddApps = lazy(async () =>
  await import(/* webpackChunkName: "modals" */ '../molecules/CollectionModals/AddApps'));

const EditCollection = lazy(async () =>
  await import(/* webpackChunkName: "modals" */ '../molecules/CollectionModals/EditCollection'));

const ManageCollection = lazy(async () =>
  await import(/* webpackChunkName: "modals" */ '../molecules/CollectionModals/ManageCollection'));

const CreateBookmark = lazy(async () =>
  await import(/* webpackChunkName: "bookmarks" */ './Bookmarks/CreateBookmark'));

interface CollectionModalsProps {
  readonly settingsBtns?: Record<string, React.RefObject<any>>;
}

export function CollectionModals({settingsBtns}: CollectionModalsProps): ReactElement {
  const [t] = useLocalization();
  const [addItemsSubmitBtnLabel, setAddItemsSubmitBtnLabel] = useState<string>(t('add'));
  const getSelectedItemsCount = useCallback((selectedItemsCount: number) => {
    setAddItemsSubmitBtnLabel(selectedItemsCount === 0
      ? t('add')
      : t('addItemsSubmitBtnLabel', { selectedItemsCount }));
  }, [t]);

  return (
    <>
      <Suspense fallback={<div></div>}>
        <CollectionModalProvider
          strings={{
            createButtonLabel: t('Create'),
            cancelButtonLabel: t('Cancel'),
            closeButtonLabel: t('Close'),
            createCollectionTitle: t('createCollectionTitle'),
            collectionNameLabel: t('Name'),
            collectionTextFieldPlaceholder: t('collectionTextFieldPlaceholder'),
            collectionItemListSubheading: t('collectionItemListSubheading'),
            noItemsToDisplayMessage: t('noAppsToDisplayMessage'),
            addAppsButtonText: t('addApps'),
            loadingSpinnerTitle: t('Loading…'),
            addItemsSubmitButtonLabel: addItemsSubmitBtnLabel,
            addItemsTitle: t('addItemsTitle'),
            addItemsSearchBarPlaceholder: t('search'),
            addItemsAddSiteButtonText: t('addSite'),
            applyChangesButtonLabel: t('applyChangesButtonLabel'),
            editMenuTitle: t('edit'),
            hideMenuTitle: t('hide'),
            deleteMenuTitle: t('Delete'),
            newCollectionButtonLabel: t('newCollectionButtonLabel'),
            manageCollectionsTitle: t('manageAll'),
            editButtonLabel: t('save'),
            editCollectionTitle: t('editCollectionTitle'),
            addItemsSelectAllCheckboxTitle: t('addItemsSelectAllCheckboxTitle'),
            moreMenuTitle: (collectionName: string) => { return t('moreMenuTitle', { collectionName }); },
            noCollectionsToDisplayMessage: t('noCollectionsToDisplayMessage'),
            showMenuTitle: t('show'),
            getAriaLabelForList: (name: string) => { return t('listAriaLabel', { name }); },
            itemsCountMessage: (itemsCount: number) => { return t('itemsCountMessage', { itemsCount }); },
          }}

          addItemsUtilities={{
            getSelectedItemsCount
          }}

          screenReaderMessages={{
            grabbingItem: (startIndex: number, startContainer: string) => {
              return t('grabbingItem', { startIndex, startContainer });
            },
            droppingItem: (endIndex: number, endContainer: string) => {
              return t('droppingItem', { endIndex, endContainer });
            },
            movingItem: (startIndex: number, endIndex: number) => {
              return t('movingItem', { startIndex, endIndex });
            },
            startDragInstructions: () => { return t('startDragInstructions'); }
          }}
          >
          <CreateCollection />
          <AddApps />
          <EditCollection settingsBtns={settingsBtns}/>
          <ManageCollection />
          <CreateBookmark />
        </CollectionModalProvider>
      </Suspense>
    </>
  );
}
