import { useQos, useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useCollectionsHeaders } from '@iamexperiences/feature-app-launching';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { CollectionsTelemetryEvent } from '../../shared/Telemetry/CollectionsTelemetryEvent';
import { usePageHeader } from '../atoms/PageHeaderProvider';
import { useCollectionViewState } from '../atoms/useCollectionViewState';
import { useFeatureOptInState } from '../atoms/useFeature';
import { useLocalization } from '../atoms/useLocalization';
import { useToggleViewMode } from '../atoms/useToggleViewMode';
import { useUrlParams } from '../atoms/useUrlParams';
import { useUserCollectionOrder } from '../client-app-collections';
import { useInformationDialog } from '../molecules/useInformationDialog';
import { useBookmarkActionCreate } from '../organisms/Bookmarks/useBookmarkActionCreate';
import { useGlobalActions } from '../organisms/useGlobalActions';
import { VerticalViewContent } from '../organisms/VerticalViewContent';

// TODO(@sabareeshn):
// function initializeSmartCollectionQuery(){
//   const [activePage] = usePage();
//   const enableQueries =  (activePage === "vertical");

//   useCollectionQuery("user", KnownCollectionIds.popular, enableQueries);
//   useCollectionQuery("user", KnownCollectionIds.recent, enableQueries);
// }

export function LaunchPage(): ReactElement {
  const [t] = useLocalization();

  // TODO(@sabareeshn):
  //  This caused a sev2
  //  because it forces smart collections to be loaded
  //  even in tenants where the pipeline is not running
  //  so the API returns a 502
  //  and our monitors cannot exclude particular endpoints.
  //  Enable this (or find another workaround) only after smart collections are running everywhere
  // Workaround: Initialize the useQuery for smart collection ids to handle switching from legacy to preview.
  // TODO: Possibly remove after we upgrade react-query to latest.
  // initializeSmartCollectionQuery();

  const { collections, isLoading } = useUserCollectionOrder();
  const { reportLoadComplete } = useQos('Collections List', { isCritical: true });
  const [createBookmark] = useBookmarkActionCreate();
  const [, informationDialogProps] = useInformationDialog();
  const { collapsedCollections, selectedCollection, addCollapsedCollectionId, removeCollapsedCollectionId, setSelectedCollectionCache } = useCollectionViewState();
  const { viewMode, setViewMode } = useToggleViewMode(true);
  const urlParams = useUrlParams();
  const telemetry = useTelemetry();
  const [verticalState, setVerticalOptInState] = useFeatureOptInState('vertical');
  const [selectedCollectionId, setSelectedCollectionId] = useState(collections.length > 0 ? selectedCollection ?? collections[0].id : '');
  const globalActions = useGlobalActions(createBookmark);

  useEffect(() => {
    if (!isLoading) {
      reportLoadComplete();
    }
  }, [isLoading, reportLoadComplete]);

  useEffect(() => {
    if (urlParams.mode === 'Focus') {
      telemetry.reportCustomEvent(CollectionsTelemetryEvent.UrlParamTogglesViewMode);
      setViewMode('Focus');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telemetry, urlParams.mode]);

  // TODO(@rosscefalu): remove once no users have the optOut flag
  useEffect(() => {
    if (verticalState === 'OptOut') {
      setViewMode('Focus');
      setVerticalOptInState('OptIn');
    }
  }, [setVerticalOptInState, setViewMode, verticalState, viewMode]);

  const onSelect = useCallback((id: string) => {
    setSelectedCollectionId(id);
    setSelectedCollectionCache(id);
  }, [setSelectedCollectionCache, setSelectedCollectionId]);

  const collectionsHeaders = useCollectionsHeaders({
    strings: {
      appTitle: t('appTitle'),
      loadingLabel: t('loading'),
      carouselSpinLeftButtonLabel: t('previous'),
      carouselSpinRightButtonLabel: t('next')
    },
    onSelect,
    scroll: viewMode === 'Continuous',
    collections,
    globalActions
  });
  // TODO: this is unsafe; React will complain if the count changes between renders.
  // The lib export should be refactored to make this safer.
  collectionsHeaders.forEach(x => usePageHeader(x));

  const verticalViewProps = useMemo(() => ({
    collections,
    informationDialogProps,
    viewMode: verticalState === 'OptOut' ? 'Focus' : viewMode,
    selectedCollectionId,
    collapsedCollections,
    addCollapsedCollectionId,
    removeCollapsedCollectionId
  }), [
    collections,
    informationDialogProps,
    viewMode,
    selectedCollectionId,
    verticalState,
    collapsedCollections,
    addCollapsedCollectionId,
    removeCollapsedCollectionId
  ]);

  return <VerticalViewContent {...verticalViewProps} />;
}
