import { IDetector, IDetectorOptions } from './interfaces';

export const htmlTagDetector: IDetector = {
  name: 'htmlTag',
  lookup: (options?: IDetectorOptions) => {
    let found: string | undefined = undefined;
    const htmlTag = (options && options.htmlTag) || (typeof document !== 'undefined' ? document.documentElement : null);

    if (htmlTag && typeof htmlTag.getAttribute === 'function') {
      found = htmlTag.getAttribute('lang') || '';
    }

    return found;
  },
};
