import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useAuth } from '@iamexperiences/feature-auth';
import { useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { useQuery, UseQueryResult } from 'react-query';

import { useLocalization } from '../../atoms/useLocalization';
import { addDataBoundaryHeaders } from '../../utils/DataBoundaryHeaders';
import { useAppCollectionsQueryConfig } from '../atoms/AppCollectionsProvider';
import { useCollectionCacheConfig } from '../atoms/CollectionCacheProvider';
import { applyViewToDisplayName, cacheKeyItem, cacheKeyScopedLibrary, cacheKeyScopedOrder, CollectionResponse, DeferredCollectionTemplate, ErrorCollectionResponse, ErrorResponse, forbidAllActions, isDeferredCollectionResponse, isErrorResponse, Scope, useCollectionCacheClient } from '../atoms/useCollectionCacheClient';
import { SmartCollectionErrorCodes } from '../utilities/smartCollectionErrorCodes';
import { throwFetchError } from '../utilities/throwFetchError';

type Result<T extends string | null | undefined> = T extends string ? CollectionResponse : null;

/** Looks up all of the current user's collections. */
export function useCollectionQuery<T extends string | null | undefined>(scope: Scope, id: T, enabled: boolean = true): UseQueryResult<Result<T>, string> {
  const [t] = useLocalization();
  const telemetry = useTelemetry();
  const queryKey = cacheKeyItem(scope, id ?? '');
  const { serviceRootUrl, acquireToken } = useAppCollectionsQueryConfig();
  const { getPersistentCache, onLoadSuccess } = useCollectionCacheConfig();
  const [cache, setCache] = getPersistentCache?.(cacheKeyScopedOrder(scope)) ?? [[]];
  const client = useCollectionCacheClient();
  const { auth } = useAuth();

  // We pre-populate from the boot cache.
  // When the list is queried, this entry will be updated as well.
  const initialData = useMemo(() => cache?.find(x => x.id === id), [cache, id]);

  /* Update the collection in user's collection order cache */
  function updateCollectionResponseCache(collection: CollectionResponse) {
    const orderKey = cacheKeyScopedOrder(scope);
    const orderCollections = client.getQueryData(orderKey);

    if (orderCollections?.some(x => x.id === collection.id)) {
      const order = orderCollections.map(x => x.id === collection.id ? collection : x);
      setCache?.(order);
      client.setQueryData(orderKey, order);
    }
  }

  /* Handle smart collection errors and update collection */
  function handleSmartCollectionErrors(error: ErrorResponse | unknown) {
    if (isErrorResponse(error)) {
      // Lookup cache for id & displayName and merge with ErrorResponse from backend and add back to cache.
      // Cleanup - Split collection attributes & data for rendering.
      const orderKey = cacheKeyScopedOrder(scope);
      const orderCollections = client.getQueryData(orderKey);
      const collectionCache = orderCollections?.find(x => x.id == id) ?? null;

      // Show error if license error or cache has no data
      if (error.code === SmartCollectionErrorCodes.NotLicensed || (collectionCache && isDeferredCollectionResponse(collectionCache))) {
        const template = { displayName: collectionCache?.template?.displayName, isComplete: false } as DeferredCollectionTemplate;
        const errorCollection = { id, error: error.message, view: collectionCache?.view, viewPermissions: forbidAllActions, template } as ErrorCollectionResponse;
        updateCollectionResponseCache(errorCollection);
      }
    }
  }

  return useQuery({
    queryKey,
    // The cache is manually invalidated on demand.
    cacheTime: Infinity,
    staleTime: Infinity,
    initialData,
    enabled,
    queryFn: async () => {
      if (!id) return await Promise.resolve(null);
      const token = await acquireToken();
      const headers = addDataBoundaryHeaders(auth);
      headers.append('Authorization', 'Bearer ' + token);

      return await fetch(`${serviceRootUrl}/me/collections/${id}`, {
        headers
      })
        .then(throwFetchError)
        .then(async response => await response.json())
        .then((data: CollectionResponse | ErrorResponse): CollectionResponse => {
          if (isErrorResponse(data)) {
            throw data.message;
          } else {
            return data;
          }
        })
        .then(data => {
        // Replace item in list caches
          updateCollectionResponseCache(data);

          const displayName = applyViewToDisplayName(data.template, data.view, data.viewPermissions);
          const libraryKey = cacheKeyScopedLibrary(scope);
          const libraryCollections = client.getQueryData(libraryKey);
          if (libraryCollections?.some(x => x.id === data.id)) {
            client.setQueryData(libraryKey, libraryCollections.map(x => x.id === data.id ? { id: data.id, displayName } : x));
          }

          try {
            onLoadSuccess?.(queryKey, data);
          } catch {}

          return data;
        })
        .catch((error) => {
          handleSmartCollectionErrors(error);
          telemetry.error('collection-api/error', error);
          toast.error(t('genericApiFailure'), { id: 'collections-api/error' });
          throw error;
        });
    }
  });
}
