import { useLocalization } from '../atoms/useLocalization';
import { useMemo, useState } from 'react';

import { AppDescriptionDialogProps } from '../molecules/AppDescriptionDialog';

interface App {
  readonly description?: string;
  readonly displayName: string;
}

interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: ([app, collection]: readonly [App, unknown]) => boolean;
  readonly act: ([app, collection]: readonly [App, unknown]) => void;
}

interface AppActionViewMoreInfo {
  readonly viewAppDescriptionAction: Action;
  readonly appDescriptionDialogProps: AppDescriptionDialogProps;
}

const defaultApp: App = {
  displayName: '',
  description: ''
} as const;

export function useAppActionViewMoreInfo(shouldViewAppDescription?: boolean): AppActionViewMoreInfo {
  const [t] = useLocalization();
  const [app, setApp] = useState<App>(defaultApp);
  const [hidden, setHidden] = useState<boolean>(true);

  const viewAppDescriptionAction = useMemo((): Action => ({
    key: 'viewAppDescriptionMenuButton',
    name: t('viewAppDescriptionMenuButton'),
    icon: 'Info',
    enabled: ([app]) => (shouldViewAppDescription ?? false) && !!app.description,
    act: ([app]) => {
      if (app?.description) {
        setApp(app);
        setHidden(false);
      }
    }
  }), [shouldViewAppDescription, t]);

  const appDescriptionDialogProps = useMemo((): AppDescriptionDialogProps => ({
    app,
    hidden,
    setHidden
  }), [app, hidden, setHidden]);

  return {
    viewAppDescriptionAction,
    appDescriptionDialogProps
  };
}
