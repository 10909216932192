import React, { createContext, ReactElement, ReactNode, useContext } from 'react';

import { HeaderTelemetryEvent } from '../utilities/HeaderActions';

export interface HeaderDataQueryConfig {
  /** The base url for MSGraph Graph in the current cloud, without trailing slash. */
  readonly msGraphBaseUrl: string;
  /** Callback for getting a bearer token for calling My Account. */
  readonly acquireMSGraphToken: () => Promise<string>;
  /** Callback for getting a bearer token for calling Office Shell. */
  readonly acquireOfficeShellToken: () => Promise<string>;
  /** Callback for getting the office shell URL. */
  readonly officeShellBaseUrl: () => Promise<string | undefined>;
  /**
   * (optional) Callback for reporting API call results.
  */
  readonly reportApiSuccess?: (telemetry: HeaderTelemetryEvent) => void;
  readonly reportApiFailure?: (telemetry: HeaderTelemetryEvent) => void;

}

// Exported for testing
export const Context = createContext<HeaderDataQueryConfig | null>(null);

export interface HeaderDataProviderProps extends HeaderDataQueryConfig {
  readonly children?: ReactNode;
}

export function useHeaderDataQueryConfig(): HeaderDataQueryConfig {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'No ancestor HeaderDataProvider found; useHeaderDataQueryConfig must be called within a HeaderDataProvider context.'
    );
  }
  return context;
}

export function HeaderDataProvider({
  children, ...config
}: HeaderDataProviderProps): ReactElement {
  return <Context.Provider value={config}>{children}</Context.Provider>;
}
