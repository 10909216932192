// Please check the README.md under /packages folder for action naming convention
export enum AppsActions {
  // Auth actions
  authRedirectError = 'auth/network-call/getTenantsByKey-error',

  // Root actions
  bootPerformance = 'root/performance/boot',

  // Suite header actions
  headerRendered = 'suite-header/business-event/rendered',
  headerButtonClicked = 'suite-header/business-event/button-clicked',
  switchApp = 'suite-header/business-event/switch-app',
  switchOrg = 'suite-header/business-event/switch-organization',
  meControlLogout = 'suite-header/business-event/meControl-logOut',

  // End-user collections actions
  setCurrentlyDraggedApp = 'collections/business-event/set-dragged-app',
  clearAppSelection = 'collections/business-event/clear-app-selection',
  syncAppSelectionFromCollection = 'collections/business-event/sync-app-selection',
  setAppSyncFlag = 'collections/business-event/set-app-sync-flag',
  addAppTileSelectionToCollection = 'collections/business-event/add-app-tile-selection',
  toggleAppSelectionInCollection = 'collections/business-event/toggle-app-selection',
  createCollectionEditTitle = 'collections/business-event/create-collection-edit-title',
  updateCollectionOrderState = 'collections/business-event/update-order-state',
  selectCollection = 'collections/business-event/select-collection',

  // App tile actions
  getAssignedAppTiles = 'appTile/network-call/get-assigned-app-tiles',

  // System-initiated feedback actions
  cancelFeedback = 'system-initiated-feedback/business-event/cancel-feedback',
  submitFeedback = 'system-initiated-feedback/network-call/submit-feedback',
  changeRating = 'system-initiated-feedback/business-event/change-rating',

  // TODO: classify actions below
  selectApp = 'business-event/select-app',
  getFeatureEnabled = '/network-call/get-feature-enabled',

  // funnel actions
  funnelManageViewCreateCollectionButtonClicked = 'funnel/collection/manage-view-create-collection-button-clicked',
  funnelAddAppsButtonClickedOnCreateCollectionScreen = 'funnel/collection/add-apps-button-clicked/create-collection',
  funnelAddAppsButtonClickedOnEditCollectionScreen = 'funnel/collection/add-apps-button-clicked/edit-collection',
  funnelNextButtonClickedOnCreateCollectionAddAppsScreen = 'funnel/collection/next-button-clicked/create-collection',
  funnelNextButtonClickedOnEditCollectionAddAppsScreen = 'funnel/collection/next-button-clicked/edit-collection',
  funnelUserEditTitleOnCreateCollection = 'funnel/collection/edit-title/create-collection',
  funnelUserEditTitleOnEditCollection = 'funnel/collection/edit-title/edit-collection',
  funnelSearchBoxUsedOnCreateCollection = 'funnel/collection/search-apps-used/create-collection',
  funnelAppSelectionToggledOnCreateCollection = 'funnel/collection/app-selection-toggled/create-collection',
  funnelAppSelectionToggledOnEditCollection = 'funnel/collection/app-selection-toggled/edit-collection'
}
