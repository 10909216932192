import * as React from 'react';
import { IErrorBoundaryComponentState, IErrorBoundaryProps } from './ErrorBoundary.types';

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryComponentState> {
  public state: IErrorBoundaryComponentState = {
    error: undefined,
    errorInfo: undefined,
  };

  public componentDidCatch = (error: Error, errorInfo: React.ErrorInfo) => {
    this.setState({ error, errorInfo });
  };

  public render(): React.ReactNode {
    const { component } = this.props;
    const { error, errorInfo } = this.state;
    const ErrorComponent = component;
    if (error != null && errorInfo != null) {
      return <ErrorComponent error={error} errorInfo={errorInfo} />;
    } else {
      return this.props.children;
    }
  }
}
