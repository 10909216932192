import { useCallback } from 'react';
import { cacheKeyScopedTileLibrary, Scope, useTileCacheClient } from '../atoms/useTileCacheClient';
import { optimisticRemoveItemsFromList, OptimisticUpdate } from '../utilities/cache';

/**
 * Removes a tile from the library cache and all corresponding collections, and triggers a background refresh of the tile library.
 * Does NOT execute any API operation to unassign or delete the tile.
 */
export function useLocalRemoveTile(): (scope: Scope, id: string) => Promise<OptimisticUpdate> {
  const tileClient = useTileCacheClient();

  return useCallback(async (scope, id) => {
    return await optimisticRemoveItemsFromList(tileClient, cacheKeyScopedTileLibrary(scope), x => x.id === id);
  }, [tileClient]);
}
