import { auth as baseAuth } from '../../../auth';
import { IDetector } from './interfaces';

export const profileLanguageDetector: IDetector = {
  name: 'profileLanguage',
  lookup: async (): Promise<string | undefined> => {
    const auth = baseAuth;
    const user = auth.user;
    const claims = user && (user.rawClaims as { xms_pl?: unknown } | undefined);
    const language = claims && typeof claims.xms_pl === 'string' ? claims.xms_pl : undefined;

    return language;
  }
};
