import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import {
  DragInteractionMethod,
  DroppableContainer
}
  from '@iamexperiences/feature-drag-and-drop/lib/commonjs/organisms/DragContextProvider';
import { useCallback } from 'react';
import toast from 'react-hot-toast';

import { CollectionsTelemetryEvent } from '../../shared/Telemetry/CollectionsTelemetryEvent';
import { useLocalization } from '../atoms/useLocalization';
import { Collection, Tile } from '../client-app-collections';
import { KnownCollectionIds } from '../client-app-collections/atoms/useCollectionCacheClient';
import { useUpdateCollectionViews } from '../client-app-collections/organisms/useUpdateCollectionViews';

interface DragAndDropInteractions {
  readonly onItemDropped: (
    item: Tile,
    sourceContainer: string,
    dropContainer: string,
    index: number,
    copy: boolean,
    Collections: Array<DroppableContainer<Tile>>,
    updated: boolean,
    distance: number,
    time: number
  ) => void;
  readonly onDragStarted: (item: Tile, sourceContainer: string, type: DragInteractionMethod) => void;
  readonly onItemOverDropContainer: (item: Tile, sourceContainer: string, dropContainer: string | undefined) => boolean;
  readonly copyFromContainer: (collection: Collection) => boolean;
  readonly reorderContainer: (collection: Collection) => boolean;
}

interface DragAndDropResultTelemetryData {
  readonly sourceCollectionId: string;
  readonly itemId: string;
  readonly copy: boolean;
  readonly updated: boolean;
  readonly finalIndex?: number;
  readonly destinationCollectionId?: string;
  readonly distance?: number;
  readonly time?: number;
  errorMessage?: any;
}

interface DragAndDropStartTelemetryData {
  readonly sourceCollectionId: string;
  readonly itemId: string;
  readonly type: string;
}

export function useDragAndDropInteractions(collections: readonly Collection[]): DragAndDropInteractions {
  const telemetry = useTelemetry();
  const [t] = useLocalization();
  const updateCollections = useUpdateCollectionViews();

  const onMoveAppFromCollection = useCallback((
    item: Tile,
    updatedItems: readonly Tile[],
    selectedDropCollectionId: string,
    selectedSourceCollectionId: string,
    expandedDropContainer: Collection,
    expandedSourceContainer: Collection,
    telemetryData: DragAndDropResultTelemetryData,
    updatedSourceTiles?: readonly Tile[]
  ) => {
    if (expandedDropContainer.loadingState === 'loaded' && expandedSourceContainer.loadingState === 'loaded') {
      updateCollections([{
        id: selectedDropCollectionId,
        explicitItemIds: updatedItems.map((x) => x.id),
        hiddenItemIds: expandedDropContainer.view?.hiddenItemIds
          ? expandedDropContainer.view?.hiddenItemIds.filter((x) => x !== item.id)
          : [],
        useViewOrder: true,
        operation: 'move',
        displayName: expandedDropContainer.displayName
      },
      {
        id: selectedSourceCollectionId,
        explicitItemIds: updatedSourceTiles
          ? updatedSourceTiles.map((x) => x.id)
          : expandedSourceContainer.view?.explicitItemIds,
        hiddenItemIds: expandedSourceContainer.view?.hiddenItemIds
          ? [...expandedSourceContainer.view.hiddenItemIds, item.id]
          : [item.id],
        operation: 'move',
        displayName: expandedSourceContainer.displayName
      }]);
      telemetry.reportCustomEvent(CollectionsTelemetryEvent.AddAppToCollectionWithDragAndDrop, telemetryData);
      telemetry.reportCustomEvent(CollectionsTelemetryEvent.RemoveAppFromCollectionWithDragAndDrop, telemetryData);
    }
  }, [telemetry, updateCollections]);

  const onAddAppToCollection = useCallback((
    updatedItems: readonly Tile[],
    item: Tile,
    selectedCollectionId: string,
    expandedDropContainer: Collection,
    telemetryData: DragAndDropResultTelemetryData
  ) => {
    telemetry.reportCustomEvent(CollectionsTelemetryEvent.AddAppToCollectionWithDragAndDrop, telemetryData);
    if (expandedDropContainer.loadingState === 'loaded') {
      updateCollections([{
        id: selectedCollectionId,
        explicitItemIds: updatedItems.map(x => x.id),
        hiddenItemIds: expandedDropContainer.view?.hiddenItemIds
          ? expandedDropContainer.view?.hiddenItemIds.filter((x) => x !== item.id)
          : [],
        useViewOrder: true,
        operation: 'copy',
        displayName: expandedDropContainer.displayName
      }]);
    }
  }, [telemetry, updateCollections]);

  const onListOrderUpdated = useCallback((
    item: Tile,
    updatedItems: readonly Tile[],
    selectedCollectionId: string,
    telemetryData: DragAndDropResultTelemetryData,
    expandedDropContainer: Collection
  ) => {
    telemetry.reportCustomEvent(CollectionsTelemetryEvent.ReorderCollectionWithDragAndDrop, telemetryData);
    if (expandedDropContainer.loadingState === 'loaded') {
      updateCollections([{
        id: selectedCollectionId,
        explicitItemIds: updatedItems.map(x => x.id),
        hiddenItemIds: expandedDropContainer.view?.hiddenItemIds
          ? expandedDropContainer.view?.hiddenItemIds.filter((x) => x !== item.id)
          : [],
        useViewOrder: true,
        operation: 'reorder',
        displayName: expandedDropContainer.displayName
      }]);
    }
  }, [telemetry, updateCollections]);

  const onItemDropped = useCallback(
    (
      item: Tile,
      sourceContainer: string,
      dropContainer: string,
      index: number,
      copy: boolean,
      Collections: Array<DroppableContainer<Tile>>,
      updated: boolean,
      distance: number,
      time: number
    ) => {
      let expandedSourceContainer;
      let expandedDropContainer;
      const telemetryData: DragAndDropResultTelemetryData = {
        sourceCollectionId: sourceContainer,
        itemId: item.id,
        copy,
        finalIndex: index,
        updated,
        destinationCollectionId: dropContainer,
        distance,
        time
      };
      try {
        telemetry.reportCustomEvent(CollectionsTelemetryEvent.EndDragAndDrop);
        expandedSourceContainer = collections.filter((x) => x.id === sourceContainer).pop();
        expandedDropContainer = collections.filter((x) => x.id === dropContainer).pop();
        const updatedDropTiles = Collections.filter((x) => x.id === dropContainer).pop()?.items;
        const updatedSourceTiles = Collections.filter((x) => x.id === sourceContainer).pop()?.items;

        if (updated) {
          if (updatedDropTiles && sourceContainer && dropContainer === sourceContainer && expandedDropContainer) {
            onListOrderUpdated(item, updatedDropTiles, sourceContainer, telemetryData, expandedDropContainer);
          }
          if (expandedSourceContainer &&
              expandedDropContainer &&
              updatedDropTiles &&
              sourceContainer &&
              dropContainer &&
              dropContainer !== sourceContainer &&
              !copy) {
            onMoveAppFromCollection(
              item,
              updatedDropTiles,
              dropContainer,
              sourceContainer,
              expandedDropContainer,
              expandedSourceContainer,
              telemetryData,
              updatedSourceTiles);
          }
          if (updatedDropTiles &&
              sourceContainer &&
              expandedDropContainer &&
              dropContainer &&
              dropContainer !== sourceContainer &&
              copy) {
            telemetry.reportCustomEvent(CollectionsTelemetryEvent.CopyFromCollectionWithDragAndDrop);
            onAddAppToCollection(updatedDropTiles, item, dropContainer, expandedDropContainer, telemetryData);
          }
        } else {
          if ((expandedDropContainer && !expandedDropContainer?.viewPermissions.canReorderItems)) {
            toast.error(t('editAdminCollectionError'), { id: 'editAdminCollectionError' });
          } else if (expandedDropContainer?.loadingState === 'loaded') {
            if (expandedDropContainer.items.map((x) => x.id).includes(item.id) &&
            expandedDropContainer.id !== expandedSourceContainer?.id) {
              if (copy) {
                toast.error(t('duplicateAppErrorCopy'), { id: 'duplicateAppErrorCopy' });
              } else {
                toast.error(t('duplicateAppErrorMove'), { id: 'duplicateAppErrorMove' });
              }
            }
          }
          telemetry.reportCustomEvent(CollectionsTelemetryEvent.DragAndDropWithNoUpdate, telemetryData);
        }
      } catch (error) {
        telemetryData.errorMessage = error instanceof Error ? error.message : error;
        telemetry.reportCustomEvent(CollectionsTelemetryEvent.DragAndDropError, telemetryData);
      }
    }, [telemetry, collections, onListOrderUpdated, onMoveAppFromCollection, onAddAppToCollection, t]);

  const onDragStarted = useCallback((item: Tile, sourceContainer: string, type: DragInteractionMethod) => {
    const telemetryData: DragAndDropStartTelemetryData = {
      sourceCollectionId: sourceContainer,
      itemId: item.id,
      type: type.toString()
    };
    telemetry.reportCustomEvent(CollectionsTelemetryEvent.StartDragAndDrop, telemetryData);
  }, [telemetry]);

  const onItemOverDropContainer = useCallback(
    (
      item: Tile,
      sourceContainer: string,
      dropContainer: string | undefined
    ) => {
      const expandedDropContainer = collections.filter((x) => x.id === dropContainer).pop();
      const canAddToCollection = expandedDropContainer?.viewPermissions.canAddItems;
      const canReorderCollection = expandedDropContainer?.viewPermissions.canReorderItems;
      let dropContainerItems;
      if (expandedDropContainer && expandedDropContainer.loadingState === 'loaded') {
        dropContainerItems = expandedDropContainer.items;
      }
      const validDropTarget = (!dropContainerItems?.includes(item) && canAddToCollection) ?? false;
      const validReorderTarget = (dropContainerItems?.includes(item) && canReorderCollection) ?? false;
      return sourceContainer === dropContainer ? validReorderTarget : validDropTarget;
    }, [collections]);

  const copyFromContainer = useCallback((collection: Collection) => {
    return collection.id === KnownCollectionIds.allapps || !collection.viewPermissions.canReorderItems;
  }, []);
  const reorderContainer = useCallback((collection: Collection) => {
    return collection.viewPermissions.canReorderItems;
  }, []);

  return {
    onItemDropped,
    onDragStarted,
    onItemOverDropContainer,
    copyFromContainer,
    reorderContainer
  };
}
