import { useAuth } from '@iamexperiences/feature-auth';
import { useState } from 'react';

import { settingsManager } from '../../shared/settings';

interface DataBoundary {
  readonly dataBoundary?: string;
  readonly isRegionalEndpointEnabled: boolean;
}

export function useDataBoundary(): DataBoundary {
  const { auth } = useAuth();
  const user = auth.user;
  const claims = user && (user.rawClaims as { xms_tdbr?: unknown } | undefined);
  const [dataBoundary] = useState<string | undefined>(
    claims && typeof claims.xms_tdbr === 'string' ? claims.xms_tdbr : undefined
  );

  const isRegionalEndpointEnabled = settingsManager.get('regionalCollectorEndpointEnabled') ?? false;

  return {
    dataBoundary,
    isRegionalEndpointEnabled
  };
}
