import { useCallback, useState } from 'react';
import { ConfirmationDialogProps } from './ConfirmationDialog';

export type ConfirmationDialogStrings = Omit<ConfirmationDialogProps, 'hidden' | 'onDeny' | 'onConfirm'>;

export interface OpenDialogFunction {
  /**
   * @param strings strings to display in the dialog
   * @param onConfirm callback to execute when the operation is confirmed by the user
   */
  (strings: ConfirmationDialogStrings, onConfirm: () => void): void;
}

const noop = () => {};
const noDialog: ConfirmationDialogProps = {
  hidden: true,
  onDeny: noop,
  onConfirm: noop,
  title: '',
  body: '',
  confirm: '',
  deny: '',
  action: '',
};

/** Maintains a stack of confirmation dialogs and returns the props to pass to the UX component for the top dialog. */
export function useConfirmationDialog(): [OpenDialogFunction, ConfirmationDialogProps] {
  const [stack, setStack] = useState<readonly ConfirmationDialogProps[]>([noDialog]);

  const closeDialog = useCallback(() => {
    setStack(stack => stack.slice(0, stack.length-1));
  }, []);

  const openDialog = useCallback((strings: ConfirmationDialogStrings, onConfirm: () => void) => {
    const props: ConfirmationDialogProps = {
      ...strings,
      hidden: false,
      onDeny: closeDialog,
      onConfirm: () => {
        closeDialog();
        onConfirm();
      }
    };

    setStack(stack => stack.length === 1 ?
      [{ ...noDialog, ...strings }, props] : // set strings of hidden state to match bottom dialog for smooth animation
      [...stack, props]
    );
  }, [closeDialog]);

  return [openDialog, stack[stack.length-1]];
}
