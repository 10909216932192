import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useMemo } from 'react';

import { useGlobalActionAddApp } from './useGlobalActionAddApp';
import { useGlobalActionCreateCollection } from './useGlobalActionCreateCollection';
import { useGlobalActionCustomizeView } from './useGlobalActionCustomizeView';

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon?: string;
  readonly enabled: () => boolean;
  readonly act: () => void;
  readonly subActionItems?: readonly Action[];
}

export function useGlobalActions(createBookmark: Action): Action[] {
  const telemetry = useTelemetry();
  const createCollection = useGlobalActionCreateCollection();
  const manageCollections = useGlobalActionCustomizeView();
  const addApp = useGlobalActionAddApp(createBookmark);

  return useMemo(
    () =>
      [addApp, createCollection, manageCollections].map((action) => ({
        ...action,
        act: () => {
          telemetry.reportCustomEvent(`contextMenu/global/${action.key}`);
          action.act();
        }
      })),
    [createCollection, manageCollections, addApp, telemetry]
  );
}
