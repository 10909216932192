import { useQueryClient } from 'react-query';
import { CacheClient } from '../utilities/cache';

/** Data for a launchable app tile. */
export interface Tile {
  /** Unique identifier for the tile. */
  readonly id: string;
  /** The source of the app tile. */
  readonly provider: string;
  /** The provider's id for the tile. */
  readonly scopedId: string;
  /** Display name of the tile. */
  readonly displayName: string;
  /** Display description of the collection. */
  readonly description?: string;
  /** Location of the logo to display on the tile. */
  readonly logoUrl: string;
  /** Location to bookmark for launching this tile later. */
  readonly persistentLaunchUrl: string;
  /** Location to launch the url within the current session. */
  readonly fastLaunchUrl: string;
}

export enum KnownCollectionIds {
  allapps = "allapps",
  popular = "popularapps",
  recent = "recentapps",
  recommended = "recommendedapps"
}

/** Settings that control which actions are allowed for customizing a collection. */
export interface CollectionViewPermissions {
  readonly canAddItems: boolean;
  readonly canRemoveItems: boolean;
  readonly canReorderItems: boolean;
  readonly canRenameList: boolean;
}

export const forbidAllActions = {
  canAddItems: false,
  canRemoveItems: false,
  canReorderItems: false,
  canRenameList: false,
} as const;

export function forbidAppActions(renameAllowed: boolean): CollectionViewPermissions {
  return {
    ...forbidAllActions,
    canRenameList: renameAllowed
  };
}

/** Error response from a collection query backend API. */
export interface ErrorResponse{
  code: string,
  message: string
}

export function isErrorResponse(response: CollectionResponse | ErrorResponse | unknown): response is ErrorResponse {
  return response != null && (response as ErrorResponse).hasOwnProperty('code');
}

export interface ErrorDetails {
  /** HTTP status code for the error response. */
  readonly statusCode: number;
  /** Service-specific error code. */
  readonly errorCode: number;
  /** Error message returned by the server. */
  readonly message: string;
}

export interface PlaceholderCollectionResponse {
  readonly id: string;
  readonly displayName: string;
}

/** Properties always received when loading a collection. */
interface CommonCollectionResponse {
  readonly id: string;
  readonly viewPermissions: CollectionViewPermissions;
  readonly view: CollectionView | null;
}

/**
 * API response when loading a collection as part of a list
 * that must be loaded again individually to get its items.
 */
interface DeferredCollectionResponse extends CommonCollectionResponse {
  readonly template: DeferredCollectionTemplate;
}

export function isDeferredCollectionResponse(response: CollectionResponse): response is DeferredCollectionResponse {
  return response.template?.isComplete === false && !(response.template as IncompleteCollectionTemplate).itemIds;
}

export function isSmartOrDeferredCollection(collection: CollectionResponse){
  return isDeferredCollectionResponse(collection) || collection.id === KnownCollectionIds.popular || collection.id === KnownCollectionIds.recent;
}

function convertDeferredCollectionResponse(response: DeferredCollectionResponse): CollectionLoadingItems {
  const displayName = applyViewToDisplayName(response.template, response.view, response.viewPermissions);
  return {
    id: response.id,
    displayName: displayName,
    loadingState: 'items',
    viewPermissions: forbidAllActions,
    template: response.template,
    view: response.view
  };
}

/** API response when loading an additive collection with an explicit list of items. */
interface IncompleteCollectionResponse extends CommonCollectionResponse {
  readonly template: IncompleteCollectionTemplate;
}

function isIncompleteCollectionResponse(response: CollectionResponse): response is IncompleteCollectionResponse {
  return response.template?.isComplete === false && !!(response.template as IncompleteCollectionTemplate).itemIds;
}

function convertIncompleteCollectionResponse(response: IncompleteCollectionResponse): CollectionLoadingTiles | FullyLoadedCollection {
  const displayName = applyViewToDisplayName(response.template, response.view, response.viewPermissions);
  return applyView({
    id: response.id,
    displayName,
    loadingState: 'apply-view',
    viewPermissions: response.viewPermissions,
    template: response.template,
    view: response.view
  });
}

/** API response when loading a subtractive collection that includes all items by default. */
interface CompleteCollectionResponse extends CommonCollectionResponse {
  readonly template: CompleteCollectionTemplate;
}

function isCompleteCollectionResponse(response: CollectionResponse): response is CompleteCollectionResponse {
  return response.template?.isComplete ?? false;
}

function convertCompleteCollectionResponse(response: CompleteCollectionResponse): CollectionLoadingItems {
  const displayName = applyViewToDisplayName(response.template, response.view, response.viewPermissions);
  return {
    id: response.id,
    displayName: displayName,
    loadingState: 'items',
    viewPermissions: response.viewPermissions,
    template: response.template,
    view: response.view
  };
}

/** API response when loading an explicit personal collection. */
interface PersonalCollectionResponse extends CommonCollectionResponse {
  /** Empty */
  readonly template: null;
}

function isPersonalCollectionResponse(response: CollectionResponse): response is PersonalCollectionResponse {
  return !response.template;
}

function convertPersonalCollectionResponse(response: PersonalCollectionResponse): CollectionLoadingTiles | FullyLoadedCollection {
  const displayName = applyViewToDisplayName(response.template, response.view, response.viewPermissions);
  return applyView({
    id: response.id,
    displayName,
    loadingState: 'apply-view',
    viewPermissions: response.viewPermissions,
    template: null,
    view: response.view
  });
}

/** NOTE: Not an API response. Represents Error in CollectionResponse by merging ErrorResponse object with any collection response */
export interface ErrorCollectionResponse extends CommonCollectionResponse {
  /** Empty */
  readonly template: DeferredCollectionTemplate;
  /** Error message to display to the user. */
  readonly error: string; //ErrorDetails;
}

function isErrorCollectionResponse(response: CollectionResponse): response is ErrorCollectionResponse {
  return !!(response as ErrorCollectionResponse).error;
}

function convertErrorCollectionResponse(response: ErrorCollectionResponse): CollectionWithLoadError {
  const displayName = applyViewToDisplayName(response.template, response.view, response.viewPermissions);
  return { id: response.id, displayName: displayName, error: response.error, loadingState: "error", viewPermissions: response.viewPermissions} as CollectionWithLoadError;
}

export type CollectionResponse = PersonalCollectionResponse | CompleteCollectionResponse | IncompleteCollectionResponse | DeferredCollectionResponse | ErrorCollectionResponse;

export function convertCollectionResponse(response: CollectionResponse): Collection {

  if(isErrorCollectionResponse(response)){
    return convertErrorCollectionResponse(response);
  } else if (isPersonalCollectionResponse(response)) {
    return convertPersonalCollectionResponse(response);
  } else if (isCompleteCollectionResponse(response)) {
    return convertCompleteCollectionResponse(response);
  } else if (isIncompleteCollectionResponse(response)) {
    return convertIncompleteCollectionResponse(response);
  } else {
    return convertDeferredCollectionResponse(response);
  } 
}

export function applyViewToDisplayName(template: CompleteCollectionTemplateWithItems | CompleteCollectionTemplate | IncompleteCollectionTemplate | DeferredCollectionTemplate | null, view: CollectionView | null, viewPermissions: CollectionViewPermissions): string {
  return (viewPermissions.canRenameList ? view?.displayName : undefined) ?? template?.displayName ?? '';
}

export function applyView(collection: CollectionApplyingView): CollectionLoadingTiles | FullyLoadedCollection {
  // Start with the items from the template
  const templateItemIds = collection.template?.itemIds ?? [];
  let itemIds: readonly string[] = templateItemIds;

  // If the user has not personalized this, or personalization is not allowed
  // return the apps from the base collection exactly as they are
  if (collection.view) {
    // User-defined order
    if (collection.viewPermissions.canReorderItems && collection.view.useViewOrder && collection.view.explicitItemIds) {
      // Remove explicit items; they will be prepended later in the view order.
      // This moves implicit (new) items to the end.
      const explicitItemSet = collection.view.explicitItemIds.reduce((v, x) => ({ ...v, [x]: true }), {});
      itemIds = itemIds.filter(x => !explicitItemSet[x]);

      if (collection.viewPermissions.canAddItems) {
        // Add known apps to the front in the expected order
        itemIds = [...collection.view.explicitItemIds, ...itemIds];
      } else {
        // Add remaining base apps to the front in the chosen order
        const templateItemSet = templateItemIds.reduce((v, x) => ({ ...v, [x]: true }), {});
        itemIds = [...collection.view.explicitItemIds.filter(x => templateItemSet[x]), ...itemIds];
      }
    // Default order
    } else {
      if (collection.viewPermissions.canAddItems && collection.view.explicitItemIds) {
        // Add remaining known apps to the end
        const templateItemSet = templateItemIds.reduce((v, x) => ({ ...v, [x]: true }), {});
        itemIds = [...itemIds, ...collection.view.explicitItemIds.filter(x => !templateItemSet[x])];
      }
    }

    if (collection.viewPermissions.canRemoveItems && collection.view.hiddenItemIds) {
      // Remove hidden apps
      const hiddenItemSet = collection.view.hiddenItemIds.reduce((v, x) => ({ ...v, [x]: true }), {});
      itemIds = itemIds.filter(x => !hiddenItemSet[x]);
    }
  }

  if (itemIds.length === 0) {
    return {
      ...collection,
      loadingState: 'loaded',
      items: [],
      itemCount: 0
    };
  } else {
    return {
      ...collection,
      loadingState: 'tiles',
      itemIds,
      itemCount: itemIds.length
    };
  }
}

/** A placeholder for a collection in a list. */
export interface BasicCollection {
  readonly id: string;
  readonly displayName: string;
}

export function collectionLoadingMetadata(id: string, displayName: string): DeferredCollectionResponse {
  return {
    id,
    viewPermissions: forbidAllActions,
    template: {
      displayName,
      isComplete: false
    },
    view: null
  }
}

/** Represents a collection that completely failed to load. */
export interface CollectionWithLoadError {
  /** Unique identifier for the collection. */
  readonly id: string;
  readonly displayName: string;
  /** Whether the collection is loading or the load succeeded or failed. */
  readonly loadingState: 'error';
  readonly viewPermissions: typeof forbidAllActions;
  /** Error message to display to the user. */
  readonly error: string; //ErrorDetails;
}

/**
 * Represents a collection that has partially loaded as part of a list
 * but needs to be loaded individually to get its items.
 * Or needs tiles to be loaded to get its items.
 */
export interface CollectionLoadingItems {
  /** Unique identifier for the collection. */
  readonly id: string;
  readonly displayName: string;
  /** Whether the collection is loading or the load succeeded or failed. */
  readonly loadingState: 'items';
  readonly viewPermissions: CollectionViewPermissions;
  readonly template: DeferredCollectionTemplate | CompleteCollectionTemplate;
  readonly view: CollectionView | null;
}

export interface CollectionApplyingView {
  readonly id: string;
  readonly displayName: string;
  readonly loadingState: 'apply-view',
  readonly viewPermissions: CollectionViewPermissions;
  readonly template: IncompleteCollectionTemplate | CompleteCollectionTemplateWithItems | null;
  readonly view: CollectionView | null;
}

/** Represents a collection that successfully loaded items, but is awaiting tiles to be populated. */
export interface CollectionLoadingTiles {
  /** Unique identifier for the collection. */
  readonly id: string;
  readonly displayName: string;
  /** Whether the collection is loading or the load succeeded or failed. */
  readonly loadingState: 'tiles';
  readonly viewPermissions: CollectionViewPermissions;
  readonly template: IncompleteCollectionTemplate | CompleteCollectionTemplateWithItems | null;
  readonly view: CollectionView | null;
  readonly itemIds: readonly string[];
  readonly itemCount: number;
}

export interface CollectionWithTilesLoadError {
  /** Unique identifier for the collection. */
  readonly id: string;
  readonly displayName: string;
  /** Whether the collection is loading or the load succeeded or failed. */
  readonly loadingState: 'tiles-error';
  readonly viewPermissions: CollectionViewPermissions;
  readonly template: IncompleteCollectionTemplate | CompleteCollectionTemplateWithItems | null;
  readonly view: CollectionView | null;
  readonly itemIds: readonly string[];
  readonly itemCount: number;
  /** Error message to display to the user. */
  readonly error: string; //ErrorDetails;
}

export interface CompleteCollectionTemplate {
  /** Display name of the collection. */
  readonly displayName: string;
  readonly isComplete: true;
}

export interface CompleteCollectionTemplateWithItems {
  readonly displayName: string;
  readonly isComplete: true;
  readonly itemIds: readonly string[];
}

export interface DeferredCollectionTemplate {
  /** Display name of the collection. */
  readonly displayName: string;
  readonly isComplete: false;
}

export interface IncompleteCollectionTemplate {
  /** Display name of the collection. */
  readonly displayName: string;
  readonly isComplete: false;
  readonly itemIds: readonly string[];
}

export interface CollectionView {
  readonly useViewOrder: boolean;
  readonly explicitItemIds: readonly string[];
  readonly hiddenItemIds: readonly string[];
  readonly displayName?: string;
}

/** Represents a collection that has fully loaded. */
export interface FullyLoadedCollection {
  /** Unique identifier for the collection. */
  readonly id: string;
  /** Display name of the collection. */
  readonly displayName: string;
  /** Whether the collection data is still loading. */
  readonly loadingState: 'loaded';
  /** Determines which customizations to allow on the collection. */
  readonly viewPermissions: CollectionViewPermissions;
  /** Describes the base properties of the collection before customizations are applied. */
  readonly template: CompleteCollectionTemplateWithItems | IncompleteCollectionTemplate | null;
  /** Describes customizations of the base template. */
  readonly view: CollectionView | null;
  /** Tiles in the collection. */
  readonly items: readonly Tile[];
  readonly itemCount: number;
}

/** Data for a single app tile collection. */
export type Collection =
  FullyLoadedCollection |
  CollectionWithTilesLoadError |
  CollectionLoadingTiles |
  CollectionLoadingItems |
  CollectionWithLoadError;

export type Scope = 'user' | 'tenant';

/** This key applies to all collection-related caches. */
export const cacheKeyAll = ['collection'] as const;

export const cacheKeyOrder = ['collection', 'order'] as const;
export function cacheKeyScopedOrder(scope: Scope): CacheKeyScopedOrder {
  return ['collection', 'order', scope];
}

export const cacheKeyLibrary = ['collection', 'library'] as const;
export function cacheKeyScopedLibrary(scope: Scope): CacheKeyScopedLibrary {
  return ['collection', 'library', scope];
}

/** These keys apply to individual collection-item caches. */
export function cacheKeyItem(scope: Scope, id: string): CacheKeyItem {
  return ['collection', 'item', scope, id];
}

export type CacheKeyAll = typeof cacheKeyAll;
export type CacheKeyOrder = typeof cacheKeyOrder;
export type CacheKeyScopedOrder = readonly ['collection', 'order', Scope];
export type CacheKeyLibrary = typeof cacheKeyLibrary;
export type CacheKeyScopedLibrary = readonly ['collection', 'library', Scope];
export type CacheKeyItem = readonly ['collection', 'item', Scope, string];
export type CacheKey = CacheKeyAll | CacheKeyOrder | CacheKeyScopedOrder | CacheKeyLibrary | CacheKeyScopedLibrary | CacheKeyItem;

export type Cache =
  [CacheKeyAll, void] |
  [CacheKeyLibrary, void] |
  [CacheKeyOrder, void] |
  [CacheKeyScopedLibrary, readonly BasicCollection[]] |
  [CacheKeyScopedOrder, readonly CollectionResponse[]] |
  [CacheKeyItem, CollectionResponse];

export function useCollectionCacheClient(): CacheClient<Cache> {
  return useQueryClient();
}
