import { useCallback } from 'react';
import { cacheKeyItem, cacheKeyScopedLibrary, cacheKeyScopedOrder, Scope, useCollectionCacheClient } from '../atoms/useCollectionCacheClient';
import { optimisticRemoveItemsFromList, optimisticRemoveValue, OptimisticUpdate } from '../utilities/cache';

/**
 * Updates all caches containing a collection, and triggers a background refresh of all containing lists.
 * Does NOT execute any API operation to delete the collection on the backend.
 */
export function useLocalDeleteCollection(): (scope: Scope, id: string) => Promise<OptimisticUpdate> {
  const client = useCollectionCacheClient();

  return useCallback(async (scope, id) => {
    const updates = [
      await optimisticRemoveItemsFromList(client, cacheKeyScopedOrder(scope), x => x.id === id),
      await optimisticRemoveItemsFromList(client, cacheKeyScopedLibrary(scope), x => x.id === id),
    ];

    const itemKey = cacheKeyItem(scope, id);
    const item = client.getQueryData(cacheKeyItem(scope, id));
    if (item) {
      updates.push(await optimisticRemoveValue(client, itemKey));
    }

    return {
      complete: async () => {
        await Promise.all(updates.map(x => x.complete()));
      },
      rollback: async () => {
        await Promise.all(updates.map(x => x.rollback()));
      }
    }
  }, [client]);
}
