import { useCollectionOrderQuery, useTileLibraryQuery } from '../client-app-collections';
import { isDeferredCollectionResponse } from '../client-app-collections/atoms/useCollectionCacheClient';
import { useMarkFramePaint } from '../molecules/useMarkFramePaint';

export function useCollectionLoadTelemetry(): void {
  const { isLoading: areCollectionsLoading, data } = useCollectionOrderQuery('user');
  const { isLoading: areTilesLoading } = useTileLibraryQuery('user');
  // The UI "progressive load" has four phases:
  //  1. no info is available yet --> show loading spinner
  //  2. collection order is available --> show list of collections, each in loading shimmer state (FCP)
  //  3. collection order and tiles are available --> show most collections with accurate tiles, but some may be deferred (FMP)
  //  4. deferred collections have loaded --> show the full page
  // Note:
  //  - step 1 can be skipped if there is a localStorage cache
  //  - step 2 can be skipped if tiles load before collections
  //  - step 3 can be skipped if no collections have been deferred
  // Here we mark the time to arrive at each stage above
  useMarkFramePaint('Initial Render', true);
  useMarkFramePaint('Collections Rendered', !areCollectionsLoading);
  useMarkFramePaint('Tiles Rendered', !areCollectionsLoading && !areTilesLoading);
  useMarkFramePaint('Deferred Collections Rendered', !areCollectionsLoading && !areTilesLoading && !data?.some(isDeferredCollectionResponse));
}
