import { mergeStyleSets } from '@fluentui/react';
import React, { lazy, ReactElement, Suspense } from 'react';

import { settingsManager } from '../../shared/settings';

export const SystemInitiatedFeedback = lazy(async () =>
  await import(/* webpackChunkName: "SystemInitiatedFeedback" */ '../organisms/SystemInitiatedFeedback').then((module) => ({ default: module.SystemInitiatedFeedback })
  ));

const classNames = mergeStyleSets({
  feedback: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0
  }
});

export function Feedbackfeature(): ReactElement | null {
  return (
    settingsManager.get('isFeedbackEnabled')
      ? (
      <Suspense fallback={<div></div>}>
        <div className={classNames.feedback}>
          <SystemInitiatedFeedback/>
        </div>
      </Suspense>
        )
      : null
  );
}
