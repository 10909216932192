import { useCallback } from 'react';

import { useCollectionModals } from '../atoms/CollectionModalsProvider';
import { usePage } from '../atoms/PageProvider';
import { Tile } from '../client-app-collections/atoms/useCollectionCacheClient';

interface Route {
  readonly goToHomePage: () => void;
  readonly goToManageAppPage: (appTile: Tile) => void;
  readonly openEditCollection: (collectionId: string) => void;
  readonly openCreateCollection: () => void;
  readonly openManageCollections: () => void;
  readonly goToRequestNewApps: () => void;
}

export function useRoute(): Route {
  const [, setActivePage] = usePage();
  const { pushModal } = useCollectionModals();

  const goToHomePage = useCallback(() => {
    setActivePage({ name: 'launch' });
  }, [setActivePage]);

  const openEditCollection = useCallback((collectionId: string) => {
    void pushModal('edit', { collectionId }).catch(e => e);
  }, [pushModal]);

  const openCreateCollection = useCallback(() => {
    void pushModal('create').catch(e => e);
  }, [pushModal]);

  const goToManageAppPage = useCallback((appTile: Tile) => {
    setActivePage({ name: 'manageApp', props: { spId: appTile.scopedId, logoUrl: appTile.logoUrl } });
  }, [setActivePage]);

  const openManageCollections = useCallback(() => {
    void pushModal('manage').catch(e => e);
  }, [pushModal]);

  const goToRequestNewApps = useCallback(() => {
    setActivePage({ name: 'requestNewApps' });
  }, [setActivePage]);

  return {
    goToHomePage,
    openEditCollection,
    openCreateCollection,
    goToManageAppPage,
    openManageCollections,
    goToRequestNewApps
  };
}
