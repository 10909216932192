import { ErrorResponse } from "../atoms/useCollectionCacheClient";

/** If the fetch response represents an error, this extracts the failure reason and throws it. Otherwise the response is returned as-is. */
export function throwFetchError(response: Response): Promise<Response> | Response {
  if (!response) {
    return Promise.reject('no response');
  }
  if (response.status >= 400) {
    return response.json().then(json => {
      // First try to parse json response as an odata error
      const error = json?.error;
      const code = error?.code ?? json?.code ?? error?.code ?? undefined;
      const message = error?.message ?? json?.message ?? error?.code ?? undefined;
      if (message && message.length) return { code: code, message: message } as ErrorResponse;
      // Response is json but not odata, so we fall back to the status message
      return Promise.reject();
    }, () => {
      // Response is not json, so try to parse the body as raw text
      return response.text();
    }).catch(() => {
      // Parsing the body failed, so use the status message
      return response.statusText || null;
    }).then(message => {
      // Reject the final promise with the failure reason.
      return Promise.reject(message);
    });
  }
  return response;
}
