import { ISettings } from './ISettings';
import * as mcSettings from './settings.mc.json';
import * as arSettings from './settings.ar.json';
import * as baseSettings from './settings.base.json';
import * as ppeSettings from './settings.ppe.json';
import * as prodSettings from './settings.prod.json';
import * as usSecSettings from './settings.ussec.json';
import * as usNatSettings from './settings.usnat.json';
import * as SettingsManager from './SettingsManager';

const getEnvSettings = () => {
  let envSettings: ISettings = prodSettings;
  const hostname = window.location.hostname;

  if (/-ppe\./.test(hostname)) {
    envSettings = ppeSettings;
  } else if (/\.com$/.test(hostname)) {
    envSettings = prodSettings;
  } else if (/\.us$/.test(hostname)) {
    envSettings = arSettings;
  } else if (/\.cn$/.test(hostname)) {
    envSettings = mcSettings;
  } else if (/\.scloud$/.test(hostname)) {
    envSettings = usSecSettings;
  } else if (/\.eaglex.ic.gov$/.test(hostname)) {
    envSettings = usNatSettings;
  }
  return envSettings;
};

export const settingsManager = SettingsManager.create<ISettings>()
  .addSettings(baseSettings)
  .addSettings(getEnvSettings());
