import { useLocalization } from '../../atoms/useLocalization';
import { useCallback, useMemo, useState } from 'react';
import { useCollectionModals } from '../../atoms/CollectionModalsProvider';

export interface Action {
  readonly key: string;
  readonly name: string;
  readonly enabled: () => boolean;
  readonly act: () => void;
}

export function useBookmarkActionCreate(): [
  action: Action,
] {
  const [t] = useLocalization();
  const [modalHidden, setModalHidden] = useState(true);
  const hideModal = useCallback(() => setModalHidden(true), []);
  const { pushModal } = useCollectionModals();

  return useMemo(() => ([{
    key: 'addSite',
    name: t('addSite'),
    enabled: () => true,
    act: () => {
      pushModal('createBookmark');
    }
  }]), [modalHidden, hideModal, t]);
}
