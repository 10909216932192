import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { CollectionsBody } from '@iamexperiences/feature-app-launching';
import React, { lazy, ReactElement, Suspense, useCallback, useEffect, useState } from 'react';

import { collectionAppLaunchTelemetry } from '../../../src/shared/Telemetry/CreateCollectionTelemetryUtil';
import { CollectionsTelemetryEvent } from '../../shared/Telemetry/CollectionsTelemetryEvent';
import { useFeature } from '../atoms/useFeature';
import { useLocalization } from '../atoms/useLocalization';
import { useToggleAppLayout } from '../atoms/useToggleAppLayout';
import { ViewMode } from '../atoms/useToggleViewMode';
import { useUrlParams } from '../atoms/useUrlParams';
import { Collection, DeferredCollectionLoader, Tile } from '../client-app-collections';
import { Feedbackfeature } from '../molecules/FeedbackFeature';
import { InformationDialogProps } from '../molecules/InformationDialog';
import { useConfirmationDialog } from '../molecules/useConfirmationDialog';
import { useBookmarkActionDelete } from './Bookmarks/useBookmarkActionDelete';
import { useBookmarkActionUpdate } from './Bookmarks/useBookmarkActionUpdate';
import { CollectionModals } from './CollectionModals';
import { useAppActionCopyLink } from './useAppActionCopyLink';
import { useAppActionManage } from './useAppActionManage';
import { useAppActionRemove } from './useAppActionRemove';
import { useAppActions } from './useAppActions';
import { useAppActionViewMoreInfo } from './useAppActionViewMoreInfo';
import { useCollectionActions } from './useCollectionActions';
import { useCollectionLoadTelemetry } from './useCollectionLoadTelemetry';
import { useDragAndDropInteractions } from './useDragAndDropInteractions';
import { useEmptyCollectionActions } from './useEmptyCollectionActions';

export const DeleteBookmark = lazy(async () =>
  await import(/* webpackChunkName: "bookmarks" */ './Bookmarks/DeleteBookmark'));
export const UpdateBookmark = lazy(async () =>
  await import(/* webpackChunkName: "bookmarks" */ './Bookmarks/UpdateBookmark'));

export const AppDescriptionDialog = lazy(async () =>
  await import(/* webpackChunkName: "info-dialog" */ '../molecules/AppDescriptionDialog'));
export const InformationDialog = lazy(async () =>
  await import(/* webpackChunkName: "info-dialog" */ '../molecules/InformationDialog'));
export const ConfirmationDialog = lazy(async () =>
  await import(/* webpackChunkName: "info-dialog" */ '../molecules/ConfirmationDialog'));

const ROW_PER_PAGE_GRID = 4;
const ROW_PER_PAGE_LIST = 25;
const WINDOWS_AHEAD = 2;

export interface VerticalViewContentProps {
  readonly collections: readonly Collection[];
  readonly informationDialogProps: InformationDialogProps;
  readonly viewMode: ViewMode;
  readonly selectedCollectionId?: string;
  readonly collapsedCollections: string[] | undefined;
  addCollapsedCollectionId: (value: string, numApps: number) => void;
  removeCollapsedCollectionId: (value: string, numApps: number) => void;
}

export function VerticalViewContent({
  collections,
  informationDialogProps,
  viewMode,
  selectedCollectionId,
  collapsedCollections,
  addCollapsedCollectionId,
  removeCollapsedCollectionId
}: VerticalViewContentProps): ReactElement {
  const [t] = useLocalization();
  useCollectionLoadTelemetry();
  const [openConfirmationDialog, confirmationDialogProps] = useConfirmationDialog();
  const appActionManage = useAppActionManage();
  const [updateBookmark, updateBookmarkProps] = useBookmarkActionUpdate();
  const [deleteBookmark, deleteBookmarkProps] = useBookmarkActionDelete();
  const copyAppLink = useAppActionCopyLink();
  const removeApp = useAppActionRemove();
  const appDescriptionFeatureFlag = useFeature('appDescription');
  const { viewAppDescriptionAction, appDescriptionDialogProps } = useAppActionViewMoreInfo(appDescriptionFeatureFlag);
  const collectionActions = useCollectionActions(openConfirmationDialog);
  const { onDeleteCollectionClicked, onAddAppsClicked, onItemDraggedOver } = useEmptyCollectionActions(openConfirmationDialog);
  const appActions = useAppActions<Tile, Collection>([
    copyAppLink,
    viewAppDescriptionAction,
    appActionManage,
    updateBookmark,
    deleteBookmark,
    removeApp
  ]);
  const { appLayout, setAppLayout } = useToggleAppLayout(true);
  const urlParams = useUrlParams();
  const telemetry = useTelemetry();
  const reportAppLaunchTelemetry = useCallback((tile: Tile, collection: Collection) => {
    telemetry.reportCustomEvent('collections/business-event/select-app', collectionAppLaunchTelemetry(tile, collection?.id));
  }, [telemetry]);

  useEffect(() => {
    if (urlParams.layout === 'List') {
      telemetry.reportCustomEvent(CollectionsTelemetryEvent.UrlParamTogglesAppsLayout);
      setAppLayout('List');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telemetry, urlParams.layout]);

  const {
    onDragStarted,
    onItemOverDropContainer,
    onItemDropped,
    reorderContainer,
    copyFromContainer
  } = useDragAndDropInteractions(collections);

  const [enabledFeatures, SetEnabledFeatures] = useState<string[]>([]);
  useEffect(() => {
    if (appDescriptionFeatureFlag && !enabledFeatures.includes('appDescription')) {
      SetEnabledFeatures(features => ([...features, 'appDescription']));
    }
  }, [appDescriptionFeatureFlag, enabledFeatures]);

  const [settingsBtns, SetSettingsBtns] = useState<Record<string, React.RefObject<any>>>({});
  useEffect(() => {
    const newRefs: Record<string, React.RefObject<any>> = {};
    collections?.forEach((item) => newRefs[item.id] = React.createRef<any>());
    SetSettingsBtns(newRefs);
  }, [collections]);

  return (
    <>
      <CollectionsBody
        strings={{
          loadingLabel: t('loading'),
          settingsButtonLabel: t('settings'),
          contextButtonAriaLabel: t('openAppsContextMenu')
        }}
        collections={collections}
        onAppClick={reportAppLaunchTelemetry}
        collectionActions={collectionActions}
        appActions={appActions}
        dragAndDropConfiguration={{
          enabled: true,
          animation: true,
          renderedRowsPerPage: appLayout === 'Grid' ? ROW_PER_PAGE_GRID : ROW_PER_PAGE_LIST,
          renderedWindowsAhead: WINDOWS_AHEAD,
          strings: {
            grabbingItem: (startIndex: number, startContainer: string) => { return t('grabbingItem', { startIndex, startContainer }); },
            droppingItem: (endIndex: number, endContainer: string) => { return t('droppingItem', { endIndex, endContainer }); },
            movingItem: (startIndex: number, endIndex: number) => { return t('movingItem', { startIndex, endIndex }); },
            copyingItem: (startIndex: number, startContainer: string, endContainer: string, endIndex: number) => { return t('copyingItem', { startIndex, endIndex, startContainer, endContainer }); },
            movingItemToNewList: (startIndex: number, startContainer: string, endContainer: string, endIndex: number) => { return t('movingItemToNewList', { startIndex, endIndex, startContainer, endContainer }); },
            startDragInstructions: () => { return t('startDragInstructions'); }
          },
          onDragStarted,
          onItemOverDropContainer,
          onItemDropped,
          copyFromContainer,
          reorderContainer
        }}
        viewState={{
          viewLayout: appLayout === 'List' ? 'list' : 'grid',
          selectedCollectionId,
          density: viewMode === 'Continuous' ? 'multiple' : 'single',
          collapsedCollections,
          onCollapseCollection: (collection: Collection) => { addCollapsedCollectionId(collection.id, collection.loadingState === 'loaded' || collection.loadingState === 'tiles' ? collection.itemCount : 0); },
          onExpandCollection: (collection: Collection) => { removeCollapsedCollectionId(collection.id, collection.loadingState === 'loaded' || collection.loadingState === 'tiles' ? collection.itemCount : 0); }
        }}
        emptyCollectionProps={{
          emptyCollectionFirstLine: t('emptyCollectionFirstLine'),
          emptyCollectionSecondLine: t('emptyCollectionSecondLine'),
          emptyCollectionAddApps: t('addApps'),
          emptyCollectionDelete:  t('Delete'),
          emptyCollectionFocusMode: t('noAppToShow'),
          emptyCollectionInvalid: t('noAppToShow'),
          onDeleteCollectionClicked,
          onAddAppsClicked,
          onItemDraggedOver
        }}
        enabledFeatures={enabledFeatures}
        settingsBtns={settingsBtns}
      />
      <DeferredCollectionLoader scope={'user'} />

      {/* Modals */}
        <Suspense fallback={<div></div>}>
          <UpdateBookmark {...updateBookmarkProps} />
          <DeleteBookmark {...deleteBookmarkProps} />
        </Suspense>

      <CollectionModals settingsBtns={settingsBtns} />
      <Feedbackfeature />
      <Suspense fallback={<div></div>}>
        <ConfirmationDialog {...confirmationDialogProps} />
        <InformationDialog {...informationDialogProps} />
        <AppDescriptionDialog {...appDescriptionDialogProps} />
      </Suspense>
    </>
  );
}
