import { ReactElement } from 'react';
import { Scope } from '../atoms/useCollectionCacheClient';
import { useCollectionQuery } from '../atoms/useCollectionQuery';

export interface DeferredCollectionStubProps {
  readonly scope: Scope;
  readonly collectionId: string;
}

/** Forces load of an individual collection from the order. */
export function DeferredCollectionStub({ scope, collectionId }: DeferredCollectionStubProps): ReactElement | null {
  useCollectionQuery(scope, collectionId);
  return null;
}
