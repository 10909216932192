import { IDetector } from './interfaces';

export const navigatorDetector: IDetector = {
  name: 'navigator',
  lookup: () => {
    let languages: string[] = [];

    if (typeof navigator !== 'undefined') {
      if (navigator.languages) {
        languages = [...navigator.languages];
      }

      if (navigator.language) {
        languages.push(navigator.language);
      }
    }

    return languages.length > 0 ? languages : undefined;
  },
};
