import React, { createContext, ReactElement, ReactNode, useContext } from 'react';
import { Tile } from '../client-app-collections';
import { useViewCollectionModals } from './useViewCollectionModals';

interface Collection {
  readonly id: string;
  readonly name: string;
  readonly items: readonly Tile[];
}
type Modal = readonly ['none', undefined, void]
  | readonly ['create', undefined, Collection]
  | readonly ['addApps', { readonly initiallySelectedItems: readonly Tile[] }, readonly Tile[]]  // list of chosen tile ids
  | readonly ['manage', undefined, void]
  | readonly ['edit', { readonly collectionId: string }, Collection]
  | readonly ['createBookmark', { readonly collectionId?: string }, Tile];
type ModalKey = Modal[0];
type ModalPropsForKey<TKey extends ModalKey> = Extract<Modal, readonly [TKey, any, any]>[1];
type ModalValueForKey<TKey extends ModalKey> = Extract<Modal, readonly [TKey, any, any]>[2];

interface CollectionModalsContext {
  readonly activeModal: Modal[0];
  readonly getPropsForModal: <T extends Modal, TKey extends T[0]>(key: TKey) => ModalPropsForKey<TKey> | undefined;
  readonly pushModal: <TKey extends ModalKey>(modal: TKey, props?: ModalPropsForKey<TKey>) => Promise<ModalValueForKey<TKey>>;
  readonly popModal: <TKey extends ModalKey>(completed?: boolean, result?: ModalValueForKey<TKey>) => void;
}

interface CollectionModalsProps {
  readonly children?: ReactNode;
}

const Context = createContext<CollectionModalsContext | null>(null);

export function useCollectionModals(): CollectionModalsContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error('No ancestor CollectionModalsProvider found; useCollectionModals must be called within an CollectionModalsProvider context.');
  }

  return context;
}

export function CollectionModalsProvider(props: CollectionModalsProps): ReactElement {
  const { activeModal, getPropsForModal, pushModal, popModal } = useViewCollectionModals<Modal>();
  const { children } = props;
  return (
    <Context.Provider value={{ ...props, activeModal, getPropsForModal, pushModal, popModal }}>{children}</Context.Provider>
  );
}
