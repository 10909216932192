import { useMemo } from 'react';
import { SelectableFeature, selectableFeatures } from './features';
import { useFeatureStorage } from './useFeatureStorage';

/** The list of explicitly opted-in features at the user level. */
export function useSelectedFeatures(): readonly SelectableFeature[] {
  const { getItem } = useFeatureStorage();
  return useMemo(() => {
    return selectableFeatures.filter(x => getItem(x) === 'OptIn');
  }, [getItem]);
}
