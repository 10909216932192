import { useCallback } from 'react';

import { applyViewToDisplayName, BasicCollection, cacheKeyItem, cacheKeyScopedLibrary, cacheKeyScopedOrder, CollectionResponse, forbidAllActions, isDeferredCollectionResponse, Scope, useCollectionCacheClient } from '../atoms/useCollectionCacheClient';

function isFullCollection(collection: CollectionResponse | BasicCollection): collection is CollectionResponse {
  return !!(collection as CollectionResponse).viewPermissions;
}

export interface PropagateCollectionsCallbacks {
  /**
   * Reads a cached collection order to update individual collection caches.
   * @returns a promise that resolves when all caches have been updated.
   */
  readonly propagateCachedCollectionOrder: (scope: Scope) => Promise<void>;

  /**
   * Reads a cached collection library to update individual collection caches.
   * @returns a promise that resolves when all caches have been updated.
   */
  readonly propagateCachedCollectionLibrary: (scope: Scope) => Promise<void>;

  /**
   * Update individual collection caches from the given collection list.
   * @returns a promise that resolves when all caches have been updated.
   */
  readonly propagateCollections: (scope: Scope, collections: ReadonlyArray<CollectionResponse | BasicCollection>) => Promise<void>;
}

/**
 * @returns functions that updates all individual collection caches in the given scope
 *  to match a collection list.
 *  Also invalidates all deferred collection caches, to enable the deferred load.
 */
export function usePropagateCollections(): PropagateCollectionsCallbacks {
  const client = useCollectionCacheClient();

  const propagateCollections = useCallback(async (scope: Scope, collections: ReadonlyArray<CollectionResponse | BasicCollection>) => {
    for (let i = 0, n = collections.length; i < n; i++) {
      const collection = collections[i];
      let result: CollectionResponse;
      const key = cacheKeyItem(scope, collection.id);
      if (isFullCollection(collection)) {
        result = collection;
        if (isDeferredCollectionResponse(result)) {
          // Trigger a followup load for the item, since it is deferred
          client.invalidateQueries(key);
        } else {
          // Cancel any load in progress for the item
          client.cancelQueries(key);
        }
      } else {
        const data = client.getQueryData(key);
        const displayName = applyViewToDisplayName(data?.template ?? null, data?.view ?? null, data?.viewPermissions ?? forbidAllActions);
        if (data && data.id == collection.id && displayName == collection.displayName) {
          // Don't overwrite a collection with a basic version
          continue;
        } else {
          result = { id: collection.id, viewPermissions: forbidAllActions, template: { isComplete: false, displayName: collection.displayName } } as any as CollectionResponse;
          // Trigger a followup load for the item, since we only have the name
          client.invalidateQueries(key);
        }
      }
      
      client.setQueryData(key, result);
    }
  }, [client]);

  const propagateCachedCollectionLibrary = useCallback(async (scope: Scope) => {
    const collections = client.getQueryData(cacheKeyScopedLibrary(scope));
    if (collections) {
      await propagateCollections(scope, collections);
    }
  }, [client, propagateCollections]);

  const propagateCachedCollectionOrder = useCallback(async (scope: Scope) => {
    const collections = client.getQueryData(cacheKeyScopedOrder(scope));
    if (collections) {
      await propagateCollections(scope, collections);
    }
  }, [client, propagateCollections]);

  return {
    propagateCachedCollectionOrder,
    propagateCachedCollectionLibrary,
    propagateCollections
  };
}
