import './polyfills';

import { initializeIcons } from '@fluentui/react';
import { ExperimentClientProvider } from '@iamexperiences/client-experiment';
import { TelemetryProvider } from '@iamexperiences/ecos-telemetry';
import { AuthProvider, CustomNavigationClient } from '@iamexperiences/feature-auth';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { App } from './Apps/App';
import { AuthenticatedUser } from './Apps/molecules/AuthenticatedUser';
import { ErrorBoundary } from './Apps/molecules/ErrorBoundary';
import { CustomToaster } from './Apps/organisms/CustomToaster';
import { AppsRedirect } from './AppsRedirect/AppsRedirect';
import { auth } from './auth';
import { bootstrap } from './bootstrap';
import { experimentClient } from './Experiment/ExperimentClient';
import { LoginError } from './LoginError';
import { telemetry } from './shared/Telemetry/Telemetry';

initializeIcons('/');
export const history = createBrowserHistory();

// add this to support client-side routing with MSAL
const navigationClient = new CustomNavigationClient(history);
auth.setNavigationClient(navigationClient);

const render = () => {
  ReactDOM.render(
    <ErrorBoundary component={LoginError}>
      <TelemetryProvider telemetry={telemetry}>
        <AuthProvider auth={auth}>
          <AuthenticatedUser>
            <ExperimentClientProvider experimentClient={experimentClient}>
              <AppsRedirect>
                <CustomToaster />
                  <App />
              </AppsRedirect>
            </ExperimentClientProvider>
          </AuthenticatedUser>
        </AuthProvider>
      </TelemetryProvider>
    </ErrorBoundary>,
    window.document.getElementById('root')
  );
};

Promise.resolve(true)
  .then(async () => await bootstrap())
  .then((shouldContinue: boolean) => (shouldContinue ? render() : undefined));
