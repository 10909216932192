import i18next, { TFunction } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';

/**
 * Get a translation function and namespace resource-is-loaded boolean flag.
 *
 * ```ts
 * // Get both the t() function and loaded flag.
 * const [t, loaded] = useLocalization('MyNamespace');
 *
 * // Get just the t() function.
 * const [t] = useLocalization('MyNamespace');
 *
 * // Get just the resource loaded flag.
 * const [, loaded] = useLocalization('MyNamespace');
 *
 * // Use multiple namespaces. The loaded flag will be updated when all
 * // namespaces have been loaded.
 * const [t, loaded] = useLocalization(['NamespaceA', 'NamespaceB']);
 * ```
 */
export const useLocalization = (namespace: string | string[]): [TFunction, boolean] => {
  if (typeof namespace === 'string') {
    namespace = [namespace];
  }

  const [loaded, setLoaded] = useState(false);
  const t = useRef(i18next.getFixedT(null, namespace));

  useEffect(() => {
    i18next.loadNamespaces(namespace, err => {
      if (err) {
        console.warn(err);
      }

      t.current = i18next.getFixedT(null, namespace);
      setLoaded(true);
    });
  }, namespace);

  return [t.current, loaded];
};

export interface ILoadLocalizationNamespaceProps {
  namespace: string | string[];
}

export const LoadLocalizationNamespace: React.FunctionComponent<ILoadLocalizationNamespaceProps> = ({
  namespace,
  children
}) => {
  const [, loaded] = useLocalization(namespace);

  return loaded ? <>{children}</> : null;
};
