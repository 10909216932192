import { useCallback, useEffect } from 'react';
import * as React from 'react';

import { useLocalization } from './Apps/atoms/useLocalization';
import { IErrorBoundaryComponentProps } from './Apps/molecules/ErrorBoundary';
import { FullSizeError, IFullSizeErrorComponentLabels, IFullSizeErrorProps } from './Apps/molecules/FullsizeError';
import { Omit } from './shared/helpers.types';
import { telemetry } from './shared/Telemetry/Telemetry';

export type LocalizedFullSizeErrorProps = Omit<IFullSizeErrorProps, 'labels'>;

export const LoginError: React.FunctionComponent<IErrorBoundaryComponentProps> = ({ error, errorInfo }) => {
  const [t] = useLocalization();
  const errorDetails = `${error} ${JSON.stringify(errorInfo, null, 2)}`;

  useEffect(() => {
    telemetry.error('loginerror', error);
  }, [error]);

  const actionCallback = useCallback(() => {
    // Clear local storage - in some cases this may aid recovery.
    // This resets:
    //  feature opt-in/out state
    //  banner notification dismissals
    //  NPS submission history
    //  collections cache
    try {
      localStorage.clear();
    } catch {}

    telemetry.reportCustomEvent('retry-after-loginerror');

    location.reload();
  }, []);

  const props: LocalizedFullSizeErrorProps = {
    errorTitle: t('unknownErrorTitle'),
    subtitle: t('refreshPage'),
    actionButtonProps: {
      actionLabel: t('refresh'),
      onActionPressed: actionCallback
    },
    message: errorDetails
  };

  const constants: IFullSizeErrorComponentLabels = {
    timeLabel: t('time'),
    errorCodeLabel: t('errorCode'),
    correlationIdLabel: t('correlationId'),
    sessionIdLabel: t('sessionId'),
    userIdLabel: t('userId'),
    detailsLabel: t('details'),
    unknownErrorTitle: t('unknownErrorTitle'),
    viewMoreLabel: t('viewMore'),
    viewLessLabel: t('viewLess'),
    defaultErrorButtonActionLabel: t('action'),
    downloadErrorTooltipLabel: t('downloadError')
  };

  return <FullSizeError labels={constants} {...props} />;
};

export default LoginError;
