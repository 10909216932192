import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useCallback, useMemo } from 'react';

import { settingsManager } from '../../shared/settings';
import { useLocalization } from '../atoms/useLocalization';
import { useRoute } from '../organisms/useRoute';
import { getIAMUXBaseAddress } from '../utils/getApiServerUrl';
import { useIsLicensePresent } from '../../shared/hooks/useIsLicensePresent';

export interface Collection {
  readonly id: string;
}
export interface Action {
  readonly key: string;
  readonly name: string;
  readonly enabled: () => boolean;
  readonly act: () => void;
}

const isSelfServiceAppAccessEnabled = (): boolean => {
  const isSSAAFeatureEnabled = settingsManager.get('isSelfServiceAppAccessEnabled');
  if (isSSAAFeatureEnabled) {
    return true;
  }

  const queryParams = new URLSearchParams(window.location.search);
  const isSSAAEnabled = queryParams.get('isSelfServiceAppAccessEnabled');
  return isSSAAEnabled === 'true';
};

export function useRequestApps(): Action {
  const telemetry = useTelemetry();
  const [t] = useLocalization();
  const licenseQuery = useIsLicensePresent();

  const canRequestApps = useCallback(() => (licenseQuery.isSuccess ?? false) && (licenseQuery.data ?? false), [licenseQuery.isSuccess, licenseQuery.data]);
  const { goToRequestNewApps } = useRoute();

  const requestApps = useCallback(() => {
    telemetry.reportCustomEvent('contextMenu/global/requestNewApps');

    const isSSAAEnabled = isSelfServiceAppAccessEnabled();
    if (isSSAAEnabled) {
      goToRequestNewApps();
    } else {
      window.open(`${getIAMUXBaseAddress()}/redirect/addApplications`, '_blank');
    }
  }, [goToRequestNewApps, telemetry]);

  return useMemo(
    () => ({
      key: 'requestNewApps',
      name: t('requestNewApps'),
      enabled: canRequestApps,
      act: requestApps
    }),
    [canRequestApps, requestApps, t]
  );
}
