import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useMemo } from 'react';

import { OpenDialogFunction } from '../molecules/useConfirmationDialog';
import { useCollectionActionDelete } from './useCollectionActionDelete';
import { useCollectionActionEdit } from './useCollectionActionEdit';
import { useCollectionActionHide } from './useCollectionActionHide';
import { useCollectionActionReorder } from './useCollectionActionReorder';
import { useCollectionActionRestore } from './useCollectionActionRestore';

interface Action<T> {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: (collection: T) => boolean;
  readonly act: (collection: T) => void;
}

export function useCollectionActions(
  openConfirmationDialog: OpenDialogFunction
) /* intentional implicit return type to infer T of Action<T> from nested actions */ {
  const telemetry = useTelemetry();

  const editCollection = useCollectionActionEdit();
  const hideCollection = useCollectionActionHide();
  const reorderCollection = useCollectionActionReorder();
  const deleteCollection = useCollectionActionDelete(openConfirmationDialog);
  const restoreCollection = useCollectionActionRestore(openConfirmationDialog);

  return useMemo(() => {
    const addActTelemetry = <T>(action: Action<T>): Action<T> => {
      return {
        ...action,
        act: collection => {
          telemetry.reportCustomEvent(`contextMenu/collection/${action.key}`);
          action.act(collection);
        }
      };
    };
    return [editCollection, hideCollection, reorderCollection, deleteCollection, restoreCollection].map(addActTelemetry);
  }, [editCollection, hideCollection, reorderCollection, deleteCollection, restoreCollection, telemetry]);
}
