/** A Cache type is a union of key-value pair types. */
type Cache<Key = any, Value = any> = [Key, Value];

type Expand<T, K> = T extends any ? K extends T ? T : never : never;
/** Finds the cache value type that corresponds to a cache key type. */
export type ValueFor<C extends Cache, Key extends C[0]> = Extract<C, [Expand<C[0], Key>, any]>[1];

type KeysWithValue<C extends Cache, Value> = Extract<C, [any, Expand<C[1], Value>]>[0];

type ItemOf<T> = T extends ReadonlyArray<infer Item> ? Item : never;

/** A client that operates on a cache over a given set of keys. */
export interface CacheClient<C extends Cache> {
  readonly getQueryData: <K extends C[0]>(key: K) => ValueFor<C, K> | undefined;
  readonly setQueryData: <K extends C[0]>(key: K, updater: ((old: ValueFor<C, K> | undefined) => ValueFor<C, K>) | ValueFor<C, K>) => void;
  readonly cancelQueries: (key: C[0]) => Promise<void>;
  readonly removeQueries: (key: C[0]) => void;
  readonly invalidateQueries: (key: C[0]) => Promise<void>;
}

export interface OptimisticUpdate {
  readonly complete: () => Promise<void>;
  readonly rollback: () => Promise<void>;
}

export async function optimisticReplaceValue<C extends Cache, K extends C[0]>(
  client: CacheClient<C>,
  key: K,
  value: ValueFor<C, K>
): Promise<OptimisticUpdate> {
  const current = client.getQueryData(key);
  await client.cancelQueries(key);
  client.setQueryData(key, value);
  return {
    complete: async () => await client.invalidateQueries(key),
    rollback: async () => {
      if (current) client.setQueryData(key, current);
      return await client.invalidateQueries(key);
    }
  };
}

export async function optimisticReplaceItemsInListDelayedUpdate<C extends Cache, K extends KeysWithValue<C, readonly any[]>>(
  client: CacheClient<C>,
  key: K,
  mutate: (value: ItemOf<ValueFor<C, K>>) => ItemOf<ValueFor<C, K>>
): Promise<OptimisticUpdate> {
  const cleanUp = async () => await client.invalidateQueries(key);
  const current = client.getQueryData(key);
  if (current) {
    await client.cancelQueries(key);
    client.setQueryData(key, current.map(mutate));

    return {
      complete: async () => {},
      rollback: async () => {
        client.setQueryData(key, current);
        await cleanUp();
      }
    };
  }
  return { complete: cleanUp, rollback: cleanUp };
}

export async function optimisticRemoveValue<C extends Cache, K extends C[0]>(
  client: CacheClient<C>,
  key: K
): Promise<OptimisticUpdate> {
  const current = client.getQueryData(key);
  if (current) {
    client.removeQueries(key);
    return {
      complete: async () => { },
      rollback: async () => {
        client.setQueryData(key, current);
      }
    };
  }
  return {
    complete: async () => { },
    rollback: async () => { }
  };
}

export async function optimisticReplaceItemsInList<C extends Cache, K extends KeysWithValue<C, readonly any[]>>(
  client: CacheClient<C>,
  key: K,
  mutate: (value: ItemOf<ValueFor<C, K>>) => ItemOf<ValueFor<C, K>>
): Promise<OptimisticUpdate> {
  const cleanUp = async () => await client.invalidateQueries(key);
  const current = client.getQueryData(key);
  if (current) {
    await client.cancelQueries(key);
    client.setQueryData(key, current.map(mutate));

    return {
      complete: cleanUp,
      rollback: async () => {
        client.setQueryData(key, current);
        await cleanUp();
      }
    };
  }
  return { complete: cleanUp, rollback: cleanUp };
}

export async function optimisticAppendItemToList<C extends Cache, K extends KeysWithValue<C, readonly any[]>>(
  client: CacheClient<C>,
  key: K,
  value: ItemOf<ValueFor<C, K>>
): Promise<OptimisticUpdate> {
  const cleanUp = async () => await client.invalidateQueries(key);
  const current = client.getQueryData(key);
  if (current) {
    await client.cancelQueries(key);
    client.setQueryData(key, old => old ? [...old, value] : [value]);

    return {
      complete: cleanUp,
      rollback: async () => {
        client.setQueryData(key, current);
        await cleanUp();
      }
    };
  }
  return { complete: cleanUp, rollback: cleanUp };
}

export async function optimisticRemoveItemsFromList<C extends Cache, K extends KeysWithValue<C, readonly any[]>>(
  client: CacheClient<C>,
  key: K,
  shouldRemove: (value: ItemOf<ValueFor<C, K>>) => boolean
): Promise<OptimisticUpdate> {
  const cleanUp = async () => await client.invalidateQueries(key);
  const current = client.getQueryData(key) as (Array<ItemOf<ValueFor<C, K>>> | undefined);
  if (current) {
    const itemIndex = current.findIndex(shouldRemove);
    if (itemIndex >= 0) {
      await client.cancelQueries(key);
      const next = current.filter(x => !shouldRemove(x));
      client.setQueryData(key, next);
      return {
        complete: cleanUp,
        rollback: async () => {
          client.setQueryData(key, current);
          await cleanUp();
        }
      };
    }
  }
  return { complete: cleanUp, rollback: cleanUp };
}
