import { useLocalization } from '../atoms/useLocalization';
import { useCallback, useMemo } from 'react';
import { OpenDialogFunction } from '../molecules/useConfirmationDialog';
import { useConfirmRestoreCollectionDialog } from '../molecules/useConfirmRestoreCollectionDialog';

export interface Collection {
  readonly id: string;
  readonly displayName: string;
  readonly viewPermissions: {
    readonly canAddItems: boolean;
    readonly canRemoveItems: boolean;
    readonly canReorderItems: boolean;
    readonly canRenameList: boolean;
  }
}


export interface Action {
  readonly key: string;
  readonly name: string;
  readonly icon: string;
  readonly enabled: (collection: Collection) => boolean;
  readonly act: (collection: Collection) => void;
}

export function useCollectionActionRestore(openDialog: OpenDialogFunction): Action {
  const [t] = useLocalization();
  const restoreCollection = useConfirmRestoreCollectionDialog(openDialog);
  // A collection can be restored if it can be edited, but not deleted.
  const canRestore = useCallback((collection: Collection) =>
    !collection.viewPermissions.canRenameList && !!(
      collection.viewPermissions.canAddItems ||
      collection.viewPermissions.canRemoveItems ||
      collection.viewPermissions.canReorderItems
    ), []);

  return useMemo(() => ({
    key: 'restore',
    name: t('resetToDefault'),
    icon: '',
    enabled: canRestore,
    act: restoreCollection
  }), [canRestore, restoreCollection]);
}
