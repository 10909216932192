import { useMemo } from 'react';
import { useEnabledFeatures } from './useEnabledFeatures';
import { Feature } from './features';

/**
 * Determines whether a feature is available to the currently user in the current session.
 *
 * The server is queried to get the enabled/disabled state.
 * This call is non-blocking, so a default is assumed until the load completes.
 *
 * Defaults to true if:
 * - the feature is explicitly enabled by url parameter
 * - the user has explicitly opted-in in the past (stored in local storage)
 * Defaults to false otherwise.
 */
export function useFeature(feature: Feature): boolean {
  const enabledFeatures = useEnabledFeatures();
  return useMemo(() => {
    return enabledFeatures.includes(feature);
  }, [feature, enabledFeatures]);
}
