/* eslint-disable max-len */
import { useCallback } from 'react';

import { cacheKeyScopedOrder, CollectionResponse, Scope, useCollectionCacheClient } from '../atoms/useCollectionCacheClient';
import { optimisticReplaceItemsInListDelayedUpdate, OptimisticUpdate } from '../utilities/cache';

/**
 * Updates all caches containing a collection, and triggers a background refresh of the collection and all containing lists.
 * Does not update display names, designed/optimized for D&D cache updates
 * Does NOT execute any API operation to update the collection on the backend.
 */
export function useLocalUpdateCollections(): (scope: Scope, collections: CollectionResponse[]) => Promise<OptimisticUpdate> {
  const client = useCollectionCacheClient();

  return useCallback(async (scope, collections) => {
    const collectionIds = collections.map((x) => x.id);
    const updates = [
      await optimisticReplaceItemsInListDelayedUpdate(
        client,
        cacheKeyScopedOrder(scope),
        x => collectionIds.includes(x.id) ? (collections.find((y) => y.id === x.id) ?? x) : x)
    ];
    return {
      complete: async () => {
        await Promise.all(updates.map(async x => await x.complete()));
      },
      rollback: async () => {
        await Promise.all(updates.map(async x => await x.rollback()));
      }
    };
  }, [client]);
}
