import {
  ConsoleTelemetrySinkFactory,
  initializeTelemetry,
  ITelemetry,
  OneDsTelemetrySinkFactory
} from '@iamexperiences/ecos-telemetry';
import { v4 as uuidv4 } from 'uuid';

import { auth } from '../../auth';
import { experimentClient } from '../../Experiment/ExperimentClient';
import { settingsManager } from '../settings';

const ONE_DS_CONSOLE_QUERY_STRING = '1dsConsole';

const isConsolePrintingEnabled = (): boolean => {
  const queryParameters = new URLSearchParams(window.location.search);
  const isEnabled = queryParameters.get(ONE_DS_CONSOLE_QUERY_STRING);
  return isEnabled === 'true';
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function createSinkFactory(endpointUrl?: string) {
  const sinkFactories = [
    OneDsTelemetrySinkFactory({
      instrumentKey: settingsManager.get('oneDSInstrumentKey') ?? '',
      endpointUrl,
      enableNetworkCallTracking: true,
      enableConsolePrinting: isConsolePrintingEnabled(),
      enablePublishTelemetry: process.env.NODE_ENV === 'production'
    })
  ];

  if (process.env.NODE_ENV === 'development') {
    sinkFactories.push(ConsoleTelemetrySinkFactory());
  }

  return sinkFactories;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function createTelemetryObject(endpointUrl?: string) {
  const telemetryObject = initializeTelemetry({
    sinkFactories: createSinkFactory(endpointUrl),
    data: () => {
      return {
        latestAuthEventType: auth.latestAuthEventType,
        userId: auth.user?.objectId,
        tenantId: auth.user?.tenantId,
        namespace: 'AadMyApps',
        env: settingsManager.get('env') ?? 'others',
        assignmentContext: experimentClient.getAssignmentContext(),
        logId: uuidv4(),
        buildVersion: process.env.VERSION
      };
    }
  });

  return telemetryObject;
}

/**
 * Using the Europe kusto cluster as a default is a temporary workaround
 * We ant to make sure we are EU compliant. For now having the EU as default
 * will make us compliant until we figure our a complete solution.
 *
 * Currently feature-auth (and others) are logging EUPI data in NA kusto cluster
 * for Europe tenants/users.
 */
export const telemetry = getEUTelemetryReference();

export function getWWTelemetryReference(): ITelemetry {
  return createTelemetryObject(settingsManager.get('defaultCollectorEndpoint'));
}

export function getEUTelemetryReference(): ITelemetry {
  return createTelemetryObject(
    settingsManager.get('euCollectorEndpoint') ?? settingsManager.get('defaultCollectorEndpoint')
  );
}
