import { v4 as uuidv4 } from 'uuid';

export abstract class Constants {
  public static ALL_APPS_COLLECTION_ID = 'allapps';
  public static MAX_COLLECTION_NAME_LENGTH = 200;
  public static MAX_CONTEXT_MENU_ITEM_LENGTH = 30;
  public static DEFAULT_COLLECTIONS_EDITOR_PANE_HEADER_HEIGHT = 52 + 52;
  public static DEFAULT_COLLECTIONS_EDITOR_PANE_FOOTER_HEIGHT = 64;
  public static SESSION_ID = uuidv4();
  public static UPN_QUERY_STRING = 'upn';
  public static TENANT_ID_QUERY_STRING = 'tenantId';
}

/**
 * This lowercase eu string is used to compare the results of the
 * data boundary claim. The Data boundary claim expected results are:
 * 'EU' - for European tenants
 * undefined - for the rest of the world
 */
export const EU_DATA_BOUNDARY_CLAIM_VALUE = 'eu';

export const AAD_PREMIUM_SKU = 'AAD_PREMIUM'; // Contains AAD_PREMIUM, AAD_PREMIUM_P2 licenses.
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
