import { useTelemetry } from '@iamexperiences/ecos-telemetry';
import { useAuth } from '@iamexperiences/feature-auth';
import { getApiServerUrl } from '../../utils/getApiServerUrl';
import { useLocalization } from '../useLocalization';
import { toast } from 'react-hot-toast';
import { useQuery } from 'react-query';

import { Feature } from './features';
import { useFeatureFlags } from './useFeatureFlags';
import { useSelectedFeatures } from './useSelectedFeatures';
import { addDataBoundaryHeaders } from '../../utils/DataBoundaryHeaders';

const hourInMs = 3600000;

/** Queries the API for the list of enabled features. */
export function useEnabledFeatures(): readonly Feature[] {
  const [t] = useLocalization();
  // Explicitly requested for this session
  const urlFlags = useFeatureFlags();
  // Explicitly enabled at some point in the past
  const optIns = useSelectedFeatures();
  // All features with any explicit opt-in
  const flags = urlFlags.concat(optIns.filter(x => urlFlags.every(y => y != x)));
  const telemetry = useTelemetry();

  const { auth } = useAuth();
  const result = useQuery({
    queryKey: 'enabledFeatures',
    staleTime: hourInMs,
    cacheTime: hourInMs,
    placeholderData: flags,
    queryFn: async () => {
      const flagsString = `(${urlFlags.map((x) => `'${x}'`).join(',')})`;
      try {
        const token = await auth.acquireToken({ scopes: [auth.clientId] });
        const headers = addDataBoundaryHeaders(auth);
        headers.append('Authorization', 'Bearer ' + token);

        const response = await fetch(
          getApiServerUrl('api/myorganization/features', {
            $filter: urlFlags.length > 0 ? `enabled or id in ${flagsString}` : 'enabled',
            $select: 'id'
          }),
          {
            headers: headers
          }
        );
        const data = await response.json();
        return data?.value?.map((x_1: { Id: unknown }) => x_1.Id) ?? [];
      } catch (error) {
        telemetry.error('enabledFeatures-api/error', error);
        toast.error(t('genericApiFailure'), { id: 'enabledFeatures-api/error' });
        return [];
      }
    }
  });
  return result.data ?? [];
}
