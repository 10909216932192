import { useQuery } from 'react-query';

import { AAD_PREMIUM_SKU } from '../Constants/Constants';
import { settingsManager } from '../settings';
import { telemetry } from '../Telemetry/Telemetry';
import { useTokenQueryConfig } from '../Providers/TokenProvider';

interface ServicePlan {
  servicePlanId: string;
  servicePlanName: string;
  provisioningStatus: string;
  appliesTo: string;
}

interface SubscribedSku {
  capabilityStatus: string;
  consumedUnits: number;
  servicePlans: ServicePlan[];
  skuId: string;
  skuPartNumber: string;
  appliesTo: string;
}

interface SubscribedSkusResponse {
  value: SubscribedSku[];
}
export const useIsLicensePresent = () => {
  const { acquireMsGraphToken } = useTokenQueryConfig();
  const query = useQuery({
    queryKey: 'license',
    queryFn: async () => {
      const token = await acquireMsGraphToken();
      const msGraphRootUrl = settingsManager.getRequired('msGraphResourceName');
      // https://learn.microsoft.com/en-us/graph/api/subscribedsku-list?view=graph-rest-1.0&tabs=http
      const url = `${msGraphRootUrl}/v1.0/subscribedSkus`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        const responseText = response.text();
        telemetry.error('License', responseText);
        throw new Error(`${response.status}: Failed to fetch subscribedSkus. ${responseText}`);
      }

      const skuResponse: SubscribedSkusResponse = await response.json();
      const skus: SubscribedSku[] = skuResponse.value;
      const premiumSku = skus.find((sku) =>
        sku.servicePlans.find((sp) => sp.servicePlanName.toLowerCase().includes(AAD_PREMIUM_SKU.toLowerCase())),
      );

      return !!premiumSku;
    },
    staleTime: Infinity,
  });

  return query;
};
