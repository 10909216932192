import { HeaderProps } from '@iamexperiences/feature-app-launching';
import React, { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';

type Header = HeaderProps<string>;

type HeaderContext = readonly [
  headers: readonly Header[],
  setHeaders: React.Dispatch<React.SetStateAction<readonly Header[]>>
];

interface PageHeaderProviderProps {
  readonly children?: ReactNode;
}

const Context = createContext<HeaderContext | null>(null);

export function usePageHeader(header: Header | null): void {
  const context = useContext(Context);
  if (!context) {
    throw new Error('No ancestor PageHeaderProvider found; usePageHeaders must be called within a PageHeaderProvider context.');
  }
  const [, setHeaders] = context;

  const [lastValue, setLastValue] = useState<Header | null>(null);
  if (lastValue != header) {
    if (lastValue && header) {
      // In-place swap
      setHeaders(h => h.map(x => x === lastValue ? header : x));
    } else if (lastValue) {
      // Remove last value
      setHeaders(h => h.filter(x => x !== lastValue));
    } else if (header) {
      // Add new value to the end
      setHeaders(h => [...h, header]);
    }
    setLastValue(header);
  }

  useEffect(() => {
    return () => {
      if (header) {
        setHeaders(h => h.filter(x => x != header));
      }
    };
  }, [header]);
}

export function useCurrentPageHeaders(): readonly Header[] {
  const context = useContext(Context);
  if (!context) {
    throw new Error('No ancestor PageHeaderProvider found; usePageHeaders must be called within a PageHeaderProvider context.');
  }
  return context[0];
}

export function PageHeaderProvider({
  children
}: PageHeaderProviderProps): ReactElement {
  const context = useState<readonly Header[]>([]);
  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  );
}
