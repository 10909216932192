import { useCallback, useState } from 'react';
import { InformationDialogCommonProps, InformationDialogContent, InformationDialogProps } from './InformationDialog';

export type DialogProps = Omit<InformationDialogCommonProps, 'hidden' | 'onClose'> & InformationDialogContent;

export interface OpenDialogFunction {
  (props: DialogProps): void;
}

const noDialog: InformationDialogProps = {
  hidden: true,
  onClose: () => {},
  title: '',
  body: '',
  close: '',
  action: '',
  contentType: 'text'
};

/** Maintains a stack of information dialogs and returns the props to pass to the UX component for the top dialog. */
export function useInformationDialog(): [OpenDialogFunction, InformationDialogProps] {
  const [stack, setStack] = useState<readonly InformationDialogProps[]>([noDialog]);

  const closeDialog = useCallback(() => {
    setStack(stack => stack.slice(0, stack.length-1));
  }, []);

  const openDialog = useCallback((props: DialogProps) => {
    const allProps: InformationDialogProps = {
      ...props,
      hidden: false,
      onClose: closeDialog
    };

    setStack(stack => stack.length === 1 ?
      [{ ...noDialog, ...props }, allProps] : // set strings of hidden state to match bottom dialog for smooth animation
      [...stack, allProps]
    );
  }, [closeDialog]);

  return [openDialog, stack[stack.length-1]];
}
