import { useAuth } from '@iamexperiences/feature-auth';
import { useLocalization } from '../../atoms/useLocalization';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useAppCollectionsQueryConfig } from '../atoms/AppCollectionsProvider';
import { cacheKeyItem, useCollectionCacheClient } from '../atoms/useCollectionCacheClient';
import { useLocalUpdateCollection } from '../molecules/useLocalUpdateCollection';
import { OptimisticUpdate } from '../utilities/cache';
import { addDataBoundaryHeaders } from '../../utils/DataBoundaryHeaders';

export function useDeleteCollectionView(): (id: string) => void {
  const [t] = useLocalization();
  const { acquireToken, serviceRootUrl } = useAppCollectionsQueryConfig();
  const client = useCollectionCacheClient();
  const localUpdateCollection = useLocalUpdateCollection();
  const { auth } = useAuth();

  const { mutate } = useMutation<void, unknown, string, OptimisticUpdate | null>(async id => {
    const token = await acquireToken();
    const headers = addDataBoundaryHeaders(auth);
    headers.append('Authorization', 'Bearer ' + token);
    headers.append('Content-Type', 'application/json');

    // TODO(tada): Update to use new V2 view deletion API.
    const response = await fetch(`${serviceRootUrl.slice(0, serviceRootUrl.length - 7)}/api/users/me/workspaces/${id}/restore`, {
      method: 'POST',
      headers: headers
    });
    if (!response.ok) {
      return await Promise.reject(response.statusText);
    }
  }, {
    onMutate: async id => {
      const itemKey = cacheKeyItem('user', id);
      const collection = client.getQueryData(itemKey);
      if (collection) {
        const { view: _, ...afterRestoration } = collection;
        return await localUpdateCollection('user', { ...afterRestoration, view: null });
      }
      return null;
    },
    onError: async (_error, _id, update) => {
      toast.error(t('genericApiFailure'), { id: 'workspaces-api/error' });
      await update?.rollback();
    },
    onSuccess: async (_data, _id, update) => {
      await update?.complete();
    }
  });

  return mutate;
}
